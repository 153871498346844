export const timeStampToDateAndTime = (dateString) => {
  const date = new Date(dateString);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = dateString.length === 10 ? date.getUTCDate() : date.getDate();
  const monthIndex =
    dateString.length === 10 ? date.getUTCMonth() : date.getMonth();
  const year =
    dateString.length === 10 ? date.getUTCFullYear() : date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");;
  let amOrPm = " AM";
  let adjustedHours = hours;
  if (adjustedHours >= 12) {
    amOrPm = " PM";
    adjustedHours -= 12;
  }
  if (adjustedHours === 0) {
    adjustedHours = 12;
  }

  return (
    day +
    " " +
    months[monthIndex] +
    " " +
    year +
    " " +
    hours +
    ":" +
    minutes +
    amOrPm
  );
};
