import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { VISIT_CHECK, VISIT_PENDING } from "../../api/allApi_List";
import { fetchData } from "../../redux/dataSlice";
import VisitPending from "../../components/complaint/pending/VisitPending";
import DialogBox from "../../components/UI/DialogBox";
import spin from "../../assets/icons/spin.png";
import useCheckData from "../../CustomHooks/useAxios/useCheckData";
import { handleErrMessage } from "../../functions/handleErrMessage";
import MainPageLayout from "../../components/MainPageLayout";

const VisitPendingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [render, setRender] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const data = useSelector((state) => state.data.data);
  const error = useSelector((state) => state.data.error);
  const errMsg = useSelector((state) => state.data.errMessage);
  const dataLength = isLoaded && !errMsg ? data.length : 0;

  const {
    confirmDialogBox,
    setConfirmDialogBox,
    setApiEndPoint,
    setBodyData,
    isActionSucceed,
    setIsActionSucceed,
    buttonStatus,
    setButtonStatus,
    handleCheck,
    checkResponseData,
    checkedErr,
    checkedErrMsg,
    setCheckedErr,
    checkLoading,
  } = useCheckData(setRender, "post", setIsLoaded);

  useEffect(() => {
    dispatch(fetchData(VISIT_PENDING, setIsLoaded, navigate));
  }, [dispatch, render]);

  useEffect(() => {
    localStorage.removeItem("isCheckData");
    localStorage.removeItem("filterKeys");
    localStorage.removeItem("filterCheckBoxes");
    // remove data on unmount component
    return () => {
      localStorage.removeItem("isCheckData");
      localStorage.removeItem("filterKeys");
      localStorage.removeItem("filterCheckBoxes");
    };
  }, []);

  // handle confirm dialog box on complaint check
  const handleConfirmDialogBox = (id, status) => {
    setViewDialogOpen(false);
    setConfirmDialogBox(true);
    setApiEndPoint(VISIT_CHECK);
    setBodyData((prev) => {
      return { id: id, status: status };
    });
    setButtonStatus(status);
  };

  const updateStateOnAddRemark = () => {
    setRender(!render);
  };

  return (
    <MainPageLayout
      headerConfig={{ title: "Visit Pending" }}
      numberOfData={dataLength}
    >
      {isLoaded && !error ? (
        <VisitPending
          viewDialogOpen={viewDialogOpen}
          setViewDialogOpen={setViewDialogOpen}
          handleConfirmDialogBox={handleConfirmDialogBox}
          confirmDialogBox={confirmDialogBox}
          setConfirmDialogBox={setConfirmDialogBox}
          isActionSucceed={isActionSucceed}
          setIsActionSucceed={setIsActionSucceed}
          buttonStatus={buttonStatus}
          handleCheck={handleCheck}
          checkLoading={checkLoading}
          setBodyData={setBodyData}
          updateStateOnAddRemark={updateStateOnAddRemark}
          isLoaded={isLoaded}
        />
      ) : error ? (
        <DialogBox
          title="Error"
          message={handleErrMessage(errMsg)}
          isErrorHandleButtons={true}
        />
      ) : (
        <DialogBox message="Loading your data" animateIcon={spin} />
      )}
      {checkedErr && (
        <DialogBox
          title="Error"
          btnText="Ok"
          message={handleErrMessage(checkedErrMsg)}
          closeAction={() => setCheckedErr(false)}
        />
      )}
    </MainPageLayout>
  );
};

export default VisitPendingPage;
