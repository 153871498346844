import userModule from "../assets/images/userModule.svg";
import complaintModule from "../assets/images/complaintModule.svg";
import siteModule from "../assets/images/siteModule.svg";
import factoryModule from "../assets/images/factoryModule.svg";
import cylinderModule from "../assets/images/cylinderModule.svg";
import expenseModule from "../assets/images/expenseModule.svg";

export const moduleList = [
  {
    moduleTitle: "user",
    moduleIcon: userModule,
    route: "/user/all",
    disable: false,
  },
  {
    moduleTitle: "expense",
    moduleIcon: expenseModule,
    route: "/expense/all",
    disable: false,
  },
  {
    moduleTitle: "complaint",
    moduleIcon: complaintModule,
    route: "/complaint/all",
    disable: false,
  },
  {
    moduleTitle: "site manager",
    moduleIcon: siteModule,
    route: "/site/all",
    disable: false,
  },
  {
    moduleTitle: "factory",
    moduleIcon: factoryModule,
    disable: true,
  },
  {
    moduleTitle: "cylinder tracking",
    moduleIcon: cylinderModule,
    disable: true,
  },
];
