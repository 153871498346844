export const filterCategoryExpense = [
  {
    name: "user",
    value: [
      ["user", "id"],
      ["receiver", "id"],
    ],
    label: [
      ["user", "fullName"],
      ["receiver", "fullName"],
    ],
  },
  {
    name: "category",
    value: [["category", "id"]],
    label: [["category", "name"]],
  },
  {
    name: "status",
    label: [["state"]],
    getStatusName: true,
  },
];

export const filterCategoryExpensePending = [
  {
    name: "user",
    value: [
      ["user", "id"],
      ["receiver", "id"],
    ],
    label: [
      ["user", "fullName"],
      ["receiver", "fullName"],
    ],
  },
  {
    name: "category",
    value: [["category", "id"]],
    label: [["category", "name"]],
  },
];

export const filterCategorySite = [
  {
    name: "site engineer",
    value: [["jbccFieldTechnician", "id"]],
    label: [["jbccFieldTechnician", "fullName"]],
  },
  {
    name: "subdivision",
    value: [["je", "subDivision", "id"]],
    label: [["je", "subDivision", "name"]],
  },
  {
    name: "category",
    value: [["category", "id"]],
    label: [["category", "name"]],
  },
];

export const filterCategoryVisit = [
  {
    name: "Field Technician",
    value: [["fieldTechnician", "id"]],
    label: [["fieldTechnician", "fullName"]],
  },
  {
    name: "category",
    value: [["category", "id"]],
    label: [["category", "name"]],
  },
  {
    name: "status",
    label: [["status"]],
    getStatusName: true,
  },
];

export const filterCategoryVisitPending = [
  {
    name: "Field Technician",
    value: [["fieldTechnician", "id"]],
    label: [["fieldTechnician", "fullName"]],
  },
  {
    name: "category",
    value: [["category", "id"]],
    label: [["category", "name"]],
  },
];

export const filterCategoryComplaint = [
  {
    name: "Field Technician",
    value: [["fieldTechnician", "id"]],
    label: [["fieldTechnician", "fullName"]],
  },
  {
    name: "category",
    value: [["category", "id"]],
    label: [["category", "name"]],
  },
  {
    name: "status",
    label: [["status"]],
    getStatusName: true,
  },
];

export const filterCategorySearch = [
  {
    name: "category",
    value: [["category", "id"]],
    label: [["category", "name"]],
  },
];
