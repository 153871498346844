import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import successIcon from "../../../assets/icons/successIcon.svg";
import ExpenseViewDialog from "../../UI/viewDialogs/expense/ExpenseViewDialog";
import UseDialogBox from "../../../CustomHooks/UseDialogBox";
import ImageSlider from "../../UI/viewDialogs/ImageSlider";
import rejected from "../../../assets/icons/rejected.svg";
import { getExpenseStatus } from "../../../functions/getExpenseStatus";
import JustifiedHeading from "../../UI/headingsUI/JustifiedHeading";
import FilterSearch from "../../FilterSearch";
import { expenseSearch } from "../../../utils/tableSearchList";
import { filterCategoryExpensePending } from "../../../utils/filterCategoryList";
import TableContent from "./TableContent";
import Dialog from "../../UI/Dialog";
import { countTrueFalse } from "../../../functions/countTrueFalse";

const MainPending = ({
  pageTitle,
  viewDialogOpen,
  setViewDialogOpen,
  handleConfirmDialogBox,
  confirmDialogBox,
  setConfirmDialogBox,
  isActionSucceed,
  setIsActionSucceed,
  buttonStatus,
  handleCheck,
  handleMultiCheckConfirmDialog,
  checkLoading,
  checkResponseData,
  updateStateOnAddRemark,
  isLoaded,
}) => {
  const [isMultipleCheck, setMultipleCheck] = useState(false);
  const [checkedData, setCheckedData] = useState(() => {
    console.log("State initialized!");
    return [];
  });
  const [checkedRow, setCheckedRow] = useState([]);
  const [isAllCheck, setAllCheck] = useState(false);
  const [checkedType, setCheckedType] = useState({
    accept: false,
    reject: false,
  });
  const [expenseViewDetails, setExpenseViewDetails] = useState();
  const [isViewOpen, setIsViewOpen] = useState(false);
  const { showDialogBox, setShowDialogBox } = UseDialogBox();
  const [imageSliderPics, setImageSliderPics] = useState([]);
  const [imageSliderCurrentIndex, setImageSliderCurrentIndex] = useState();
  const data = useSelector((state) => state.data.data);
  const [copyData] = useState(data.slice());

  const enableMultiCheckApplyBtn =
    checkedRow.length === checkedData.length &&
    checkedRow.length !== 0 &&
    checkedData.length !== 0;

  const isCopyData = copyData.length > 0;

  const openViewDialog = (expenseData) => {
    setIsViewOpen(true);
    setViewDialogOpen(true);
    setExpenseViewDetails(expenseData);
  };
  const closeViewDialog = () => {
    setViewDialogOpen(false);
    setIsViewOpen(false);
  };

  const closeConfirmDialogBox = () => {
    setConfirmDialogBox(false);
    if (isViewOpen) {
      setViewDialogOpen(true);
    }
  };

  const openImageSlider = (Pics) => {
    closeViewDialog();
    setShowDialogBox(true);
    setImageSliderPics(Pics);
  };

  const closeImageSlider = () => {
    setViewDialogOpen(true);
    setShowDialogBox(false);
  };

  const cancelMultipleCheck = () => {
    setAllCheck(false);
    setMultipleCheck(false);
    setCheckedRow([]);
    // setCheckedData([]);
    setCheckedType({
      accept: false,
      reject: false,
    });
  };

  const closeSuccessDialog = () => {
    setIsActionSucceed(false);
    localStorage.removeItem("dataCheckCounts");
  };

  useEffect(() => {
    if (buttonStatus === "multiple") {
      cancelMultipleCheck();
    }
    const a = () => {
      closeSuccessDialog();
    };
    isActionSucceed && setTimeout(a, 2000);
  }, [isActionSucceed]);

  const handleMultiCheck = () => {
    localStorage.setItem(
      "dataCheckCounts",
      JSON.stringify(countTrueFalse(checkedData))
    );
    handleCheck();
  };

  return (
    <>
      {/* page title container for mobile */}
      <JustifiedHeading title={pageTitle} />
      {/* data filter and data search container  */}
      {isCopyData ? (
        <FilterSearch
          categories={filterCategoryExpensePending}
          searchCategories={expenseSearch}
          getStatus={getExpenseStatus}
          expenseCheck={true}
          isMultipleCheck={isMultipleCheck}
          setMultipleCheck={setMultipleCheck}
          enableMultiCheckApplyBtn={enableMultiCheckApplyBtn}
          cancelMultipleCheck={cancelMultipleCheck}
          handleMultiCheckConfirmDialog={handleMultiCheckConfirmDialog}
          checkedData={checkedData}
          checkedRow={checkedRow}
          isActionSucceed={isActionSucceed}
          isLoaded={isLoaded}
          isCopyData={isCopyData}
        />
      ) : (
        ""
      )}
      {/* pending expense table list */}
      <TableContent
        openViewDialog={openViewDialog}
        handleConfirmDialogBox={handleConfirmDialogBox}
        isMultipleCheck={isMultipleCheck}
        checkedData={checkedData}
        setCheckedData={setCheckedData}
        isAllCheck={isAllCheck}
        setAllCheck={setAllCheck}
        checkedRow={checkedRow}
        setCheckedRow={setCheckedRow}
        setCheckedType={setCheckedType}
        checkedType={checkedType}
      />
      {/* show view dialog box for the specific expense details */}
      {viewDialogOpen && (
        <ExpenseViewDialog
          closeDialog={closeViewDialog}
          viewDetails={expenseViewDetails}
          openImageSlider={openImageSlider}
          setImageSliderCurrentIndex={setImageSliderCurrentIndex}
          accept={() => handleConfirmDialogBox(expenseViewDetails.id, true)}
          reject={() => handleConfirmDialogBox(expenseViewDetails.id, false)}
          updateStateOnAddRemark={updateStateOnAddRemark}
          setExpenseViewDetails={setExpenseViewDetails}
        />
      )}
      {/* show confirm dialog box before single expense checked (accept or reject) */}
      {confirmDialogBox && !isMultipleCheck && (
        <Dialog
          message={
            buttonStatus
              ? "Are you sure you want to Accept this expense ?"
              : "Are you sure you want to Reject this expense ?"
          }
          confirmBtnText={!checkLoading ? "Confirm" : "Loading..."}
          cancelBtnText="Cancel"
          closeDialog={closeConfirmDialogBox}
          checkAction={!checkLoading ? handleCheck : undefined}
        />
      )}
      {/* show confirm dialog box before multiple expenses checked (accept and reject) */}
      {confirmDialogBox && isMultipleCheck && (
        <Dialog
          message="Are you sure you want to check these Expenses ?"
          confirmBtnText={!checkLoading ? "Confirm" : "Loading..."}
          cancelBtnText="Cancel"
          closeDialog={closeConfirmDialogBox}
          checkAction={!checkLoading ? handleMultiCheck : undefined}
          numbersOfAcceptAndReject={countTrueFalse(checkedData)}
        />
      )}
      {/* show success dialog box after single expense checked (accept or reject) */}
      {isActionSucceed && (buttonStatus === true || buttonStatus === false) && (
        <Dialog
          message={
            buttonStatus
              ? "Expense Successfully Accepted!"
              : "Expense Successfully Rejected!"
          }
          messageIcon={buttonStatus ? successIcon : rejected}
          closeDialog={closeSuccessDialog}
        />
      )}
      {/* show success dialog box after multiple expenses checked (accept and reject)*/}
      {isActionSucceed && buttonStatus === "multiple" && (
        <Dialog
          message={`Success! The total expense of ${checkResponseData.accepted} has been checked.`}
          messageIcon={successIcon}
          closeDialog={closeSuccessDialog}
        />
      )}
      {/* show view dialog images in image slider box */}
      {showDialogBox && (
        <ImageSlider
          title="Expense Photos"
          images={imageSliderPics}
          imageSliderCurrentIndex={imageSliderCurrentIndex}
          closeDialogBox={closeImageSlider}
        />
      )}
    </>
  );
};

export default MainPending;
