import React from "react";

import checkIcon from "../assets/icons/checkIcon.svg";
import crossBlue from "../assets/icons/closeBlue1.svg";
import LeftIconButton from "./UI/buttonUI/LeftIconButton";

const MultipleCheck = ({
  isMultipleCheck,
  setMultipleCheck,
  enableMultiCheckApplyBtn,
  cancelMultipleCheck,
  handleMultiCheckConfirmDialog,
  checkedData,
}) => {
  const handleMultipleCheck = () => {
    setMultipleCheck(true);
  };
  const checkDataObj = { ids: [], statuses: [] };
  checkedData.forEach((item) => {
    checkDataObj.ids.push(item.id);
    checkDataObj.statuses.push(item.status);
  });
  return (
    <>
      {isMultipleCheck ? (
        <div className="flex h-full">
          <LeftIconButton
            text="Apply"
            textColor={`text-white font-figtreeMedium ${
              enableMultiCheckApplyBtn
                ? ""
                : "cursor-not-allowed pointer-events-none"
            }`}
            bgColor={`${
              enableMultiCheckApplyBtn
                ? "bg-dark-blue border:dark-blue"
                : "cursor-not-allowed pointer-events-none bg-blue-disable"
            }`}
            icon={checkIcon}
            iconStyle="w-4 2xs:max-md:w-3"
            margin="mr-1"
            hover="hover:bg-white dark-blue-filter hover:border-dark-blue hover:text-dark-blue"
            onClick={() =>
              handleMultiCheckConfirmDialog(
                checkDataObj.ids,
                checkDataObj.statuses
              )
            }
          />

          <LeftIconButton
            text="Cancel"
            textColor="text-dark-blue font-figtreeMedium"
            bgColor="bg-lg5 border-lg5"
            icon={crossBlue}
            iconStyle="w-4 2xs:max-md:w-3"
            hover="hover:bg-transparent hover:border-dark-blue"
            onClick={cancelMultipleCheck}
          />
        </div>
      ) : (
        <LeftIconButton
          text="Select Multiple"
          textColor="text-white"
          bgColor="bg-dark-blue border:dark-blue"
          icon={checkIcon}
          iconStyle="W-4 2xs:max-md:w-3"
          hover="hover:bg-white dark-blue-filter hover:border-dark-blue hover:text-dark-blue"
          onClick={handleMultipleCheck}
        />
      )}
    </>
  );
};
export default MultipleCheck;