import React from "react";
import { useLocation } from "react-router-dom";

import SideBar from "./sideBar/SideBar";
import CustomHeader from "./header/CustomHeader";
import BreadCrumb from "./BreadCrumb";
import OpenSidebar from "../CustomHooks/OpenSidebar";

const MainPageLayout = ({
  children,
  headerConfig,
  ExpenseListTabs,
  numberOfData,
}) => {
  const { openSidebar, setOpenSidebar } = OpenSidebar();
  const location = useLocation();
  const currentUrl = location.pathname;
  const pathParts = currentUrl.split("/").filter((part) => part !== "");
  const mainMenu = pathParts[0];
  const showBreadCrumb = mainMenu === "home" || mainMenu === "profile";
  return (
    <div className="h-full bg-gray-100">
      <div className="flex flex-wrap h-full">
        <SideBar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
        <div className="page-layout--container">
          <CustomHeader
            setOpenSidebar={setOpenSidebar}
            headerConfig={headerConfig}
            ExpenseListTabs={ExpenseListTabs}
            numberOfData={numberOfData}
          />
          <div className="px-3">
            {showBreadCrumb && <BreadCrumb mainMenu={mainMenu} />}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MainPageLayout;