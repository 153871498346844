import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionContext } from "supertokens-auth-react/recipe/session";

import ProfileHeader from "../../components/user/profile/ProfileHeader";
import ProfileTabs from "../../components/user/profile/ProfileTabs";
import useGetData from "../../CustomHooks/useAxios/useGetData";
import { GET_USER } from "../../api/allApi_List";
import DialogBox from "../../components/UI/DialogBox";
import spin from "../../assets/icons/spin.png";
import { handleErrMessage } from "../../functions/handleErrMessage";
import MainPageLayout from "../../components/MainPageLayout";

const ViewProfile = () => {
  const { data, isLoaded, error, getData, errMsg } = useGetData();
  const navigate = useNavigate();
  let session = useSessionContext();
  let sessionLoading = session.loading;

  useEffect(() => {
    if (!sessionLoading) {
      let userId = session.accessTokenPayload.custom_user_id;
      getData(GET_USER + userId, navigate);
    }
  }, [session]);

  return (
    <MainPageLayout headerConfig={{ title: "Profile" }}>
      {isLoaded && !error ? (
        <div className="profile-container">
          <div className="profileWrap">
            <div className="bg-white">
              <ProfileHeader userData={data} />
            </div>
            <ProfileTabs userData={data} />
          </div>
        </div>
      ) : error ? (
        <DialogBox
          title="Error"
          message={handleErrMessage(errMsg)}
          isErrorHandleButtons={true}
        />
      ) : (
        <DialogBox message="Loading..." animateIcon={spin} />
      )}
    </MainPageLayout>
  );
};

export default ViewProfile;
