import React from "react";

export default function ProfileDetails(props) {
  return (
    <div className="py-1">
      <p className="font-figtree text-sm text-dg2">{props.title}</p>
      <p className="font-figtree text-sm text-black">{props.value}</p>
    </div>
  );
}
