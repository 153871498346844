import React from "react";

export default function GradientButton(props) {
  return (
      <button
        className={`bg-button-bg text-white p-0.5 font-poppins border-2 hover:text-black transition duration-75 ease-linear ${props.width} ${props.rounded}`}
        onClick={props.onClick}
      >
        <p
          className={`bg-button-bg hover:bg-white-gradient rounded-full p-2 transition duration-150 ease-linear ${props.rounded}`}
        >
          {props.btnText}
        </p>
      </button>
  );
}
