import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateData } from "../redux/dataSlice";
import search from "../assets/icons/search.svg";

const getNestedProperty = (data, searchKeys, searchVal, getStatus) => {
  let keyValArr = [];
  Object.keys(searchKeys).forEach((i) => {
    let keysVal = searchKeys[i].reduce((obj, key) => obj && obj[key], data);
    if (keysVal !== null && keysVal !== undefined) {
      if (i === "status" && getStatus) {
        keyValArr.push(getStatus(keysVal).status.toLowerCase());
      } else {
        keyValArr.push(keysVal.toString().toLowerCase());
      }
    }
  });
  let checkValues = [];
  keyValArr.forEach((val) => {
    checkValues.push(val.includes(searchVal));
  });
  return checkValues.some((i) => i);
};

const SearchTable = ({
  searchCategories,
  getStatus,
  currentListTab,
  copyOriginalData,
  isActionSucceed,
}) => {
  const dispatch = useDispatch();
  const isFiltered = useSelector((state) => state.isTableFilter.isFiltered);
  const filteredData = useSelector((state) => state.isTableFilter.filteredData);

  const data = useSelector((state) => state.data.data);
  const [originalData, setOriginalData] = useState(data.slice());
  let copyData = isFiltered ? filteredData : originalData;

  const handleSearch = (e) => {
    let searchVal = e.target.value.trim().toLowerCase();
    if (searchVal.length > 0) {
      const filtered = copyData.filter((item) => {
        return getNestedProperty(item, searchCategories, searchVal, getStatus);
      });
      dispatch(updateData(filtered));
    } else {
      dispatch(updateData(copyData));
    }
  };

  // set original data on search when data checked
  useEffect(() => {
    if (isActionSucceed) {
      setOriginalData(data.slice());
    }
  }, [data, isActionSucceed]);

  // update data to be searched when clicked on expenses tabs
  useEffect(() => {
    if (currentListTab) {
      setOriginalData(copyOriginalData[currentListTab]);
    }
  }, [copyOriginalData, currentListTab]);

  return (
    <p className="relative h-full">
      <input
        type="text"
        placeholder="Search"
        onChange={handleSearch}
        className="tableSearchInput"
      />
      <img src={search} alt="search" className="tableSearchImg" />
    </p>
  );
};

export default SearchTable;
