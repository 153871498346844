import React from "react";
import { Link } from "react-router-dom";

const BreadCrumb = (props) => {
  return (
    <div className="breadCum-wrap">
      {props.mainMenu === "profile" && (
        <h2 className="breadCum-txt">View Profile</h2>
      )}
      {props.mainMenu === "home" && (
        <h2 className="breadCum-txt">Welcome Back!</h2>
      )}
      <div className="flex">
        {props.mainMenu !== "home" && (
          <Link className="breadCum-link align-center" to="/home">
            Home
            <span className="font-poppins pl-1">{`>`}</span>
          </Link>
        )}

        <p className="breadCum-mainMenu align-center">{props.mainMenu}</p>
      </div>
    </div>
  );
};
export default BreadCrumb;