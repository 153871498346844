import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useGetData from "../../CustomHooks/useAxios/useGetData";
import DialogBox from "../../components/UI/DialogBox";
import spin from "../../assets/icons/spin.png";
import { SITE_SETUP } from "../../api/allApi_List";
import { ADD_SITE } from "../../api/allApi_List";
import AddSite from "../../components/site/AddSite";
import { handleErrMessage } from "../../functions/handleErrMessage";
import usePostData from "../../CustomHooks/useAxios/usePostData";
import MainPageLayout from "../../components/MainPageLayout";

const AddSitePage = () => {
  const navigate = useNavigate();
  const { data, isLoaded, error, getData, errMsg } = useGetData();
  const {
    executePost,
    loading,
    isSucceed,
    formError,
    formErrMsg,
    responseData,
    setIsSucceed,
    setFormError,
    setResponseData,
  } = usePostData();

  useEffect(() => {
    getData(SITE_SETUP, navigate);
  }, []);

  const sendData = async (formValues, reset, setSelectedOption) => {
    const updateStateOnResponse = () => {
      setSelectedOption({});
      // reset form value
      reset();
    };
    // API calling
    executePost(formValues, ADD_SITE, updateStateOnResponse);
  };

  return (
    <MainPageLayout headerConfig={{title:"Add Site"}}>
      {isLoaded && !error ? (
        <AddSite
          siteSetup={data}
          sendData={sendData}
          setIsSucceed={setIsSucceed}
          isSucceed={isSucceed}
          loading={loading}
          navigate={navigate}
          responseData={responseData}
          setResponseData={setResponseData}
        />
      ) : error ? (
        <DialogBox
          title="Error"
          message={handleErrMessage(errMsg)}
          isErrorHandleButtons={true}
        />
      ) : (
        <DialogBox message="Loading your data" animateIcon={spin} />
      )}
      {formError && (
        <DialogBox
          title="Error"
          btnText="Ok"
          message={handleErrMessage(formErrMsg)}
          closeAction={() => setFormError(false)}
        />
      )}
    </MainPageLayout>
  );
};

export default AddSitePage;
