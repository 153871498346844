import Session from "supertokens-auth-react/recipe/session";
import { UserRoleClaim } from "supertokens-auth-react/recipe/userroles";

 const UseUserRolesClaim = () => {
  let claimValue = Session.useClaimValue(UserRoleClaim);
  const getRoles = () => {
    if (claimValue.loading || !claimValue.doesSessionExist) {
      return null;
    }
    let roles  = claimValue.value;
    if (Array.isArray(roles)) {
      return roles;
    } else {
      return [];
    }
  };
  return { getRoles };
};

export default UseUserRolesClaim;
