import React from "react";
import { handleErrMessage } from "../../functions/handleErrMessage";
import greenCheck from "../../assets/icons/green_check.svg";
import redCheck from "../../assets/icons/red_check.svg";

function StatusMessage({ isSucceed, formError, formErrMsg }) {
  return (
    <>
      {isSucceed && (
        <div className="remark-msg">
          <img src={greenCheck} alt="success" />
          <p className="text-green-3 pl-2">Remark Successfully Deleted !</p>
        </div>
      )}
      {formError && (
        <div className="remark-msg">
          <img src={redCheck} alt="delete" />
          <p className="text-red-600 pl-2">{handleErrMessage(formErrMsg)}</p>
        </div>
      )}
    </>
  );
}

export default StatusMessage;
