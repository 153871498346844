// Development
const API_DOMAIN_DEV_URL = "https://be.dev.jaibharatconst.com";
// .env.production
const API_DOMAIN_ENV_URL = process.env.REACT_APP_BE_URL;

const API_DOMAIN = API_DOMAIN_ENV_URL || API_DOMAIN_DEV_URL;

const BASE_BACKEND_URL = `${API_DOMAIN}/api`;
export const BASE_IMAGE_URL =
  "https://jai-bharat-dev.s3.ap-south-1.amazonaws.com/";

// API list
export const LOGIN_URL = BASE_BACKEND_URL + "/authenticate";
export const CHANGE_PASSWORD = BASE_BACKEND_URL + "/user/change/password";
export const UUID_URL = BASE_BACKEND_URL + "/uuid";
export const GET_USER = BASE_BACKEND_URL + "/user/";
export const ALL_USER = BASE_BACKEND_URL + "/user/all";
export const ALL_OFFICER = BASE_BACKEND_URL + "/officer/all"
export const ADD_USER = BASE_BACKEND_URL + "/user";
export const UPDATE_USER = BASE_BACKEND_URL + "/user/";
export const USER_SETUP = BASE_BACKEND_URL + "/user/setup";
export const OFFICER_SETUP = BASE_BACKEND_URL + "/officer/setup";
export const ADD_OFFICER = BASE_BACKEND_URL + "/officer";

// Expense url
export const ALL_EXPENSES = BASE_BACKEND_URL + "/expense/all";
export const EXPENSE_SETUP = BASE_BACKEND_URL + "/expense/setup";
export const EXPENSE_PENDING_RECEIVER =
  BASE_BACKEND_URL + "/expense/pending/receiver";
export const EXPENSE_PENDING_SUPERVISOR =
  BASE_BACKEND_URL + "/expense/pending/supervisor";
export const EXPENSE_PENDING_ADMIN =
  BASE_BACKEND_URL + "/expense/pending/admin";
export const EXPENSE_PENDING_SUPER_ADMIN =
  BASE_BACKEND_URL + "/expense/pending/super-admin";
export const CHECK_RECEIVER_EXPENSE =
  BASE_BACKEND_URL + "/expense/check/receiver";
export const CHECK_SUPERVISOR_EXPENSE =
  BASE_BACKEND_URL + "/expense/check/supervisor";
export const CHECK_ADMIN_EXPENSE = BASE_BACKEND_URL + "/expense/check/admin";
export const CHECK_SUPER_ADMIN_EXPENSE =
  BASE_BACKEND_URL + "/expense/check/super-admin";
export const EXPENSE_HISTORY = BASE_BACKEND_URL + "/expense/check/history";
export const ADD_LOAN = BASE_BACKEND_URL + "/expense/loan";
export const LOAN_SETUP = BASE_BACKEND_URL + "/expense/loan/setup";
export const LOAN_History = BASE_BACKEND_URL + "/expense/loan/history";
export const EXPENSE_REMARK = BASE_BACKEND_URL + "/expense/remark";

//complaint module url
export const ALL_COMPLAINT = BASE_BACKEND_URL + "/complaint/all";
export const COMPLAINT_SETUP = BASE_BACKEND_URL + "/complaint/setup";
export const ADD_COMPLAINT = BASE_BACKEND_URL + "/complaint";
export const ALL_SITE = BASE_BACKEND_URL + "/station/all";
export const ALL_VISIT = BASE_BACKEND_URL + "/visit/all";
export const COMPLAINT_CHECK = BASE_BACKEND_URL + "/complaint/check";
export const VISIT_CHECK = BASE_BACKEND_URL + "/visit/check";
export const VISIT_PENDING = BASE_BACKEND_URL + "/visit/check/pending";
export const COMPLAINT_PENDING = BASE_BACKEND_URL + "/complaint/check/pending";
export const COMPLAINT_REMARK = BASE_BACKEND_URL + "/complaint/remark";
export const VISIT_REMARK = BASE_BACKEND_URL + "/visit/remark";

// download sheets url
export const UPLOAD_SHEET = BASE_BACKEND_URL + "/expense/sheet/upload";
export const UPLOAD_SHEET_SETUP =
  BASE_BACKEND_URL + "/expense/sheet/upload/setup";
export const ALL_UPLOADED_SHEET =
  BASE_BACKEND_URL + "/expense/sheet/upload/all";
export const SINGLE_UPLOADED_SHEET =
  BASE_BACKEND_URL + "/expense/sheet/upload/";

// Site url
export const HIER_SETUP = BASE_BACKEND_URL + "/hier/setup";
export const ADD_HIER = BASE_BACKEND_URL + "/hier";
export const ADD_SITE = BASE_BACKEND_URL + "/station";
export const SITE_SETUP = BASE_BACKEND_URL + "/station/setup";
export const ADD_MACHINE = BASE_BACKEND_URL + "/machine";
export const MACHINE_SETUP = BASE_BACKEND_URL + "/machine/setup";
export const ALL_MACHINE = BASE_BACKEND_URL + "/machine/all";
export const ADD_MACHINE_COMPONENT = BASE_BACKEND_URL + "/machine/component";
export const MACHINE_COMPONENT_SETUP =
  BASE_BACKEND_URL + "/machine/component/setup";
export const ASSIGN_MACHINE = BASE_BACKEND_URL + "/machine/station/";
export const ASSIGN_MACHINE_SETUP = BASE_BACKEND_URL + "/machine/station/setup";
export const SITE_REMARK = BASE_BACKEND_URL + "/station/remark";
export const MACHINE_REMARK = BASE_BACKEND_URL + "/machine/remark";

export const Add_EXPENSE = BASE_BACKEND_URL + "/expense";

export const PERMISSIONS = {
  viewUser: "view_user",
  addUser: "add_user",
  updateUser: "update_user",
  addOfficer: "add_officer",
  addExpense: "add_expense",
  addLoan: "add_loan",
  loanRepayment: "add_loan_expense",
  supCheckExpense: "sup_check",
  adminCheckExpense: "admin_check",
  superAdminCheckExpense: "sup_admin_check",
  addComplaint: "add_complaint",
  complaintCheck: "resolve_complaint",
  visitCheck: "check_visit",
  viewVisit: "view_visit",
  viewComplaint: "view_complaint",
  viewSite: "view_site",
  addSite: "add_site",
  addHier: "add_hier",
  addMachine: "add_machine",
  addDeductionExpense: "add_deduction_expense",
  addSheets: "add_sheets",
  viewSheets: "view_sheets",
  viewMachine: "view_machine",
  addMachineComponent: "add_machine_component",
};
