import React, { useState } from "react";
import FilterSearchDropdown from "./FilterSearchDropdown";
import useClickOutside from "../../CustomHooks/useClickOutside";

const DropdownContainer = ({
  name,
  selectedItemsLen,
  optionsList,
  valueAddress,
  setSelectedItems,
  getStatus,
  status,
  checkboxes,
  setCheckboxes,
  flags,
}) => {
  const [open, setOpen] = useState(-1);
  const { dropdownRef } = useClickOutside(() => setOpen(-1));
  const handleDropdown = () => {
    setOpen((prevIndex) => (prevIndex === 1 ? -1 : 1));
  };
  return (
    <div className="relative" ref={dropdownRef}>
      <button
        key={name}
        className={`filterDropdownContainer ${
          open === 1 ? "bg-light-gray-2" : "bg-white"
        } `}
        onClick={handleDropdown}
      >
        <span className="pr-5 2xs:max-md:pr-3">
          {name}
          {selectedItemsLen && selectedItemsLen["val"].length > 0 && (
            <span>{` (${selectedItemsLen["val"].length})`}</span>
          )}
        </span>
        <span
          className={`filterDropdownArr ${
            open === 1 ? "rotate-90" : "rotate-90n"
          }`}
        >{`<`}</span>
      </button>
      <FilterSearchDropdown
        open={open}
        category={name}
        optionList={optionsList}
        valueAddress={valueAddress}
        setSelectedItems={setSelectedItems}
        getStatus={getStatus}
        status={status}
        checkboxes={checkboxes}
        setCheckboxes={setCheckboxes}
      />
    </div>
  );
};
export default DropdownContainer;
