import React from "react";

import vectorProfile from "../../../assets/images/vectorProfile.svg";
import dummyUser from "../../../assets/images/dummyUser.svg";
import useImageUrl from "../../../CustomHooks/useImageUrl";
import { mapRoles } from "../../../utils/roles";
import UseUserRolesClaim from "../../../CustomHooks/UseUserRolesClaim";

const ProfileHeader = ({ userData }) => {
  const { getImageUrl } = useImageUrl();
  const { getRoles } = UseUserRolesClaim();
  const rolesArray = getRoles();
  const roleValues = rolesArray.map((role) => {
    return mapRoles[role];
  });
  return (
    <>
      <div className="profileHeaderContainer">
        <img
          src={
            userData.profilePicUrlList
              ? getImageUrl(userData.profilePicUrlList[0])
              : dummyUser
          }
          alt="userProfile"
          className="profileImg"
        />
        <img
          src={vectorProfile}
          alt="vectorProfile"
          className="profileVector"
        />
      </div>
      <div className="email-role-wrap">
        <p className="profile-email-text">{userData.email}</p>
        <div className="max-w-5xl flex flex-wrap">
          {roleValues.map((role, index) => (
            <p key={index} className="profile-role">
              {role}
            </p>
          ))}
        </div>
      </div>
    </>
  );
};
export default ProfileHeader;
