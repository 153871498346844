import { useRef, useEffect } from "react";

const useClickOutside = (close) => {
    const dropdownRef = useRef(null);
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        close(1);
      }
    };
  
    useEffect(() => {
      const listener = document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", listener);
    }, []);
    
  return { dropdownRef };
};

export default useClickOutside;
