import React from "react";
import notFound from "../assets/images/noFound.svg";

const PageNotFound = () => {
  return (
    <div className="page-not-found-wrap">
      <div>
        <img src={notFound} alt="notFound" />
        <h2 className="page-not-found-heading">Page not found</h2>
      </div>
    </div>
  );
};

export default PageNotFound;
