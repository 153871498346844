import { useEffect, useState } from "react";
import searchIcon from "../../../assets/icons/search.svg";
import useClickOutside from "../../../CustomHooks/useClickOutside";
import sortDataByAlphabetically from "../../../functions/sorting/sortDataByAlphabetically";
import sortDataByNumber from "../../../functions/sorting/sortDataByNumber";

const SearchDropdown = ({
  name,
  label,
  keyName,
  id,
  options,
  setSelectedOption,
  selectedOption,
  setValue,
  register,
  error,
  search,
  render,
  disabled,
  optionListIsNumber,
}) => {
  const [searchList, setSearchList] = useState([]);
  const [open, setOpen] = useState(-1);
  const { dropdownRef } = useClickOutside(() => setOpen(-1));

  const handleShowDropdown = () => {
    setOpen((prevIndex) => (prevIndex === 1 ? -1 : 1));
  };
  const handleOptionClick = (id, keyName) => {
    setSelectedOption({ ...selectedOption, [name]: keyName });
  
    setValue(name, id, { shouldValidate: true });
    setOpen(-1);
  };

  const handleSearch = (e) => {
    let value = e.target.value;
    const filter = options.filter((item) =>
      item[keyName]
        .toString()
        .toLowerCase()
        .includes(value.toString().toLowerCase())
    );
    setSearchList(filter);
  };

  useEffect(() => {
    const sortedOptions = optionListIsNumber
      ? sortDataByNumber(options, keyName)
      : sortDataByAlphabetically(options, [keyName]);
    setSearchList(sortedOptions);
  }, [options, render]);

  return (
    <div className="relative">
      <label htmlFor={name} className="text-dg2 text-sm font-poppins">
        {label}
      </label>
      <div className="relative" ref={dropdownRef}>
        <p onClick={() => handleShowDropdown()}>
          <span
            className={`font-poppins absolute right-2 top-3 cursor-pointer font-semibold ${
              open === 1 ? "rotate-90" : "rotate-90n"
            }`}
          >{`<`}</span>
          {selectedOption[name] && (
            <span className="text-sm font-figtree absolute left-3 top-3 bg-white pr-3">
              {selectedOption[name]}
            </span>
          )}
          <input
            name={name}
            type="text"
            readOnly="readonly"
            placeholder="Select"
            {...register}
            className={`w-full border-bw cursor-pointer focus-visible:outline-dark-blue rounded-md py-3 px-3 font-figtree text-sm placeholder:text-sm placeholder:font-figtree placeholder:text-black ${
              error ? "border-red-500" : "border-lg4"
            }`}
            disabled={disabled}
          />
        </p>
        <div
          className={` bg-white w-full rounded absolute border-1 mx-1 py-2 border-lg2 left-0 z-99 shadow-sh-1 transition-all ease-in-out duration-300 ${
            open === 1 ? "grid opacity-1" : "invisible opacity-0"
          }`}
        >
          {search ? (
            <p className="mx-2 mb-2">
              <input
                type="text"
                placeholder="Search"
                onChange={handleSearch}
                className="focus-visible:border-dark-blue w-full text-dark-blue focus-visible:outline-0 border-1 border-dark-blue rounded h-9 pl-2 pr-5 placeholder:text-dark-blue placeholder:text-sm placeholder:font-poppins"
              />
              <img
                src={searchIcon}
                alt="search"
                className="absolute right-3 top-5 w-3"
              />
            </p>
          ) : (
            ""
          )}
          {searchList.length > 0 ? (
            <ul
              className={`${
                searchList.length > 6 ? "overflow-y-scroll h-40" : ""
              }`}
            >
              {searchList.map((item, index) => {
                return (
                  <li
                    onClick={() => handleOptionClick(item[id], item[keyName])}
                    className={`px-4 cursor-pointer hover:bg-sec-blue hover:text-white text-dg2 font-figtree ${
                      selectedOption[name] === item[keyName]
                        ? "bg-sec-blue text-white"
                        : ""
                    }`}
                    key={`${id}-${index}`}
                  >
                    {item[keyName]}
                  </li>
                );
              })}
            </ul>
          ) : (
            <p className="py-1 px-2 font-figtree text-red-400">Not Found</p>
          )}
        </div>
        <p
          className={`text-red-500 font-figtree text-xs h-4 ${
            error ? "visible" : "invisible"
          }`}
        >
          {error?.message}
        </p>
      </div>
    </div>
  );
};

export default SearchDropdown;
