import { getNestedProperty } from "../getNestedProperty";

const sortDataByAlphabetically = (data, propertyArr) => {
  let sortedData = data.slice().sort((a, b) => {
    const aVal = getNestedProperty(a, propertyArr);
    const bVal = getNestedProperty(b, propertyArr);
    let nameA = aVal;
    let nameB = bVal;
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  return sortedData;
};
export default sortDataByAlphabetically;
