import DetailRow from "../DetailRow";
import Modal from "../../../portal/Modal";
import GradientButton from "../../buttonUI/GradientButton";

export default function OfficerViewDetailsDialog({
  closeDialog,
  viewDetails,
  message,
  modalNotClose,
  messageIcon,
}) {
  return (
    <Modal closeDialog={modalNotClose}>
      <div className="officer-dialog-content absolute-center-align">
        <img src={messageIcon} alt="messageIcon" className="mx-auto mb-1 w-9" />
        <p className="view-success-msg-d">{message}</p>
        <div className="view-dialog-content-wrap">
          <DetailRow title="Officer ID" value={viewDetails.id} />
          <DetailRow title="Officer Name" value={viewDetails.fullName} />
        </div>

        <div className="mt-5 pt-3 text-center">
          <GradientButton
            btnText="Ok"
            width="w-56"
            rounded="rounded-full"
            onClick={closeDialog}
          />
        </div>
      </div>
    </Modal>
  );
}
