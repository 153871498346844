import React, { useState } from "react";
import blueArrow from "../../assets/icons/blueArrow.svg";
import remarkIcon from "../../assets/icons/remarkIcon.svg";
import AddRemarkForm from "./AddRemarkForm";
import RemarkList from "./RemarkList";
import UsePermissionsClaim from "../../CustomHooks/UsePermissionsClaim";

const Remarks = ({
  remarkList,
  dataId,
  apiUrl,
  updateStateOnAddRemark,
  setViewDetails,
  isRemarkDelete,
}) => {
  const [OpenRemarkList, setOpenRemarkList] = useState(false);
  const [showAddRemark, setShowAddRemark] = useState(false);

  const { checkPermission } = UsePermissionsClaim();
  const permAddRemark = checkPermission("add_remark");
  const checkRemarkPerm = isRemarkDelete
    ? permAddRemark && isRemarkDelete
    : true;
  const checkRemarkDelete = permAddRemark && isRemarkDelete;

  const handleRemarkList = () => {
    setOpenRemarkList((prev) => !prev);
    setShowAddRemark(false);
  };

  const handleAddRemark = () => {
    setShowAddRemark(true);
    setOpenRemarkList(false);
  };

  return (
    <>
      <div className="main-remark-container">
        <button
          className={`text-dark-blue 2xs:max-lg:text-sm ${
            remarkList ?? "cursor-pointer"
          } p-4 w-full text-left relative`}
          onClick={remarkList ? handleRemarkList : undefined}
        >
          <span className="font-semibold font-figtree">Remarks</span>
          {remarkList && (
            <img
              src={blueArrow}
              alt="arrow"
              className={`2xs right-5 w-5 py-2 absolute ${
                OpenRemarkList ? "rotate-180 top-3" : "top-4"
              }`}
            />
          )}
        </button>
        {checkRemarkPerm && (
          <p
            className={`absolute top-4 ${
              remarkList ? "right-10 2xs:max-sm:right-12" : "right-4"
            }`}
          >
            <button
              onClick={handleAddRemark}
              className="add-remark-btn dark-blue-filter"
            >
              <img src={remarkIcon} alt="btnIcon" className="pr-1 w-4" />
              Add Remark
            </button>
          </p>
        )}
        <AddRemarkForm
          dataId={dataId}
          apiUrl={apiUrl}
          updateStateOnAddRemark={updateStateOnAddRemark}
          setViewDetails={setViewDetails}
          showAddRemark={showAddRemark}
          setShowAddRemark={setShowAddRemark}
          setOpenRemarkList={setOpenRemarkList}
        />
        <RemarkList
          remarkList={remarkList}
          isRemarkDelete={checkRemarkDelete}
          OpenRemarkList={OpenRemarkList}
          dataId={dataId}
          updateStateOnAddRemark={updateStateOnAddRemark}
          setViewDetails={setViewDetails}
          apiUrl={apiUrl}
        />
      </div>
    </>
  );
};
export default Remarks;
