import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateData } from "../../../redux/dataSlice";
import arrow from "../../../assets/icons/arrowLeft.svg";

const ExpenseListTabs = ({
  currentListTab,
  SetCurrentListTab,
  copyOriginalData,
  setIsTabClicked,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleTabs = (clickedTab) => {
    if (currentListTab !== clickedTab) {
      setIsTabClicked((prev) => !prev);
      SetCurrentListTab(clickedTab);
      dispatch(updateData(copyOriginalData[clickedTab]));
    }
  };
  const handleBackButtonClick = () => {
    navigate(-1);
  };
  const getClassNames = (tabName) => {
    return `mr-2 cursor-pointer border-b-2 ${
      currentListTab === tabName
        ? "text-white font-figtreeBold border-dark-white"
        : "border-transparent"
    }`;
  };
  return (
    <div className="flex font-figtree text-white">
      <div className="pr-2 mt-1">
        {" "}
        <img
          src={arrow}
          alt="arrow"
          className="w-3 cursor-pointer"
          onClick={handleBackButtonClick}
        />
      </div>
      <h4
        onClick={() => handleTabs("expenses")}
        className={getClassNames("expenses")}
      >
        Expenses{" "}
        {currentListTab === "expenses" && (
          <span className="text-sm">
            ({copyOriginalData["expenses"]?.length})
          </span>
        )}
      </h4>
      <h4
        onClick={() => handleTabs("loanExpenses")}
        className={getClassNames("loanExpenses")}
      >
        Loan Expenses{" "}
        {currentListTab === "loanExpenses" && (
          <span className="text-sm">
            ({copyOriginalData["loanExpenses"]?.length})
          </span>
        )}
      </h4>
      <h4
        onClick={() => handleTabs("pendingExpenses")}
        className={getClassNames("pendingExpenses")}
      >
        Pending Expenses{" "}
        {currentListTab === "pendingExpenses" && (
          <span className="text-sm">
            ({copyOriginalData["pendingExpenses"]?.length})
          </span>
        )}
      </h4>
    </div>
  );
};

export default ExpenseListTabs;
