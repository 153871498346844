import { signOut } from "supertokens-auth-react/recipe/emailpassword";

export async function logoutHandler(navigate) {
  await signOut()
    .then((res) => {
      localStorage.removeItem("uuid");
      localStorage.removeItem("userId");
      localStorage.removeItem("userName");
      localStorage.removeItem("profilePic");
      navigate("/login");
    })
    .catch((error) => {
      console.log(error, "ERROR");
    });
}
