import React from "react";
import notResult from "../../assets/images/notResult.svg";
import PageHeading from "./headingsUI/CenteredHeading";

const NoDataDisplay = () => {
  return (
    <div className="noDataDisplayContainer">
      <img src={notResult} alt="notResult" className="mx-auto" />
      <PageHeading title="No data available!" />
    </div>
  );
};
export default NoDataDisplay;
