import React, { useState, useRef, useEffect } from "react";
import { mapRoles } from "../../../utils/roles";

const MultipleChecks = ({ options, register, error, roles }) => {
  const [open, setOpen] = useState(-1);
  const dropdownRef = useRef(null);

  const isRole = roles ? (roles.length !== 0 ? true : false) : false;

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(-1);
    }
  };

  useEffect(() => {
    const listener = document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", listener);
  }, []);

  const handleShowDropdown = () => {
    setOpen((prevIndex) => (prevIndex === 1 ? -1 : 1));
  };
  return (
    <>
      <label htmlFor="role" className="checkBoxDropdownLabel">
        Role *
      </label>
      <div className="relative" ref={dropdownRef}>
        <p
          onClick={() => handleShowDropdown()}
          className={`checkBoxDropdownLabelWrap ${
            error ? "border-red-500" : "border-lg4"
          }`}
        >
          {isRole ? `Role (${roles.length})` : "Select"}
          <span
            className={`checksDropdownArr ${
              open === 1 ? "rotate-90" : "rotate-90n"
            }`}
          >{`<`}</span>
        </p>
        <div
          className={`checksOptionWrap ${
            open === 1 ? "grid opacity-1" : "invisible opacity-0"
          }`}
        >
          <ul className="h-40 overflow-y-scroll">
            {options.map((item, ind) => {
              return (
                <li key={ind} className="checksItems">
                  <input
                    type="checkbox"
                    id={item}
                    value={item}
                    {...register}
                    className="accent-input"
                  />
                  <label htmlFor={item} className="checksLabel">
                    {mapRoles[item]}
                  </label>
                  <br></br>
                </li>
              );
            })}
          </ul>
        </div>
        <p className={`input-err ${error ? "visible" : "invisible"}`}>
          {error?.message}
        </p>
      </div>
    </>
  );
};
export default MultipleChecks;
