import React, { useState } from "react";
import CompanyInfoTab from "./CompanyInfoTab";
import PersonalInfoTab from "./PersonalInfoTab";

const ProfileTabs = ({ userData }) => {
  const [activeTab, setActiveTab] = useState("personal");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="profile-tabs-main">
        <button
          onClick={() => handleTabChange("personal")}
          className={`px-1 font-figtree border-b-2 ${
            activeTab === "personal" ? "profileTabActive" : "profileTab"
          }`}
        >
          Personal Info
        </button>
        <button
          onClick={() => handleTabChange("company")}
          className={`px-2 font-figtree border-b-2 ${
            activeTab === "company" ? "profileTabActive" : "profileTab"
          }`}
        >
          Company Info
        </button>
      </div>
      <div className="profileTabContentWrap">
        {activeTab === "company" && <CompanyInfoTab userData={userData} />}
        {activeTab === "personal" && <PersonalInfoTab userData={userData} />}
      </div>
    </>
  );
};
export default ProfileTabs;
