export function formatDate(dateString) {
    const date = new Date(dateString);
    var months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const day = dateString.length === 10 ? date.getUTCDate() : date.getDate();
    const monthIndex = dateString.length === 10 ? date.getUTCMonth() : date.getMonth();
    const year = dateString.length === 10 ? date.getUTCFullYear() : date.getFullYear();

    return day + " " + months[monthIndex] + " " + year;
  }