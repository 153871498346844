import TableRow from "./TableRow";
import { formatDate } from "../../../../functions/dateTime/formatDate";
import { getTimeString } from "../../../../functions/dateTime/getTimeString";

const CheckedInfoTable = ({
  receiverChecked,
  supervisorChecked,
  adminChecked,
  superAdminChecked,
}) => {
  const labels = [
    "Receiver Checked At",
    "Supervisor Checked At",
    "Admin Checked At",
    "Company Checked At",
  ];


  return (
    <>
      {receiverChecked && (
        <TableRow
          label={labels[0]}
          value={`${formatDate(receiverChecked)} ${getTimeString(
            receiverChecked
          )}`}
          isAlternate={true}
        />
      )}
      {supervisorChecked && (
        <TableRow
          label={labels[1]}
          value={`${formatDate(supervisorChecked)} ${getTimeString(
            supervisorChecked
          )}`}
          isAlternate={false}
        />
      )}
      {adminChecked && (
        <TableRow
          label={labels[2]}
          value={`${formatDate(adminChecked)} ${getTimeString(adminChecked)}`}
          isAlternate={true}
        />
      )}
      {superAdminChecked && (
        <TableRow
          label={labels[3]}
          value={`${formatDate(superAdminChecked)} ${getTimeString(
            superAdminChecked
          )}`}
          isAlternate={false}
        />
      )}
    </>
  );
};

export default CheckedInfoTable;
