import React from "react";
import SearchDropdown from "../../UI/PageInput/SearchDropdown";
import MultipleChecks from "./MultipleChecks";

const CompanyInfo = ({
  userSetup,
  register,
  errors,
  setValue,
  roles,
  selectedOption,
  setSelectedOption,
  render,
}) => {
  return (
    <div className="halfCol pr-1">
      <div className="userInfoWrap">
        <h4 className="userInfoHeading">Company Info</h4>
        <div className="px-4 py-6">
          <MultipleChecks
            options={userSetup.roles}
            register={{
              ...register("roles", {
                required: "Please select at least one role",
              }),
            }}
            error={errors.roles}
            roles={roles}
          />
          <div className="flex">
            <div className="w-full pr-1">
              <SearchDropdown
                name="jbcc_supervisor_id"
                label="Supervisor (JBCC) *"
                keyName="fullName"
                id="id"
                options={userSetup.jbccSupervisors}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
                setValue={setValue}
                register={{
                  ...register("jbcc_supervisor_id", {
                    required: "Please select a Supervisor JBCC",
                  }),
                }}
                error={errors.jbcc_supervisor_id}
                render={render}
              />
            </div>
            <div className="w-full pl-1">
              <SearchDropdown
                name="jbcc_admin_id"
                label="Admin (JBCC) *"
                keyName="fullName"
                id="id"
                options={userSetup.jbccAdmins}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
                setValue={setValue}
                register={{
                  ...register("jbcc_admin_id", {
                    required: "Please select a Admin JBCC",
                  }),
                }}
                error={errors.jbcc_admin_id}
                render={render}
              />
            </div>
          </div>
          <SearchDropdown
            name="jn_supervisor_id"
            label="Supervisor (Jal Nirikshan) *"
            keyName="fullName"
            id="id"
            options={userSetup.jnSupervisors}
            setSelectedOption={setSelectedOption}
            selectedOption={selectedOption}
            setValue={setValue}
            register={{
              ...register("jn_supervisor_id", {
                required: "Please select a Supervisor Jal Nirikshan",
              }),
            }}
            error={errors.jn_supervisor_id}
            render={render}
          />
          <SearchDropdown
            name="jn_admin_id"
            label="Admin (Jal Nirikshan) *"
            keyName="fullName"
            id="id"
            options={userSetup.jnAdmins}
            setSelectedOption={setSelectedOption}
            selectedOption={selectedOption}
            setValue={setValue}
            register={{
              ...register("jn_admin_id", {
                required: "Please select a Admin Jal Nirikshan",
              }),
            }}
            error={errors.jn_admin_id}
            render={render}
          />
          {/* <div className="halfCol">
          <div className="px-1">
          <div className="flex">
              <div className="w-full pr-1">
                <SearchDropdown
                  name="jbcc_supervisor_id"
                  label="Supervisor (JBCC) *"
                  keyName="fullName"
                  id="id"
                  options={userSetup.jbccSupervisors}
                  setSelectedOption={setSelectedOption}
                  selectedOption={selectedOption}
                  setValue={setValue}
                  register={{
                    ...register("jbcc_supervisor_id", {
                      required: "Please select a Supervisor JBCC",
                    }),
                  }}
                  error={errors.jbcc_supervisor_id}
                  render={render}
                />
              </div>
              <div className="w-full pl-1">
                <SearchDropdown
                  name="jbcc_admin_id"
                  label="Admin (JBCC) *"
                  keyName="fullName"
                  id="id"
                  options={userSetup.jbccAdmins}
                  setSelectedOption={setSelectedOption}
                  selectedOption={selectedOption}
                  setValue={setValue}
                  register={{
                    ...register("jbcc_admin_id", {
                      required: "Please select a Admin JBCC",
                    }),
                  }}
                  error={errors.jbcc_admin_id}
                  render={render}
                />
              </div>
            </div>
            <SearchDropdown
              name="jn_admin_id"
              label="Admin (Jal Nirikshan) *"
              keyName="fullName"
              id="id"
              options={userSetup.jnAdmins}
              setSelectedOption={setSelectedOption}
              selectedOption={selectedOption}
              setValue={setValue}
              register={{
                ...register("jn_admin_id", {
                  required: "Please select a Admin Jal Nirikshan",
                }),
              }}
              error={errors.jn_admin_id}
              render={render}
            />
          </div>
        </div> */}
        </div>
      </div>
    </div>
  );
};
export default CompanyInfo;
