import React from "react";

const DetailRow = ({ title, value, textJustify, children }) => {
  return (
    <div className="detail-row-wrap">
      <p className="detail-row-title">{title}</p>
      <p className={`detail-row-val ${textJustify}`}>
        {value} {children}
      </p>
    </div>
  );
};
export default DetailRow;