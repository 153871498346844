import DialogBox from "../../components/UI/DialogBox";
import { handleErrMessage } from "../../functions/handleErrMessage";
import ChangePassword from "../../components/auth/ChangePassword";
import { CHANGE_PASSWORD } from "../../api/allApi_List";
import usePostData from "../../CustomHooks/useAxios/usePostData";
import MainPageLayout from "../../components/MainPageLayout";

const ChangePasswordPage = () => {
  const {
    executePost,
    loading,
    isSucceed,
    formError,
    formErrMsg,
    setFormError,
  } = usePostData();

  const sendData = async (dataObj, reset) => {
    const updateStateOnResponse = () => {
      // reset form value
      reset();
    };
    //API calling
    executePost(dataObj, CHANGE_PASSWORD, updateStateOnResponse, true);
  };

  return (
    <MainPageLayout headerConfig={{ title: "Change Password" }}>
      <ChangePassword
        sendData={sendData}
        isSucceed={isSucceed}
        loading={loading}
      />
      {formError && (
        <DialogBox
          title="Error"
          btnText="Ok"
          message={handleErrMessage(formErrMsg)}
          closeAction={() => setFormError(false)}
        />
      )}
    </MainPageLayout>
  );
};

export default ChangePasswordPage;
