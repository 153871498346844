import { createPortal } from "react-dom";
import useClickOutside from "../../CustomHooks/useClickOutside";

const Modal = ({ children, closeDialog }) => {
  const { dropdownRef } = useClickOutside(closeDialog);
  // Create a reference to the DOM node where the modal will be rendered
  const modalRoot = document.getElementById("modal-root");

  return createPortal(
    <div className="overlay before:z-9999">
      <div ref={dropdownRef}>{children}</div>
    </div>,
    modalRoot
  );
};

export default Modal;
