import React, { forwardRef } from "react";
import { createPortal } from "react-dom";
import useClickOutside from "../../CustomHooks/useClickOutside";

const ViewModal = forwardRef(function ViewModal(
  { children, closeDialog },
  ref
) {
  const { dropdownRef } = useClickOutside(closeDialog);
  const modalRoot = document.getElementById("modal-root");
  return createPortal(
    <dialog ref={ref} className="overlay">
      <div ref={dropdownRef}>{children}</div>
    </dialog>,
    modalRoot
  );
});

export default ViewModal;
