import closeIcon from "../../../../assets/icons/close-blue.svg";
import DetailRow from "../DetailRow";
import HeadingDialog from "../HeadingDialog";
import { formatDate } from "../../../../functions/dateTime/formatDate";
import Modal from "../../../portal/Modal";
import GradientButton from "../../buttonUI/GradientButton";
import ExpensesList from "./ExpensesList";

export default function SheetView({
  closeDialog,
  viewDetails,
  successView,
  message,
  modalNotClose,
  messageIcon,
  isLoaded,
}) {
  return (
    <Modal closeDialog={!successView ? closeDialog : modalNotClose}>
      <div className="view-sheet-container absolute-center-align">
        {!successView && (
          <HeadingDialog heading="Uploaded Expense Sheet Details" />
        )}
        <div className="px-2 overflow-auto">
          {successView && (
            <>
              <img
                src={messageIcon}
                alt="messageIcon"
                className="mx-auto mb-1 w-9"
              />

              <p className="view-success-msg-d">{message}</p>
            </>
          )}
          {isLoaded ? (
            <>
              {!successView && (
                <div className="view-dialog-content-wrap">
                  <DetailRow
                    title="Uploaded By"
                    value={viewDetails.createdBy.fullName}
                  />
                  <DetailRow
                    title="Uploaded At"
                    value={formatDate(viewDetails.createdAt)}
                  />
                </div>
              )}
              <ExpensesList expenseList={viewDetails.expenses} />
            </>
          ) : (
            <p className="text-center">Details Loading...</p>
          )}
        </div>
        {successView && (
          <div className="mt-5 pt-3 text-center">
            <GradientButton
              btnText="Ok"
              width="w-56"
              rounded="rounded-full"
              onClick={closeDialog}
            />
          </div>
        )}
        {!successView && (
          <img
            src={closeIcon}
            alt="close"
            className="view-dialog-close"
            onClick={closeDialog}
          />
        )}
      </div>
    </Modal>
  );
}
