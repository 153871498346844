import React, { useState } from "react";
import { useSelector } from "react-redux";

import TableHead from "../../UI/TableUI/TableHead";
import { visitsHeadList } from "../../../utils/TableHeadList";
import { formatDate } from "../../../functions/dateTime/formatDate";
import NoDataDisplay from "../../UI/NoDataDisplay";
import sortDataByDate from "../../../functions/sorting/sortDataByDate";
import { getVisitStatus } from "../../../functions/getVisitStatus";
import JustifiedHeading from "../../UI/headingsUI/JustifiedHeading";
import FilterSearch from "../../FilterSearch";
import { visitSearch } from "../../../utils/tableSearchList";
import { filterCategoryVisit } from "../../../utils/filterCategoryList";
import { setTableHeight } from "../../../functions/setTableHeight";
import UseDialogBox from "../../../CustomHooks/UseDialogBox";
import ImageSlider from "../../UI/viewDialogs/ImageSlider";
import VisitViewDialog from "../../UI/viewDialogs/complaint/VisitViewDialog";
import { checkValue } from "../../../functions/checkValue";

const Visits = ({ visitsData, updateStateOnAddRemark }) => {
  const [visitViewDetails, setVisitViewDetails] = useState();
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const { showDialogBox, setShowDialogBox } = UseDialogBox();
  const [imageSliderPics, setImageSliderPics] = useState([]);
  const [imageSliderCurrentIndex, setImageSliderCurrentIndex] = useState();
  const isSorted = useSelector((state) => state.data.isSorted);

  let dataList = isSorted
    ? visitsData
    : sortDataByDate(visitsData, "visitedAt");
  const isDataAvailable = visitsData.length > 0;

  const [copyData] = useState(visitsData.slice());
  const isCopyData = copyData.length > 0;

  const openViewDialog = (visitData) => {
    setViewDialogOpen(true);
    setVisitViewDetails(visitData);
  };
  const closeViewDialog = () => {
    setViewDialogOpen(false);
  };

  const openImageSlider = (Pics) => {
    closeViewDialog();
    setShowDialogBox(true);
    setImageSliderPics(Pics);
  };

  const closeImageSlider = () => {
    setViewDialogOpen(true);
    setShowDialogBox(false);
  };

  return (
    <>
      <JustifiedHeading title="Visits" />
      {isCopyData ? (
        <FilterSearch
          categories={filterCategoryVisit}
          searchCategories={visitSearch}
          getStatus={getVisitStatus}
          isCopyData={isCopyData}
        />
      ) : (
        ""
      )}
      <div className={`table-container ${setTableHeight(isDataAvailable)}`}>
        <table className={`table ${!isDataAvailable ? "relative" : ""}`}>
          <tbody>
            <TableHead headList={visitsHeadList} />
            {isDataAvailable ? (
              <>
                {dataList.map((data, index) => {
                  return (
                    <tr className="table-row" key={index}>
                      <td className="p-3">{data.station.id}</td>
                      <td className="p-3">{data.station.name}</td>
                      <td className="p-3">
                        {checkValue(data.operatorPhoneNumber)}
                      </td>
                      <td className="p-3">{formatDate(data.visitedAt)}</td>
                      <td className="p-3">{data.fieldTechnician.fullName}</td>
                      <td className="p-3">
                        {data.category.name}{" "}
                        {data.category.name === "Complaint" &&
                          ` (${data.category.id})`}
                      </td>
                      <td className="p-3 C">{data.workDone}</td>
                      <td
                        className={`status-data ${
                          getVisitStatus(data.status).textColor
                        }`}
                      >
                        {getVisitStatus(data.status).status}
                      </td>
                      <td className="p-3">
                        <button
                          className="tableViewBtn"
                          onClick={() => openViewDialog(dataList[index])}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <NoDataDisplay />
            )}
          </tbody>
        </table>
      </div>
      {viewDialogOpen && (
        <VisitViewDialog
          closeDialog={closeViewDialog}
          viewDetails={visitViewDetails}
          openImageSlider={openImageSlider}
          setImageSliderCurrentIndex={setImageSliderCurrentIndex}
          updateStateOnAddRemark={updateStateOnAddRemark}
          setVisitViewDetails={setVisitViewDetails}
        />
      )}
      {showDialogBox && (
        <ImageSlider
          title="Complaint Photos"
          images={imageSliderPics}
          imageSliderCurrentIndex={imageSliderCurrentIndex}
          closeDialogBox={closeImageSlider}
        />
      )}
    </>
  );
};
export default Visits;