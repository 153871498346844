import { invertExpenseAmount } from "./invertExpenseAmount";

export function changeExpenseAmountData(expenseData, expenseType) {
  const updateExpensesArray = (expensesArray) => {
    return expensesArray.map((expense) => ({
      ...expense,
      amount: invertExpenseAmount(
        expense.amount,
        expense.category.id,
        expense.user.id,
        expense.receiver?.id
      ),
    }));
  };

  if (expenseType === "allExpenses") {
    // Update the entire data object
    const updatedExpenses = {
      ...expenseData,
      expenses: expenseData.expenses?.length
        ? updateExpensesArray(expenseData.expenses)
        : [],
      loanExpenses: expenseData.loanExpenses?.length
        ? updateExpensesArray(expenseData.loanExpenses)
        : [],
      pendingBalance: expenseData.pendingExpenses?.length
        ? updateExpensesArray(expenseData.pendingExpenses)
        : [],
    };

    return updatedExpenses;
  } else {
    return updateExpensesArray(expenseData);
  }
}
