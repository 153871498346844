import React, { Fragment } from "react";

export default function CustomInput({
  name,
  labelText,
  type,
  placeholder,
  register,
  handleClick,
  inputIcon,
  imgAlt,
  showPasswordStatus,
  error,
}) {
  return (
    <Fragment>
      <label htmlFor={name} className="textInputLabel-c">
        {labelText}
        <span> *</span>
      </label>
      <p className="relative">
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          className={`textInput-c ${
            error ? "border-red-500" : "border-light-gray"
          }`}
          {...register}
        />
        {handleClick ? (
          <img
            src={inputIcon}
            className={`absolute right-3 top-4 cursor-pointer ${
              showPasswordStatus ? "w-4 h-4" : "w-5 h-5"
            }`}
            alt={imgAlt}
            onClick={handleClick}
          />
        ) : (
          <img
            src={inputIcon}
            className="absolute right-3 top-5"
            alt={imgAlt}
          />
        )}
      </p>
      <p className={`input-err ${error ? "visible" : "invisible"}`}>
        {error?.message}
      </p>
    </Fragment>
  );
}
