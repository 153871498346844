import closeIcon from "../../../../assets/icons/close-blue.svg";
import DetailRow from "../DetailRow";
import HeadingDialog from "../HeadingDialog";
import { timeStampToDateAndTime } from "../../../../functions/dateTime/timeStampToDateAndTime";
import { getExpenseStatus } from "../../../../functions/getExpenseStatus";
import { checkValue } from "../../../../functions/checkValue";
import Modal from "../../../portal/Modal";
import TabsView from "./TabsView";
import GradientButton from "../../buttonUI/GradientButton";
import GrayButton from "../../buttonUI/GrayButton";
import Remarks from "../../../remark/Remarks";
import { EXPENSE_REMARK } from "../../../../api/allApi_List";
import PictureList from "../PictureList";
import { formatDate } from "../../../../functions/dateTime/formatDate";

export default function ExpenseViewDialog({
  closeDialog,
  viewDetails,
  openImageSlider,
  setImageSliderCurrentIndex,
  successView,
  message,
  modalNotClose,
  accept,
  reject,
  messageIcon,
  updateStateOnAddRemark,
  setExpenseViewDetails,
}) {
  const backDate = viewDetails.backDated ? (
    <span className="expense-view-bd">BD</span>
  ) : (
    ""
  );
  return (
    <Modal closeDialog={!successView ? closeDialog : modalNotClose}>
      <div className="expense-vie-content absolute-center-align">
        {!successView && <HeadingDialog heading="Expense Details" />}
        <div
          className={`${
            viewDetails.pictureList ? "h-500" : "h-400"
          } px-2 overflow-auto`}
        >
          {successView && (
            <>
              <img
                src={messageIcon}
                alt="messageIcon"
                className="mx-auto mb-1 w-9"
              />

              <p className="view-success-msg-d ">{message}</p>
            </>
          )}
          <div className="view-dialog-content-wrap">
            <DetailRow title="User" value={viewDetails.user.fullName} />
            <DetailRow title="Expense ID" value={viewDetails.id} />
            <DetailRow title="Amount" value={`₹${viewDetails.amount}`} />
            <div className={`${getExpenseStatus(viewDetails.state).textColor}`}>
              <DetailRow
                title="Status"
                value={getExpenseStatus(viewDetails.state).status}
              />
            </div>
            <DetailRow
              title="Description"
              value={checkValue(viewDetails.description)}
              textJustify="text-justify"
              widthDesc="w-64"
            />
            {viewDetails.receiver && (
              <DetailRow
                title="Received By"
                value={viewDetails.receiver.fullName}
              />
            )}
            <DetailRow title="Date" value={formatDate(viewDetails.date)}>
              {backDate}
            </DetailRow>
            <DetailRow
              title="Created By"
              value={viewDetails.createdBy.fullName}
            />
            <DetailRow
              title="Created At"
              value={timeStampToDateAndTime(viewDetails.createdAt)}
            />
            <DetailRow
              title="Last Updated By"
              value={viewDetails.updatedBy.fullName}
            />
            <DetailRow
              title="Last Updated At"
              value={timeStampToDateAndTime(viewDetails.updatedAt)}
            />
            <DetailRow
              title="Supervisor"
              value={viewDetails.supervisor.fullName}
            />
            <DetailRow title="Admin" value={viewDetails.admin.fullName} />
            <DetailRow
              title="Super Admin"
              value={viewDetails.superAdmin.fullName}
            />
          </div>
          {viewDetails.pictureList && (
            <PictureList
              pictureList={viewDetails.pictureList}
              openImageSlider={openImageSlider}
              setImageSliderCurrentIndex={setImageSliderCurrentIndex}
            />
          )}
          <Remarks
            remarkList={viewDetails.remarks}
            dataId={viewDetails.id}
            apiUrl={EXPENSE_REMARK}
            updateStateOnAddRemark={updateStateOnAddRemark}
            setViewDetails={setExpenseViewDetails}
          />
          <TabsView expenseViewDetails={viewDetails} />
        </div>
        {accept && reject && (
          <div className="expense-action-btn-wrap flex-center-align">
            <div className="expense-action-btn">
              <GradientButton
                btnText="Accept"
                width="w-full"
                rounded="rounded-lg"
                onClick={accept}
              />
            </div>
            <div className="expense-action-btn">
              <GrayButton btnText="Reject" width="w-full" onClick={reject} />
            </div>
          </div>
        )}
        {successView && (
          <div className="mt-5 pt-3 text-center">
            <GradientButton
              btnText="Ok"
              width="w-56"
              rounded="rounded-full"
              onClick={closeDialog}
            />
          </div>
        )}
        {!successView && (
          <img
            src={closeIcon}
            alt="close"
            className="view-dialog-close"
            onClick={closeDialog}
          />
        )}
      </div>
    </Modal>
  );
}
