import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import CenteredHeading from "../UI/headingsUI/CenteredHeading";
import AddButton from "../UI/buttonUI/AddButton";
import GrayButton from "../UI/buttonUI/GrayButton";
import CustomInput from "../UI/PageInput/CustomInput";
import JurisDropdown from "../UI/PageInput/JurisDropdown";
import { jurisOptions } from "../../utils/jurisdictionDetail";
import { jurisArr } from "../../utils/jurisdictionDetail";
import DialogBox from "../UI/DialogBox";
import successIcon from "../../assets/icons/successIcon.svg";
import { getHierName } from "../../functions/getHierName";

const AddJurisdiction = ({
  hireDetail,
  setHireDetail,
  hierSetup,
  sendData,
  setIsSucceed,
  isSucceed,
  loading,
  responseData,
}) => {
  const [levelDropdown, setLevelDropdown] = useState([]);
  const [isJurisSelected, setIsJurisSelected] = useState(false);
  const [isFormClear, setIsFormClear] = useState(false);
  const [jurisName, setJurisName] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    watch((value) => {
      const excludedKeys = [];
      // Conditional exclusion for the accessLevel
      if (value.accessLevel === 1) {
        excludedKeys.push("parentAccessId");
      }
      const updatedHierDetail = Object.fromEntries(
        Object.entries(value).filter(([key]) => !excludedKeys.includes(key))
      );

      setHireDetail({ ...hireDetail, ...updatedHierDetail });
    });
  }, [watch]);

  // Get a dropdown list and name for a specific level
  useEffect(() => {
    if (hireDetail.accessLevel) {
      const updatedLevelDropdown =
        hierSetup[jurisArr[hireDetail.accessLevel - 2]] || [];
      const updatedLevelName = jurisArr[hireDetail.accessLevel - 1] || [];
      setLevelDropdown(updatedLevelDropdown);
      setJurisName(updatedLevelName);
      // check true if any option is selected from the Hierarchy list
      setIsJurisSelected(true);
      // Reset Default value of name
      setValue("name", "");
    }
  }, [hireDetail.accessLevel, hierSetup, setValue]);

  const onSubmit = () => {
    sendData(reset, setLevelDropdown, setIsJurisSelected);
  };

  // handle clear form
  const handleClearForm = () => {
    setLevelDropdown([]);
    setHireDetail({});
    setIsJurisSelected(false);
    setIsFormClear(!isFormClear);
    // reset form value
    reset();
  };

  return (
    <>
      <div className="form-container">
        <CenteredHeading title="Add Jurisdiction" />
        <form>
          <div className="flex flex-wrap">
            <div className="halfCol mx-auto">
              <JurisDropdown
                name="accessLevel"
                label="Select Jurisdiction *"
                keyName="name"
                id="id"
                options={jurisOptions}
                value={hireDetail.accessLevel}
                setValue={setValue}
                render={isSucceed}
                register={{
                  ...register("accessLevel", {
                    required: "Please select Jurisdiction",
                  }),
                }}
                error={errors.accessLevel}
                reset={reset}
                isFormClear={isFormClear}
                unSort={true}
              />
              {levelDropdown.length > 0 && hireDetail.accessLevel !== 1 && (
                <JurisDropdown
                  name="parentAccessId"
                  label={`Select ${getHierName(
                    jurisArr[hireDetail.accessLevel - 2]
                  )} *`}
                  keyName="name"
                  id="id"
                  options={levelDropdown}
                  value={hireDetail.parentAccessId}
                  setValue={setValue}
                  render={isSucceed}
                  register={{
                    ...register("parentAccessId", {
                      required: `Select a ${
                        jurisArr[hireDetail.accessLevel - 2]
                      }`,
                    }),
                  }}
                  error={errors.parentAccessId}
                  search={true}
                />
              )}
              {isJurisSelected && (
                <CustomInput
                  type="text"
                  name="name"
                  labelText={`Add ${getHierName(jurisName)} Name *`}
                  placeholder="Enter"
                  register={{
                    ...register("name", {
                      required: "Please Enter a name",
                    }),
                  }}
                  error={errors.name}
                />
              )}
            </div>
          </div>
        </form>
        <div className="addBtn---wrap">
          <p className="addWrap">
            <AddButton
              btnText={!loading ? "Add Jurisdiction" : "Adding..."}
              onClick={!loading ? handleSubmit(onSubmit) : undefined}
              loading={loading}
              height="h-12"
            />
          </p>
          <p className="grayWrap">
            <GrayButton
              btnText="Clear"
              width="w-40"
              onClick={handleClearForm}
            />
          </p>
        </div>
        {isSucceed && (
          <DialogBox
            message={` ${responseData.name} Successfully Added!`}
            messageIcon={successIcon}
            btnText="Ok"
            closeAction={() => setIsSucceed(false)}
          />
        )}
      </div>
    </>
  );
};

export default AddJurisdiction;
