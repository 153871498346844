import React from "react";
import ProfileDetails from "../../UI/UserUI/ProfileDetails";
import UseDialogBox from "../../../CustomHooks/UseDialogBox";
import RoundedButton from "../../UI/buttonUI/RoundedButton";
import ImageSlider from "../../UI/viewDialogs/ImageSlider";

const PersonalInfoTab = ({ userData }) => {
  const { showDialogBox, setShowDialogBox } = UseDialogBox();
  return (
    <div className="flex">
      <div className="w-3/6">
        <ProfileDetails title="Full Name" value={userData.fullName} />
        <ProfileDetails title="Email" value={userData.email} />
        <ProfileDetails title="Date of Birth" value={userData.dob} />
        <ProfileDetails title="Gender" value={userData.gender.name} />

        {userData.idProofUrlList && (
          <div className="w-32 py-3">
            <RoundedButton
              text="View ID Proof"
              font="font-figtree"
              onClick={() => setShowDialogBox(true)}
            />
          </div>
        )}
      </div>
      <div className="w-3/6">
        <ProfileDetails
          title="Bank Name"
          value={userData.bankName?.name ?? "N/A"}
        />
        <ProfileDetails title="IFSC Code" value={userData.ifsc ?? "N/A"} />
        <ProfileDetails
          title="Account Number"
          value={userData.accountNumber ?? "N/A"}
        />
        <ProfileDetails title="Mobile Number" value={userData.mobileNumber} />
        <ProfileDetails
          title="Bike registration no."
          value={userData.bikeRegNumber ?? "N/A"}
        />
      </div>
      {showDialogBox && (
        <ImageSlider
          title="Id Proof Photo"
          images={userData.idProofUrlList}
          imageSliderCurrentIndex={0}
          closeDialogBox={() => setShowDialogBox(false)}
        />
      )}
    </div>
  );
};

export default PersonalInfoTab;
