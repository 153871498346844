export const mapRoles = {
  jbcc_supervisor: "JBCC Supervisor",
  jbcc_factory: "JBCC Factory",
  jn_field: "JN Field",
  jbcc_admin: "JBCC Admin",
  jbcc_field: "JBCC Field",
  jbcc_factory_supervisor: "JBCC Factory Supervisor",
  jn_admin: "JN Admin",
  jn_supervisor: "JN Supervisor",
  jbcc_office: "JBCC Office",
  jbcc_accounts: "JBCC Accounts",
  jbcc_driver: "JBCC Driver",
  jbcc_super_admin: "JBCC Super Admin",
  jn_super_admin: "JN Super Admin",
};
