import React from "react";

const LeftIconButton = ({
  text,
  textColor,
  bgColor,
  icon,
  iconStyle,
  margin,
  hover,
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      className={`${bgColor} ${textColor} ${margin} relative items-center px-3 h-full rounded transition-all ease-in-out duration-300 font-figtree ${hover} border-1 2xs:max-md:text-xs 2xs:max-md:px-1 2xs:max-md:h-auto 2xs:max-md:py-1`}
    >
      <img
        src={icon}
        alt="btnIcon"
        className={`pr-1 ${iconStyle} absolute left-1 inset-y-2/4 translate-y-50`}
      />
      <span className="pl-3">{text}</span>
    </button>
  );
};

export default LeftIconButton;
