import { useLocation, useNavigate } from "react-router-dom";

const UseUserLocationState = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const stateData = location.state?.state || {};
  const userExistingData = {
    fullName: stateData.fullName || "",
    salary: stateData.salary || "",
    genderId: stateData.gender?.id || "",
    dob: stateData.dob || "",
    mobile: stateData.mobileNumber || "",
    bikeRegNumber: stateData.bikeRegNumber || "",
    bankNameId: stateData.bankName?.id || "",
    accountNumber: stateData.accountNumber || "",
    ifsc: stateData.ifsc || "",
    branchName: stateData.bankBranch || "",
  };

  const existingDropdownData = {
    genderId: stateData.gender?.name || "",
    bankNameId: stateData.bankName?.name || "",
  };

  const emptyExistingData = {
    fullName: "",
    salary: "",
    genderId: "",
    dob: "",
    mobile: "",
    bikeRegNumber: "",
    bankNameId: "",
    accountNumber: "",
    ifsc: "",
    branchName: "",
  };

  const replaceLocationState = (newState) => {
    navigate(location.pathname, {
      replace: true,
      state: { ...location.state, state: newState },
    });
  };

  return {
    userExistingData,
    existingDropdownData,
    emptyExistingData,
    replaceLocationState,
  };
};
export default UseUserLocationState;
