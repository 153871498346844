import DetailRow from "../DetailRow";
import Modal from "../../../portal/Modal";
import HeadingDialog from "../HeadingDialog";
import GradientButton from "../../buttonUI/GradientButton";
import closeIcon from "../../../../assets/icons/close-blue.svg";
import { timeStampToDateAndTime } from "../../../../functions/dateTime/timeStampToDateAndTime";
import GetCheckCross from "../GetCheckCross";
import { checkValue } from "../../../../functions/checkValue";
import { MACHINE_REMARK } from "../../../../api/allApi_List";
import Remarks from "../../../remark/Remarks";

export default function MachineViewDialog({
  closeDialog,
  viewDetails,
  successView,
  message,
  modalNotClose,
  messageIcon,
  updateStateOnAddRemark,
  setMachineViewDetails,
}) {
  return (
    <Modal closeDialog={!successView ? closeDialog : modalNotClose}>
      <div className="view-dialog-content absolute-center-align">
        {!successView && <HeadingDialog heading="Machine Details" />}
        {successView && (
          <>
            <img
              src={messageIcon}
              alt="messageIcon"
              className="mx-auto mb-1 w-9"
            />
            <p className="view-success-msg-d">{message}</p>
          </>
        )}
        <div className="h-500 overflow-auto">
          <h4 className="machineInfo-view--d">Machine Info</h4>
          <div className="view-dialog-content-wrap">
            <DetailRow title="Machine ID" value={viewDetails.id} />
            <DetailRow title="Category" value={viewDetails.category.name} />
            <DetailRow
              title="Assigned Site Name"
              value={checkValue(viewDetails.station?.name)}
            />
            <DetailRow
              title="Assigned Site ID"
              value={checkValue(viewDetails.station?.id)}
            />
            <DetailRow
              title="Assigned"
              value={<GetCheckCross checkVal={viewDetails.isAssigned} />}
            />
            <DetailRow
              title="JN Field Technician"
              value={checkValue(
                viewDetails.station?.jnFieldTechnician?.fullName
              )}
            />
            <DetailRow
              title="JN Supervisor"
              value={checkValue(
                viewDetails.station?.jnFieldTechnician?.jnSupervisor?.fullName
              )}
            />
            <DetailRow
              title="Created By"
              value={viewDetails.createdBy.fullName}
            />
            <DetailRow
              title="Created At"
              value={timeStampToDateAndTime(viewDetails.createdAt)}
            />
            <DetailRow
              title="Updated By"
              value={viewDetails.updatedBy.fullName}
            />
            <DetailRow
              title="Updated At"
              value={timeStampToDateAndTime(viewDetails.updatedAt)}
            />
          </div>
          <h4 className="machineState-view--d">Machine State</h4>
          <div className="p-4 rounded-md 3xl border-1 border-lg5 shadow-md">
            <DetailRow
              title="Sampling Time"
              value={checkValue(viewDetails.state?.controlFlags)}
            />
            <DetailRow
              title="Last Day Flags"
              value={checkValue(viewDetails.state?.statusFlags)}
            />
            <DetailRow
              title="Sampling Time"
              value={checkValue(viewDetails.state?.samplingTime)}
            />
            <DetailRow
              title="Last Day Flags"
              value={checkValue(viewDetails.state?.lastDayFlags)}
            />
            <DetailRow
              title="Analyzer Status"
              value={checkValue(viewDetails.state?.analyzerStatus)}
            />
            <DetailRow
              title="Shutoff Status"
              value={checkValue(viewDetails.state?.shutoffStatus)}
            />
            <DetailRow
              title="Error Count"
              value={checkValue(viewDetails.state?.errorCount)}
            />
            <DetailRow
              title="Created At"
              value={timeStampToDateAndTime(viewDetails.state?.createdAt)}
            />
            <DetailRow
              title="Updated At"
              value={timeStampToDateAndTime(viewDetails.state?.updatedAt)}
            />
          </div>
          <Remarks
            remarkList={viewDetails.remarks}
            dataId={viewDetails.id}
            apiUrl={MACHINE_REMARK}
            updateStateOnAddRemark={updateStateOnAddRemark}
            setViewDetails={setMachineViewDetails}
            isRemarkDelete={true}
          />
          {successView && (
            <div className="mt-5 pt-3 text-center">
              <GradientButton
                btnText="Ok"
                width="w-56"
                rounded="rounded-full"
                onClick={closeDialog}
              />
            </div>
          )}
          {!successView && (
            <img
              src={closeIcon}
              alt="close"
              className="absolute top-5 right-4 cursor-pointer w-4"
              onClick={closeDialog}
            />
          )}
        </div>
      </div>
    </Modal>
  );
}
