export const prepareFormData = (formValues, files) => {
  const formData = new FormData();
  Object.entries(formValues).forEach(([key, value]) => {
    // Skip profile_pic and id_proof
    if (key === "pictures") {
      return;
    }
    // Handle array values
    if (value !== "" && value !== null && value !== undefined) {
      // Append only non-empty, non-null, and defined values
      formData.append(key, value);
    }
  });
  if (files && Object.keys(files).length > 0) {
    Object.entries(files).forEach(([key, val]) => {
      // Check if val is an array and not empty
      if (Array.isArray(val) && val.length !== 0) {
        val.forEach((file) => {
          formData.append(key, file);
        });
      }
    });
  }
  return formData;
};
