export const  handleClickOutside = (handleArr) => {
    const handleClickOutside = (event) => {
      if (handleArr[0] && !event.target.closest(".modal-content")) {
        handleArr[1](false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }
  