import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signIn } from "supertokens-auth-react/recipe/emailpassword";
import { useSessionContext } from "supertokens-auth-react/recipe/session";

import LoginForm from "../../components/auth/LoginForm";
import LoginHeader from "../../components/auth/LoginHeader";

const LoginPage = () => {
  // Variables
  let session = useSessionContext();
  let sessionLoading = session.loading;

  const navigate = useNavigate();
  const [isRemember, setIsRemember] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getLogin = async (password, email) => {
    if (isRemember) {
      localStorage.setItem("email", email.trim());
      localStorage.setItem("password", password.trim());
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
    setLoading(true);
    await signIn({
      formFields: [
        {
          id: "email",
          value: email,
        },

        {
          id: "password",
          value: password,
        },
      ],
    })
      .then((res) => {
        console.log(res, "login data");
        if (res.status === "OK") {
          navigate("/");
          setIsInvalid(false);
        } else {
          setIsInvalid(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error, "ERROR");
        setLoading(false);
        setError(true);
      });
  };
  useEffect(() => {
    if (!sessionLoading && session.doesSessionExist) {
      navigate("/home");
    } else {
      localStorage.removeItem("uuid");
      localStorage.removeItem("userId");
      localStorage.removeItem("roles");
      localStorage.removeItem("userName");
      localStorage.removeItem("profilePic");
    }
  }, [sessionLoading, session.doesSessionExist, navigate]);

  return (
    <div className="sm:flex sm:flex-wrap h-full">
      <LoginHeader />
      <LoginForm
        getLogin={getLogin}
        isInvalid={isInvalid}
        loading={loading}
        isRemember={isRemember}
        setIsRemember={setIsRemember}
        error={error}
      />
    </div>
  );
};

export default LoginPage;
