import React, { useState } from "react";
import blueArrow from "../../../../assets/icons/blueArrow.svg";
import { formatDate } from "../../../../functions/dateTime/formatDate";
import { getTimeString } from "../../../../functions/dateTime/getTimeString";
import BalanceTable from "./BalanceTable";
import CheckedInfoTable from "./CheckedInfoTable";

const TabsView = ({ expenseViewDetails }) => {
  const [active, setActive] = useState({
    section1: false,
    section2: false,
  });
  const checkedInfo =
    expenseViewDetails.receiverCheckedAt ||
    expenseViewDetails.supervisorCheckedAt ||
    expenseViewDetails.adminCheckedAt ||
    expenseViewDetails.superAdminCheckedAt;

  const handleAccordion = (content) => {
    if (content === "section1") {
      setActive({
        section2: active.section2,
        section1: !active.section1,
      });
    } else {
      setActive({
        section1: active.section1,
        section2: !active.section2,
      });
    }
  };

  return (
    <>
      {checkedInfo && (
        <>
          <div className="expense-tabs-wrap">
            <button
              className="expense-tabs-btn"
              onClick={() => handleAccordion("section1")}
            >
              Checked At Info
              <img
                src={blueArrow}
                alt="arrow"
                className={`expense-tab-arr ${
                  active.section1 ? "rotate-180 top-3" : "top-4"
                }`}
              />
            </button>{" "}
            <br></br>
            {active.section1 && (
              <div className="px-4 pb-4">
                <table className="expense-tabs-table">
                  <CheckedInfoTable
                    receiverChecked={expenseViewDetails.receiverCheckedAt}
                    supervisorChecked={expenseViewDetails.supervisorCheckedAt}
                    adminChecked={expenseViewDetails.adminCheckedAt}
                    superAdminChecked={expenseViewDetails.superAdminCheckedAt}
                  />
                </table>
              </div>
            )}
          </div>
        </>
      )}
      {expenseViewDetails.balances &&
        expenseViewDetails.balances.length > 1 && (
          <>
            <div className="expense-tabs-wrap">
              <button
                className="expense-tabs-btn"
                onClick={() => handleAccordion("section2")}
              >
                Balance Info
                <img
                  src={blueArrow}
                  alt="arrow"
                  className={`expense-tab-arr ${
                    active.section2 ? "rotate-180 top-3" : "top-4"
                  }`}
                />
              </button>
              {active.section2 && (
                <div className="px-4 pb-4">
                  <table className="expense-tabs-table">
                    <tr className="bg-lg5">
                      <td className="balance-updatedAt">Balance Updated At</td>
                      <td className="border border-lg6 px-5 font-figtree">
                        {formatDate(expenseViewDetails.balancesUpdatedAt)}
                        {getTimeString(expenseViewDetails.balancesUpdatedAt)}
                      </td>
                    </tr>

                    <BalanceTable
                      balances={expenseViewDetails.balances}
                      userName={expenseViewDetails.user.fullName}
                      receiverName={expenseViewDetails.receiver?.fullName}
                      expenseCategory={expenseViewDetails.category.id}
                      expenseUserId={expenseViewDetails.user.id}
                      expenseReceiverId={expenseViewDetails.receiver?.id}
                      superVisorId={expenseViewDetails.supervisor.id}
                      adminId={expenseViewDetails.admin.id}
                    />
                  </table>
                </div>
              )}
            </div>
          </>
        )}
    </>
  );
};

export default TabsView;
