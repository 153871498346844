import closeIcon from "../../../../assets/icons/close-blue.svg";
import DetailRow from "../DetailRow";
import HeadingDialog from "../HeadingDialog";
import { formatDate } from "../../../../functions/dateTime/formatDate";
import GradientButton from "../../buttonUI/GradientButton";
import accept from "../../../../assets/icons/accept.svg";
import reject from "../../../../assets/icons/reject.svg";
import Modal from "../../../portal/Modal";
import { checkValue } from "../../../../functions/checkValue";

export default function UserViewDetailsDialog({
  closeDialog,
  viewDetails,
  successView,
  message,
  modalNotClose,
  messageIcon,
}) {
  return (
    <Modal closeDialog={!successView ? closeDialog : modalNotClose}>
      <div className="user-view-content absolute-center-align">
        {!successView && <HeadingDialog heading="User Details" />}
        <div className={`px-2 overflow-auto 2xs:max-2xl:h-500`}>
          {successView && (
            <>
              <img
                src={messageIcon}
                alt="messageIcon"
                className="mx-auto mb-1 w-9"
              />

              <p className="view-success-msg-d">{message}</p>
            </>
          )}
          <div className="view-dialog-content-wrap">
            <DetailRow title="User Name" value={viewDetails.fullName} />
            <DetailRow title="User Id" value={viewDetails.id} />
            <DetailRow title="Email" value={viewDetails.email} />
            <DetailRow title="Mobile Number" value={viewDetails.mobileNumber} />
            <DetailRow title="Gender" value={viewDetails.gender.name} />
            <DetailRow
              title="Date of birth"
              value={
                viewDetails.dob ? formatDate(viewDetails.dob) : "Not Available"
              }
            />
            <DetailRow
              title="Is Active"
              value={
                viewDetails.isActive ? (
                  <img src={accept} alt="accept" className="w-5 mt-1" />
                ) : (
                  <img src={reject} alt="reject" className="w-4 mt-1" />
                )
              }
            />
            <DetailRow title="Salary" value={`${viewDetails.salary} /-`} />
            <DetailRow
              title="Loan Balance"
              value={`${viewDetails.loanBalance} /-`}
            />
            <DetailRow
              title="Cash Balance "
              value={`${viewDetails.cashBalance} /-`}
            />
            <DetailRow
              title="Account Balance"
              value={`${viewDetails.accountBalance} /-`}
            />
            <DetailRow
              title="Total Balance"
              value={`${viewDetails.totalBalance} /-`}
            />
            <DetailRow
              title="pending Balance"
              value={`${viewDetails.pendingBalance} /-`}
            />
            <DetailRow
              title="Created At"
              value={formatDate(viewDetails.createdAt)}
            />
            <DetailRow
              title="Updated At"
              value={formatDate(viewDetails.updatedAt)}
            />
                <DetailRow
              title="Bike Registration No."
              value={checkValue(viewDetails.bikeRegNumber)}
            />
            <DetailRow
              title="Account No."
              value={checkValue(viewDetails.accountNumber)}
            />
            <DetailRow title="IFSC Code" value={checkValue(viewDetails.ifsc)} />
            <DetailRow
              title="Branch Name"
              value={checkValue(viewDetails.bankName?.name)}
            />
            <DetailRow
              title="Branch Branch"
              value={checkValue(viewDetails.bankBranch)}
            />
            <DetailRow
              title="JBCC Supervisor"
              value={viewDetails.jbccSupervisor.fullName}
            />
            <DetailRow
              title="JN Supervisor"
              value={viewDetails.jnSupervisor.fullName}
            />
            <DetailRow
              title="JBCC Admin"
              value={viewDetails.jbccAdmin.fullName}
            />
            <DetailRow title="JN Admin" value={viewDetails.jnAdmin.fullName} />
          </div>
        </div>
        {successView && (
          <div className="mt-5 pt-3 text-center">
            <GradientButton
              btnText="Ok"
              width="w-56"
              rounded="rounded-full"
              onClick={closeDialog}
            />
          </div>
        )}
        {!successView && (
          <img
            src={closeIcon}
            alt="close"
            className="view-dialog-close"
            onClick={closeDialog}
          />
        )}
      </div>
    </Modal>
  );
}
