import axios from "axios";

export const uploadSheet = async (
  sendData,
  apiUrl,
  setLoading,
  setFormError,
  setFormErrMsg,
  updateStateOnResponse,
  setResponseData,
  setErrorSheetList,
  navigate
) => {
  let config = {
    method: "post",
    url: apiUrl,
    data: sendData,
  };
  setLoading(true);
  await axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      if (setResponseData) {
        setResponseData(response.data);
      }
      setLoading(false);
      if (response.data.sheetErrors.length !== 0) {
        setErrorSheetList(true);
      } else {
        updateStateOnResponse();
      }
    })
    .catch((error) => {
      console.log(error);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      } else {
        setFormError(true);
        setLoading(false);
        setFormErrMsg(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    });
};
