import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import successIcon from "../../../assets/icons/successIcon.svg";
import rejected from "../../../assets/icons/rejected.svg";
import { getComplaintStatus } from "../../../functions/getComplaintStatus";
import JustifiedHeading from "../../UI/headingsUI/JustifiedHeading";
import FilterSearch from "../../FilterSearch";
import { complaintSearch } from "../../../utils/tableSearchList";
import { filterCategoryComplaint } from "../../../utils/filterCategoryList";
import TableComplaintContent from "./TableComplaintContent";
import UseDialogBox from "../../../CustomHooks/UseDialogBox";
import ImageSlider from "../../UI/viewDialogs/ImageSlider";
import ComplaintViewDialog from "../../UI/viewDialogs/complaint/ComplaintViewDialog";
import Dialog from "../../UI/Dialog";

const ComplaintPending = ({
  handleConfirmDialogBox,
  viewDialogOpen,
  setViewDialogOpen,
  confirmDialogBox,
  setConfirmDialogBox,
  isActionSucceed,
  setIsActionSucceed,
  buttonStatus,
  handleCheck,
  checkLoading,
  updateStateOnAddRemark,
  isLoaded,
}) => {
  const [complaintViewDetails, setComplaintViewDetails] = useState();
  const [isViewOpen, setIsViewOpen] = useState(false);
  const { showDialogBox, setShowDialogBox } = UseDialogBox();
  const [imageSliderPics, setImageSliderPics] = useState([]);
  const [imageSliderCurrentIndex, setImageSliderCurrentIndex] = useState();
  const data = useSelector((state) => state.data.data);
  const [copyData] = useState(data.slice());
  const isCopyData = copyData.length > 0;

  const openViewDialog = (complaintsData) => {
    setIsViewOpen(true);
    setViewDialogOpen(true);
    setComplaintViewDetails(complaintsData);
  };
  const closeViewDialog = () => {
    setViewDialogOpen(false);
    setIsViewOpen(false);
  };

  const closeConfirmDialogBox = () => {
    setConfirmDialogBox(false);
    if (isViewOpen) {
      setViewDialogOpen(true);
    }
  };

  const openImageSlider = (Pics) => {
    closeViewDialog();
    setShowDialogBox(true);
    setImageSliderPics(Pics);
  };

  const closeImageSlider = () => {
    setViewDialogOpen(true);
    setShowDialogBox(false);
  };

  const closeSuccessDialog = () => {
    setIsActionSucceed(false);
  };

  useEffect(() => {
    // setCopyData(complaintsData.slice());
    const a = () => {
      setIsActionSucceed(false);
    };
    isActionSucceed && setTimeout(a, 2000);
  }, [isActionSucceed]);

  return (
    <>
      {/* page title container for mobile */}
      <JustifiedHeading title="Complaint Pending" />
      {/* data filter and data search container  */}
      {isCopyData ? (
        <FilterSearch
          categories={filterCategoryComplaint}
          searchCategories={complaintSearch}
          getStatus={getComplaintStatus}
          isActionSucceed={isActionSucceed}
          isLoaded={isLoaded}
          isCopyData={isCopyData}
        />
      ) : (
        ""
      )}
      {/* all complaint pending table list */}
      <TableComplaintContent openViewDialog={openViewDialog} />
      {/* show complaint details view dialog */}
      {viewDialogOpen && (
        <ComplaintViewDialog
          closeDialog={closeViewDialog}
          viewDetails={complaintViewDetails}
          openImageSlider={openImageSlider}
          setImageSliderCurrentIndex={setImageSliderCurrentIndex}
          accept={() => handleConfirmDialogBox(complaintViewDetails.id, true)}
          reject={() => handleConfirmDialogBox(complaintViewDetails.id, false)}
          updateStateOnAddRemark={updateStateOnAddRemark}
          setComplaintViewDetails={setComplaintViewDetails}
        />
      )}
      {/* show confirm dialog box to complaint checked */}
      {confirmDialogBox && (
        <Dialog
          message={
            buttonStatus
              ? "Are you sure you want to approve this complaint ?"
              : "Are you sure you want to Reject this complaint ?"
          }
          confirmBtnText={!checkLoading ? "Confirm" : "Loading..."}
          cancelBtnText="Cancel"
          closeDialog={closeConfirmDialogBox}
          checkAction={!checkLoading ? handleCheck : undefined}
        />
      )}
      {/* show success dialog box after complaint checked */}
      {isActionSucceed && (
        <Dialog
          message={
            buttonStatus
              ? "Complaint successfully approved!"
              : "Complaint successfully rejected!"
          }
          messageIcon={buttonStatus ? successIcon : rejected}
          closeDialog={closeSuccessDialog}
        />
      )}
      {/* show view dialog images in image slider box */}
      {showDialogBox && (
        <ImageSlider
          title="Complaint Photos"
          images={imageSliderPics}
          imageSliderCurrentIndex={imageSliderCurrentIndex}
          closeDialogBox={closeImageSlider}
        />
      )}
    </>
  );
};

export default ComplaintPending;
