import React, { useState, useRef } from "react";

import close from "../../../assets/icons/close-blue.svg";
import ImageDialog from "../../UI/viewDialogs/ImageDialog";

const SelectedImagesBox = ({
  errors,
  selectedImages,
  setFiles,
  setValue,
  name,
}) => {
  const [clickedImage, setClickedImage] = useState(null);
  const dialog = useRef();
  const isSelectedImage = selectedImages
    ? selectedImages.length !== 0
      ? true
      : false
    : false;

  const handleOpen = (picture) => {
    setClickedImage(picture);
    dialog.current.showModal();
  };

  const removeSelectedImage = (selectedInd) => {
    const removed = selectedImages.filter((item, ind) => {
      return ind !== selectedInd;
    });
    setFiles((prev) => ({ ...prev, [name]: removed }));
    setValue(name, "");
  };
  return (
    <>
      <div className={`selectedImgContainer ${errors ? "border-red-500" : ""}`}>
        <div className="selectedImgScroll">
          {isSelectedImage ? (
            <>
              <div className="w-full flex flex-wrap pt-8">
                {selectedImages.map((image, ind) => {
                  return (
                    <div
                      className="w-1/6 2xs:max-base:w-25% relative"
                      key={ind}
                    >
                      <img
                        src={URL.createObjectURL(image)}
                        alt="uploadImage"
                        className="selectedImg"
                        key={ind}
                        onClick={() => handleOpen(URL.createObjectURL(image))}
                      />
                      <img
                        src={close}
                        alt="close"
                        className="removeSelectedImg"
                        onClick={() => removeSelectedImage(ind)}
                      />
                    </div>
                  );
                })}
              </div>
              <p className="selectedImgLen">{`Total ${selectedImages.length} Images`}</p>
            </>
          ) : (
            <p className="absolute-center-align text-center">
              No Image selected
            </p>
          )}
        </div>
      </div>
      <ImageDialog
        image={clickedImage}
        closeDialog={() => dialog.current.close()}
        ref={dialog}
      />
    </>
  );
};
export default SelectedImagesBox;