import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { EXPENSE_PENDING_ADMIN } from "../../api/allApi_List";
import DialogBox from "../../components/UI/DialogBox";
import MainPending from "../../components/expense/pending/MainPending";
import spin from "../../assets/icons/spin.png";
import useCheckData from "../../CustomHooks/useAxios/useCheckData";
import { fetchData } from "../../redux/dataSlice";
import { handleErrMessage } from "../../functions/handleErrMessage";
import { CHECK_ADMIN_EXPENSE } from "../../api/allApi_List";
import MainPageLayout from "../../components/MainPageLayout";

const SupervisorPendingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [render, setRender] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const data = useSelector((state) => state.data.data);
  const error = useSelector((state) => state.data.error);
  const errMsg = useSelector((state) => state.data.errMessage);
  const dataLength = isLoaded && !errMsg ? data.length : 0;

  const {
    confirmDialogBox,
    setConfirmDialogBox,
    setApiEndPoint,
    setBodyData,
    isActionSucceed,
    setIsActionSucceed,
    buttonStatus,
    setButtonStatus,
    handleCheck,
    checkResponseData,
    checkedErr,
    checkedErrMsg,
    setCheckedErr,
    checkLoading,
  } = useCheckData(setRender, "patch", setIsLoaded);

  useEffect(() => {
    dispatch(
      fetchData(EXPENSE_PENDING_ADMIN, setIsLoaded, navigate, "pendingExpense")
    );
  }, [dispatch, render]);

  useEffect(() => {
    localStorage.removeItem("isCheckData");
    localStorage.removeItem("filterKeys");
    localStorage.removeItem("filterCheckBoxes");
    // remove data on unmount component
    return () => {
      localStorage.removeItem("isCheckData");
      localStorage.removeItem("filterKeys");
      localStorage.removeItem("filterCheckBoxes");
    };
  }, []);

  // handle confirm dialog box on single expense checked
  const handleConfirmDialogBox = (id, status) => {
    setViewDialogOpen(false);
    setConfirmDialogBox(true);
    setApiEndPoint(CHECK_ADMIN_EXPENSE);
    setBodyData((prev) => {
      return { ids: [id], statuses: [status] };
    });
    setButtonStatus(status);
  };

  // handle confirm dialog box on multiple expense checked
  const handleMultiCheckConfirmDialog = (ids, statuses) => {
    setConfirmDialogBox(true);
    setApiEndPoint(CHECK_ADMIN_EXPENSE);
    setBodyData((prev) => {
      return { ids: ids, statuses: statuses };
    });
    setButtonStatus("multiple");
  };
  const updateStateOnAddRemark = () => {
    setRender(!render);
  };

  return (
    <MainPageLayout
      headerConfig={{ title: "Admin Pending" }}
      numberOfData={dataLength}
    >
      {isLoaded && !error ? (
        <MainPending
          pageTitle="Admin Pending Expenses"
          viewDialogOpen={viewDialogOpen}
          setViewDialogOpen={setViewDialogOpen}
          handleConfirmDialogBox={handleConfirmDialogBox}
          confirmDialogBox={confirmDialogBox}
          setConfirmDialogBox={setConfirmDialogBox}
          isActionSucceed={isActionSucceed}
          setIsActionSucceed={setIsActionSucceed}
          buttonStatus={buttonStatus}
          handleCheck={handleCheck}
          checkResponseData={checkResponseData}
          handleMultiCheckConfirmDialog={handleMultiCheckConfirmDialog}
          checkLoading={checkLoading}
          updateStateOnAddRemark={updateStateOnAddRemark}
          isLoaded={isLoaded}
        />
      ) : error ? (
        <DialogBox
          title="Error"
          message={handleErrMessage(errMsg)}
          isErrorHandleButtons={true}
        />
      ) : (
        <DialogBox message="Loading your data" animateIcon={spin} />
      )}
      {checkedErr && (
        <DialogBox
          title="Error"
          btnText="Ok"
          message={handleErrMessage(checkedErrMsg)}
          closeAction={() => setCheckedErr(false)}
        />
      )}
    </MainPageLayout>
  );
};

export default SupervisorPendingPage;
