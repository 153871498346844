import { useState } from "react";

const useShowHidePassword = () => {
  const [showPassword, setShowPassword] = useState({
    password: false,
    newPassword: false,
    confirmNewPassword: false,
  });
  const handleClickShowPassword = (name) => {
    setShowPassword({ ...showPassword, [name]: !showPassword[name] });
  };
  return { showPassword, handleClickShowPassword };
};

export default useShowHidePassword;
