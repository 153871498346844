import React from "react";
import { useNavigate } from "react-router-dom";

import AddLoan from "../../components/expense/add/AddLoan";
import DialogBox from "../../components/UI/DialogBox";
import { Add_EXPENSE } from "../../api/allApi_List";
import { handleErrMessage } from "../../functions/handleErrMessage";
import usePostData from "../../CustomHooks/useAxios/usePostData";
import MainPageLayout from "../../components/MainPageLayout";
import { prepareFormData } from "../../functions/prepareFormData";

const AddLoanPage = () => {
  const navigate = useNavigate();
  const {
    executePost,
    loading,
    isSucceed,
    formError,
    formErrMsg,
    responseData,
    setIsSucceed,
    setFormError,
    setResponseData,
  } = usePostData();

  // execute post api
  const sendData = async (data, reset) => {
    // send data into form data
    const formData = prepareFormData(data)
    for (const key in data) {
      formData.append(key, data[key]);
    }
    const updateStateOnResponse = () => {
      // reset form value
      reset();
    };
    //API calling
    executePost(formData, Add_EXPENSE, updateStateOnResponse);
  };

  return (
    <MainPageLayout headerConfig={{ title: "Add Loan" }}>
      <AddLoan
        sendData={sendData}
        isSucceed={isSucceed}
        setIsSucceed={setIsSucceed}
        loading={loading}
        navigate={navigate}
        responseData={responseData}
        setResponseData={setResponseData}
      />
      {formError && (
        <DialogBox
          title="Error"
          btnText="Ok"
          message={handleErrMessage(formErrMsg)}
          closeAction={() => setFormError(false)}
        />
      )}
    </MainPageLayout>
  );
};

export default AddLoanPage;
