import React from "react";
import { useSelector } from "react-redux";
import { pendingExpenseHeadList } from "../../../utils/TableHeadList";
import TableHead from "../../UI/TableUI/TableHead";
import sortDataByDate from "../../../functions/sorting/sortDataByDate";
import { formatDate } from "../../../functions/dateTime/formatDate";
import { checkValue } from "../../../functions/checkValue";
import { getExpenseStatus } from "../../../functions/getExpenseStatus";
import NoDataDisplay from "../../UI/NoDataDisplay";
import CheckInput from "../../UI/TableUI/CheckInput";
import AcceptRejectOnMultipleCheck from "./AcceptRejectOnMultipleCheck";
import SingleExpenseCheck from "./SingleExpenseCheck";
import { setTableHeight } from "../../../functions/setTableHeight";

const TableContent = ({
  openViewDialog,
  handleConfirmDialogBox,
  isMultipleCheck,
  checkedData,
  setCheckedData,
  isAllCheck,
  setAllCheck,
  checkedRow,
  setCheckedRow,
  checkedType,
  setCheckedType,
}) => {
  const data = useSelector((state) => state.data.data);
  const isSorted = useSelector((state) => state.data.isSorted);
  const isDataAvailable = data.length > 0;
  let dataList = isSorted ? data : sortDataByDate(data, "createdAt");

  return (
    <div className={`table-container ${setTableHeight(isDataAvailable)}`}>
      <table className={`table ${!isDataAvailable ? "relative" : ""}`}>
        <tbody>
          <TableHead
            headList={pendingExpenseHeadList}
            isMultipleCheck={isMultipleCheck}
            isAllCheck={isAllCheck}
            setAllCheck={setAllCheck}
            setCheckedRow={setCheckedRow}
            checkedRow={checkedRow}
            setCheckedData={setCheckedData}
            setCheckedType={setCheckedType}
            checkedType={checkedType}
          />
          {isDataAvailable ? (
            <>
              {dataList.map((data, index) => {
                return (
                  <tr
                    className={`table-row ${
                      checkedRow.includes(data.id)
                        ? "bg-lb6"
                        : "border-b-light-gray"
                    }`}
                    key={index}
                  >
                    {isMultipleCheck && (
                      <CheckInput
                        id={data.id}
                        setAllCheck={setAllCheck}
                        isAllCheck={isAllCheck}
                        checkedRow={checkedRow}
                        setCheckedRow={setCheckedRow}
                      />
                    )}
                    <td className="p-3">{index + 1}</td>
                    <td className="p-3">
                      {data.user.fullName} (U) <br></br>
                      {data.receiver && <>{data.receiver.fullName} (R)</>}
                    </td>
                    <td className="p-3">{`₹${data.amount}`}</td>
                    <td className="p-3 w-72">{checkValue(data.description)}</td>
                    <td className="p-3">
                      {formatDate(data.date)}
                      {data.backDated && (
                        <span className="backDatedBtn">BD</span>
                      )}
                    </td>
                    <td className="p-3">{data.category.name}</td>
                    <td
                      className={`status-data ${
                        getExpenseStatus(data.state).textColor
                      }`}
                    >
                      {getExpenseStatus(data.state).status}
                    </td>
                    <td className="dataCheckWrap">
                      {isMultipleCheck ? (
                        <AcceptRejectOnMultipleCheck
                          isRowSelected={checkedRow.includes(data.id)}
                          setCheckedData={setCheckedData}
                          dataId={data.id}
                          checkedData={checkedData}
                          isAllCheck={isAllCheck}
                        />
                      ) : (
                        <SingleExpenseCheck
                          handleConfirmDialogBox={handleConfirmDialogBox}
                          dataId={data.id}
                        />
                      )}
                    </td>
                    <td className="p-3">
                      <button
                        className="tableViewBtn"
                        onClick={() => openViewDialog(dataList[index])}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                );
              })}
            </>
          ) : (
            <NoDataDisplay />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableContent;
