import DetailRow from "../DetailRow";
import { checkValue } from "../../../../functions/checkValue";
import Modal from "../../../portal/Modal";
import HeadingDialog from "../HeadingDialog";
import accept from "../../../../assets/icons/accept.svg";
import reject from "../../../../assets/icons/reject.svg";
import { siteCategoryColor } from "../../../../functions/siteCategoryColor";
import GradientButton from "../../buttonUI/GradientButton";
import closeIcon from "../../../../assets/icons/close-blue.svg";
import Remarks from "../../../remark/Remarks";
import { SITE_REMARK } from "../../../../api/allApi_List";
import { timeStampToDateAndTime } from "../../../../functions/dateTime/timeStampToDateAndTime";

export default function SiteViewDialog({
  closeDialog,
  viewDetails,
  successView,
  message,
  modalNotClose,
  messageIcon,
  updateStateOnAddRemark,
  setSiteViewDetails,
}) {
  return (
    <Modal closeDialog={!successView ? closeDialog : modalNotClose}>
      <div className="view-dialog-content absolute-center-align">
        {!successView && <HeadingDialog heading="Site Details" />}
        {successView && (
          <>
            <img
              src={messageIcon}
              alt="messageIcon"
              className="mx-auto mb-1 w-9"
            />
            <p className="view-success-msg-d ">{message}</p>
          </>
        )}
        <div className={`h-500 px-2 overflow-auto`}>
          <div className="view-dialog-content-wrap">
            <DetailRow title="Site Name" value={viewDetails.name} />
            <DetailRow title="Site Id" value={viewDetails.id} />
            <div className={`${siteCategoryColor(viewDetails.category.name)}`}>
              <DetailRow title="Category" value={viewDetails.category.name} />
            </div>
            <DetailRow
              title="Field Technician(JBCC)"
              value={checkValue(viewDetails.jbccFieldTechnician?.fullName)}
            />
            <DetailRow
              title="Field Technician(JN)"
              value={checkValue(viewDetails.jnFieldTechnician?.fullName)}
            />
            <DetailRow
              title="Subdivision"
              value={checkValue(viewDetails.je.subDivision.name)}
              textJustify="text-justify"
              widthDesc="w-64"
            />
            <DetailRow
              title="JE Name"
              value={checkValue(viewDetails.je.name)}
              textJustify="text-justify"
              widthDesc="w-64"
            />
            <DetailRow
              title="Created By"
              value={checkValue(viewDetails.createdBy.fullName)}
            />
            <DetailRow
              title="Created At"
              value={timeStampToDateAndTime(viewDetails.createdAt)}
            />

            <DetailRow
              title="Automation"
              value={
                viewDetails.isAutomation ? (
                  <img src={accept} alt="accept" className="w-5 mt-1" />
                ) : (
                  <img src={reject} alt="reject" className="w-4 mt-1" />
                )
              }
            />
            <DetailRow
              title="Non-Automation"
              value={
                viewDetails.isNonAutomation ? (
                  <img src={accept} alt="accept" className="w-5 mt-1" />
                ) : (
                  <img src={reject} alt="reject" className="w-4 mt-1" />
                )
              }
            />
            {viewDetails.machine && (
              <DetailRow
                title="Machine Assigned"
                value={
                  viewDetails.machine.isAssigned ? (
                    <img src={accept} alt="accept" className="w-5 mt-1" />
                  ) : (
                    <img src={reject} alt="reject" className="w-4 mt-1" />
                  )
                }
              />
            )}
            <Remarks
              remarkList={viewDetails.remarks}
              dataId={viewDetails.id}
              apiUrl={SITE_REMARK}
              updateStateOnAddRemark={updateStateOnAddRemark}
              setViewDetails={setSiteViewDetails}
              isRemarkDelete={true}
            />
          </div>
        </div>
        {successView && (
          <div className="mt-5 pt-3 text-center">
            <GradientButton
              btnText="Ok"
              width="w-56"
              rounded="rounded-full"
              onClick={closeDialog}
            />
          </div>
        )}
        {!successView && (
          <img
            src={closeIcon}
            alt="close"
            className="view-dialog-close"
            onClick={closeDialog}
          />
        )}
      </div>
    </Modal>
  );
}
