export function getHierName(hierName) {
  const namesMap = {
    state: "State",
    department: "Department",
    circle: "Circle",
    division: "Division",
    subDivision: "Sub-Division",
    je: "JE",
  };

  return namesMap[hierName] || "";
}
