import React from "react";

import vectorProfile from "../../assets/images/vectorProfile.svg";
import dummyUser from "../../assets/images/dummyUser.svg";
import RoundedButton from "../UI/buttonUI/RoundedButton";
import ArrowButton from "../UI/buttonUI/ArrowButton";
import UserDetails from "../UI/UserUI/UserDetails";
import useImageUrl from "../../CustomHooks/useImageUrl";
import ImageSlider from "../UI/viewDialogs/ImageSlider";
import UseDialogBox from "../../CustomHooks/UseDialogBox";
import UseUserRolesClaim from "../../CustomHooks/UseUserRolesClaim";
import { mapRoles } from "../../utils/roles";

const HomeProfileBox = ({ userData }) => {
  const { getImageUrl } = useImageUrl();
  const { showDialogBox, setShowDialogBox } = UseDialogBox();
  const { getRoles } = UseUserRolesClaim();
  const rolesArray = getRoles();
  const roleValues = rolesArray.map((role, ind) => {
    return (
      <span key={role}>
        {mapRoles[role]}
        {rolesArray.length - 1 !== ind && ", "}
      </span>
    );
  });
  return (
    <div className="home-profile-main">
      <div className="home-profile-wrap">
        <div className="home-profile-top">
          <img
            src={
              userData.profilePicUrlList
                ? getImageUrl(userData.profilePicUrlList[0])
                : dummyUser
            }
            alt="userProfile"
            className="home-profile-pic"
          />
          <img
            src={vectorProfile}
            alt="vectorProfile"
            className="home-profile-vector"
          />
        </div>
        <div className="homeProfileDetailsWrap">
          <div className="relative">
            <h4 className="home-profile-h">Personal info</h4>
            <UserDetails title="Full name" value={userData.fullName} />
            <UserDetails title="Email" value={userData.email} />
            <UserDetails title="Mobile Number" value={userData.mobileNumber} />
            {userData.idProofUrlList && (
              <div className="homeIdProof">
                <RoundedButton
                  text="View ID Proof"
                  font="font-figtree"
                  onClick={() => setShowDialogBox(true)}
                />
              </div>
            )}
          </div>

          <div>
            <h4 className="home-profile-h mt-4 2xs:max-sm:mt-2">
              Company info
            </h4>

            <div className="capitalize">
              <UserDetails title="Role" value={roleValues} />
            </div>
            <UserDetails
              title="Supervisor (JBCC)"
              value={userData.jbccSupervisor?.fullName ?? "N/A"}
            />

            <UserDetails
              title="Supervisor (JN)"
              value={userData.jnSupervisor?.fullName ?? "N/A"}
            />
            <UserDetails
              title="Admin (JBCC)"
              value={userData.jbccAdmin?.fullName ?? "N/A"}
            />

            <UserDetails
              title="Admin (JN)"
              value={userData.jnAdmin?.fullName ?? "N/A"}
            />

            <UserDetails
              title="Bike registration no."
              value={userData.bikeRegNumber ?? "Not Available"}
            />
            <UserDetails
              title="Total Balance"
              value={`₹${userData.totalBalance}`}
            />
          </div>
          <div className="viewProfileBtn">
            <ArrowButton
              btnText="View Profile"
              rounded="rounded-md"
              width="w-full"
            />
          </div>
        </div>
      </div>
      {showDialogBox && (
        <ImageSlider
          title="Id Proof Photo"
          images={userData.idProofUrlList}
          imageSliderCurrentIndex={0}
          closeDialogBox={() => setShowDialogBox(false)}
        />
      )}
    </div>
  );
};

export default HomeProfileBox;
