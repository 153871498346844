import SuperTokens from "supertokens-auth-react";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";

export function getSuperTokenInit() {
  // Development
  const API_DOMAIN_DEV_URL = "https://be.dev.jaibharatconst.com";
  const WEB_DOMAIN_DEV_URL = "http://localhost:3000";

  // .env.production
  const API_DOMAIN_ENV_URL = process.env.REACT_APP_BE_URL;
  const WEB_DOMAIN_ENV_URL = process.env.REACT_APP_FE_URL;

  const API_DOMAIN = API_DOMAIN_ENV_URL || API_DOMAIN_DEV_URL;
  const WEB_DOMAIN = WEB_DOMAIN_ENV_URL || WEB_DOMAIN_DEV_URL;

  const sliceApiDomain = API_DOMAIN.slice(8);
  const sliceWebDomain = WEB_DOMAIN_ENV_URL
    ? WEB_DOMAIN_ENV_URL.slice(8)
    : WEB_DOMAIN_DEV_URL.slice(7);

  SuperTokens.init({
    appInfo: {
      appName: "JBCC",
      apiDomain: API_DOMAIN,
      websiteDomain: WEB_DOMAIN,
      apiBasePath: "/auth",
      websiteBasePath: "/login",
    },
    recipeList: [
      EmailPassword.init(),
      Session.init({
        tokenTransferMethod: "cookie",
        sessionTokenBackendDomain: sliceApiDomain,
        sessionTokenFrontendDomain: sliceWebDomain,
      }),
    ],
  });
}
