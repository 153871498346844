import React from "react";
import useImageUrl from "../../../../CustomHooks/useImageUrl";

const VisitSigns = ({ ftSign, operatorSign }) => {
  const { getImageUrl } = useImageUrl();
  return (
    <div className="visit-sign-container">
      <h3 className="signText">Signatures</h3>
      <div className="signImgWrap">
        <div className="px-5">
          <p className="w-24 h-24">
            <img src={getImageUrl(ftSign)} alt="sign" />
          </p>
          <p>Service Engineer</p>
        </div>
        <div className="px-5">
          <p className="w-24 h-24">
            <img
              src={getImageUrl(operatorSign)}
              alt="sign"
              className="w-24 h-24"
            />
          </p>
          <p>Operator</p>
        </div>
      </div>
    </div>
  );
};
export default VisitSigns;
