export function invertExpenseAmount(
  amount,
  categoryId,
  expenseUserId,
  receiverId
) {
  const userId = localStorage.getItem("userId");
  if (
    parseInt(userId) === receiverId &&
    (categoryId === 1 || categoryId === 2) &&
    amount < 0
  ) {
    return Math.abs(amount);
  } else if (
    parseInt(userId) === receiverId &&
    (categoryId === 1 || categoryId === 2)
  ) {
    return -amount;
  } else if (parseInt(userId) === expenseUserId && categoryId === 3) {
    return -amount;
  } else if (parseInt(userId) === expenseUserId && categoryId === 4) {
    return -amount;
  } else {
    return amount;
  }
}
