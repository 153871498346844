import React, { useEffect, useState } from "react";
import checkIcon from "../../../assets/icons/checkIcon.svg";

const CheckInput = ({
  id,
  setAllCheck,
  isAllCheck,
  checkedRow,
  setCheckedRow,
}) => {
  const [check, setCheck] = useState(-1);

  const handleCheck = (id) => {
    setAllCheck(false);
    setCheckedRow((prev) => {
      const newArr = prev.includes(id)
        ? prev.filter((option) => option !== id)
        : [...prev, id];
      return newArr;
    });
  };

  useEffect(() => {
    if (isAllCheck) {
      setCheck(id);
    } else if (checkedRow.length === 0) {
      setCheck(-1);
    } else {
      setCheck(checkedRow.includes(id) ? id : -1);
    }
  }, [id, isAllCheck, checkedRow]);

  return (
    <>
      <td className="p-3">
        <button
          className={`w-4 h-4 border-2 border-dark-blue rounded ${
            check === id ? "bg-dark-blue" : ""
          }`}
          onClick={() => handleCheck(id)}
        >
          <img
            src={checkIcon}
            alt="check"
            className={`${check === id ? "" : "opacity-0"}`}
          />
        </button>
      </td>
    </>
  );
};

export default CheckInput;
