import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import UpdateUser from "../../components/user/updateUser/UpdateUser";
import useGetData from "../../CustomHooks/useAxios/useGetData";
import DialogBox from "../../components/UI/DialogBox";
import { handleErrMessage } from "../../functions/handleErrMessage";
import spin from "../../assets/icons/spin.png";
import { USER_SETUP } from "../../api/allApi_List";
import { UPDATE_USER } from "../../api/allApi_List";
import usePatchData from "../../CustomHooks/useAxios/usePatchData";
import MainPageLayout from "../../components/MainPageLayout";
import UseUserLocationState from "../../CustomHooks/UseUserLocationState";

const UpdateUserPage = () => {
  const { data, isLoaded, error, getData, errMsg } = useGetData();
  const { id } = useParams();
  const { userExistingData } = UseUserLocationState();
  const {
    executePatch,
    loading,
    isSucceed,
    formError,
    formErrMsg,
    responseData,
    setIsSucceed,
    setFormError,
  } = usePatchData();
  const navigate = useNavigate();

  useEffect(() => {
    getData(USER_SETUP, navigate);
  }, []);

  // execute post api
  const sendData = async (formValues, updateStateOnResponse) => {
    const formsData = {};
    Object.keys(formValues).forEach((key) => {
      const newValue = formValues[key];
      const existingValue = userExistingData[key];
      if (newValue !== existingValue) {
        formsData[key] = key === "salary" ? parseInt(newValue) : newValue;
      }
    });
    //API calling
    executePatch(formsData, UPDATE_USER + id, updateStateOnResponse);
  };
  return (
    <MainPageLayout headerConfig={{ title: "Update User" }}>
      {isLoaded && !error ? (
        <UpdateUser
          userSetup={data}
          sendData={sendData}
          isSucceed={isSucceed}
          setIsSucceed={setIsSucceed}
          loading={loading}
          navigate={navigate}
          responseData={responseData}
        />
      ) : error ? (
        <DialogBox
          title="Error"
          message={handleErrMessage(errMsg)}
          isErrorHandleButtons={true}
        />
      ) : (
        <DialogBox message="Loading your data" animateIcon={spin} />
      )}
      {formError && (
        <DialogBox
          title="Error"
          btnText="Ok"
          message={handleErrMessage(formErrMsg)}
          closeAction={() => setFormError(false)}
        />
      )}
    </MainPageLayout>
  );
};
export default UpdateUserPage;
