import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { updateData } from "../../redux/dataSlice";
import filterIcon from "../../assets/icons/filter-icon.svg";
import LeftIconButton from "../UI/buttonUI/LeftIconButton";
import { getDropdownsData } from "../../functions/filter/getDropdownsData";
import FilterReset from "./FilterReset";
import { modifyCategory } from "../../functions/filter/modifyCategory";
import { deleteFilterKeysIfDataChecked } from "../../functions/filter/deleteFilterKeysIfDataChecked";
import { setIsFiltered, setFilteredData } from "../../redux/isTableFilterSlice";
import DropdownContainer from "./DropdownContainer";
import { betterFilter } from "../../functions/filter/betterFilter";

const FilterData = ({
  categories,
  getStatus,
  isActionSucceed,
  currentListTab,
  copyOriginalData,
  isTabClicked,
  isLoaded,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data.data);
  const [selectedItems, setSelectedItems] = useState({});
  const [originalData, setOriginalData] = useState(data.slice());
  const [checkboxes, setCheckboxes] = useState({});
  const categoryAndOptionList = getDropdownsData(
    categories,
    originalData,
    location
  );
  const isDataChecked = JSON.parse(localStorage.getItem("isCheckData"));
  const retrievedFilterKeysObj = JSON.parse(localStorage.getItem("filterKeys"));
  const isFilteredKeysLen = retrievedFilterKeysObj
    ? Object.keys(retrievedFilterKeysObj).length > 0
    : false;
  const finalSelectedItems =
    isDataChecked && isFilteredKeysLen ? retrievedFilterKeysObj : selectedItems;

  // Applying filter
  const filteredData = betterFilter(originalData, finalSelectedItems);

  const setFilter = () => {
    if (Object.keys(finalSelectedItems).length > 0) {
      dispatch(updateData(filteredData));
      // isFiltered slice states
      dispatch(setIsFiltered(true));
      dispatch(setFilteredData(filteredData));
    }
  };

  const handleFilter = () => {
    localStorage.setItem("filterKeys", JSON.stringify(selectedItems));
    localStorage.setItem("filterCheckBoxes", JSON.stringify(checkboxes));
    setFilter();
  };

  // handle filtered data after data checked
  // Helper function to handle resetting of data when data is checked with filtered
  const resetData = () => {
    dispatch(updateData(data));
    setSelectedItems({});
    localStorage.removeItem("isCheckData");
    localStorage.removeItem("filterKeys");
    localStorage.removeItem("filterCheckBoxes");
  };

  // Helper function to retrieve and set data from localStorage when data is checked with filtered
  const retrieveAndSetData = () => {
    const retrievedCheckBoxesObj = JSON.parse(
      localStorage.getItem("filterCheckBoxes")
    );
    const retrievedFilterKeysObj = JSON.parse(
      localStorage.getItem("filterKeys")
    );
    const retrievedFilterKeysObjFiltered = deleteFilterKeysIfDataChecked(
      data,
      retrievedFilterKeysObj
    );
    localStorage.setItem(
      "filterKeys",
      JSON.stringify(retrievedFilterKeysObjFiltered)
    );
    setCheckboxes(retrievedCheckBoxesObj);
    setSelectedItems(retrievedFilterKeysObjFiltered);
    setFilter();
  };

  useEffect(() => {
    if (isActionSucceed && isLoaded && isFilteredKeysLen) {
      const isFilteredDataEmpty = filteredData.length === 0;
      if (isFilteredDataEmpty) {
        resetData();
      } else {
        retrieveAndSetData();
      }
    }
  }, [isActionSucceed]);

  // end  handle filtered data after data checked

  // copied original data when all expense tab is clicked
  useEffect(() => {
    if (currentListTab) {
      setOriginalData(copyOriginalData[currentListTab]);
      setSelectedItems({});
    }
  }, [isTabClicked]);

  return (
    <div className="flex">
      {categoryAndOptionList.map((cate, ind) => (
        <DropdownContainer
          key={cate.name}
          name={cate.name}
          selectedItemsLen={selectedItems[modifyCategory(cate.name)]}
          optionsList={cate.optionsList}
          valueAddress={cate.valueAddress}
          setSelectedItems={setSelectedItems}
          getStatus={getStatus}
          status={cate.status}
          checkboxes={checkboxes}
          setCheckboxes={setCheckboxes}
        />
      ))}
      <LeftIconButton
        text="Filter"
        textColor="text-white"
        bgColor="bg-dark-blue border:dark-blue"
        icon={filterIcon}
        iconStyle="2xs:max-md:w-3"
        margin="ml-2 mr-1 2xs:max-md:ml-0"
        hover="main-filterBtn dark-blue-filter"
        onClick={handleFilter}
      />
      {Object.keys(selectedItems).length > 0 ||
      (isDataChecked && isFilteredKeysLen) ? (
        <FilterReset
          setSelectedItems={setSelectedItems}
          setCheckboxes={setCheckboxes}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default FilterData;
