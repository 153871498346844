import React from "react";
import { Link } from "react-router-dom";

export default function RoundedButton(props) {
  return (
    <Link
      className={`white-filter text-dark-blue ${props.font} flex items-center justify-center w-full border-1 border-dark-blue rounded-full px-4 py-1 text-sm 2xs:max-lg:text-xs hover:bg-dark-blue hover:text-white transition duration-150 ease-linear`}
      onClick={props.onClick}
      to={props.navigate}
    >
      {props.icon && <img src={props.icon} alt="round" className="pr-1"/>}
      {props.text}
    </Link>
  );
}
