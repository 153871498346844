import expense from "../../assets/icons/expense.svg";
import addExpenseIcon from "../../assets/icons/addExpenseIcon.svg";
import expensesIcon from "../../assets/icons/expensesIcon.svg";
import pendingIcon from "../../assets/icons/pendingIcon.svg";
import home from "../../assets/icons/home.svg";
import complaint from "../../assets/icons/complaint.svg";
import complaints from "../../assets/icons/complaints.svg";
import sites from "../../assets/icons/sites.svg";
import visits from "../../assets/icons/visits.svg";
import user from "../../assets/icons/user.svg";
import compPending from "../../assets/icons/complaintPending.svg";
import visitPending from "../../assets/icons/visitPending.svg";
import userAdd from "../../assets/icons/addUser.svg";
import officer from "../../assets/icons/officer.svg"
import userAll from "../../assets/icons/userAll.svg";
import siteManager from "../../assets/icons/siteManager.svg";
import siteHierarchy from "../../assets/icons/siteHierarchy.svg";
import siteAdd from "../../assets/icons/siteAdd.svg";
import addComp from "../../assets/icons/addComp.svg";
import expenseSheet from "../../assets/icons/expenseSheet.svg";
import uploadedSheets from "../../assets/icons/uploadedSheets.svg";
import AddSheet from "../../assets/icons/addSheet.svg";
import machines from "../../assets/icons/machines.svg";
import addMachine from "../../assets/icons/addMachine.svg";
import addMachineComp from "../../assets/icons/addMachineComponent.svg";
import changePassword from "../../assets/icons/changePassword.svg";
import expenseHistory from "../../assets//icons/expenseHistory.svg";

export const menuList = [
  {
    menuTitle: "dashboard",
    menuIcon: home,
    route: "/home",
  },
  {
    menuTitle: "user",
    menuIcon: user,
    height: "h-50",
    anyPerm: true,
    subMenu: [
      {
        menuTitle: "Add User",
        route: "/user/add",
        menuIcon: userAdd,
        anyPerm: true,
      },
      {
        menuTitle: "Users",
        route: "/user/all",
        menuIcon: userAll,
        anyPerm: true,
      },
      {
        menuTitle: "Add Officer",
        route: "/user/add-officer",
        menuIcon: userAdd,
        anyPerm: true,
      },
      {
        menuTitle: "Officers",
        route: "/officer/all",
        menuIcon: officer,
        anyPerm: true,
      },
    ],
  },
  {
    menuTitle: "expense",
    menuIcon: expense,
    height: "h-50",
    anyPerm: false,
    subMenu: [
      {
        menuTitle: "Add Expense",
        route: "/expense/add",
        menuIcon: addExpenseIcon,
        anyPerm: true,
      },
      {
        menuTitle: "Expense",
        route: "/expense/all",
        menuIcon: expensesIcon,
        anyPerm: false,
      },
      {
        menuTitle: "Receiver Pending",
        route: "/expense/receiver-pending",
        menuIcon: pendingIcon,
        anyPerm: false,
      },
      {
        menuTitle: "Supervisor Pending",
        route: "/expense/supervisor-pending",
        menuIcon: pendingIcon,
        anyPerm: true,
      },
      {
        menuTitle: "Admin Pending",
        route: "/expense/admin-pending",
        menuIcon: pendingIcon,
        anyPerm: true,
      },
      {
        menuTitle: "Super Admin Pending",
        route: "/expense/super-admin-pending",
        menuIcon: pendingIcon,
        anyPerm: true,
      },
      {
        menuTitle: "Check History",
        route: "/expense/history",
        menuIcon: expenseHistory,
        anyPerm: true,
      },
    ],
  },
  {
    menuTitle: "Complaint",
    menuIcon: complaint,
    height: "h-50",
    anyPerm: true,
    subMenu: [
      {
        menuTitle: "Add Complaint",
        route: "/complaint/add",
        menuIcon: addComp,
        anyPerm: true,
      },
      {
        menuTitle: "Complaints",
        route: "/complaint/all",
        menuIcon: complaints,
        anyPerm: true,
      },
      {
        menuTitle: "Complaint Pending",
        route: "/complaint/pending",
        menuIcon: compPending,
        anyPerm: true,
      },
      {
        menuTitle: "Visits",
        route: "/complaint/visit-all",
        menuIcon: visits,
      },
      {
        menuTitle: "Visit Pending",
        route: "/complaint/visit-pending",
        menuIcon: visitPending,
        anyPerm: true,
      },
    ],
  },
  {
    menuTitle: "Site Manager",
    menuIcon: siteManager,
    height: "h-50",
    anyPerm: true,
    subMenu: [
      {
        menuTitle: "Add Site",
        route: "/site/add",
        menuIcon: siteAdd,
        anyPerm: true,
      },
      {
        menuTitle: "Sites",
        route: "/site/all",
        menuIcon: sites,
        anyPerm: true,
      },
      {
        menuTitle: "Add Jurisdiction",
        route: "/site/add-jurisdiction",
        menuIcon: siteHierarchy,
        anyPerm: true,
      },
      {
        menuTitle: "Assign Machine",
        route: "/site/assign-machine",
        menuIcon: addMachine,
        anyPerm: true,
      },
      {
        menuTitle: "Add Machine",
        route: "/site/add-machine",
        menuIcon: addMachine,
        anyPerm: true,
      },
      {
        menuTitle: "Machines",
        route: "/site/all-machine",
        menuIcon: machines,
        anyPerm: true,
      },
      {
        menuTitle: "Add Machine Component",
        route: "/site/add-machine-component",
        menuIcon: addMachineComp,
        anyPerm: true,
      },
    ],
  },
  {
    menuTitle: "Expense Sheet",
    menuIcon: expenseSheet,
    height: "h-50",
    anyPerm: true,
    subMenu: [
      {
        menuTitle: "Upload Sheets",
        route: "/sheet/add",
        menuIcon: AddSheet,
        anyPerm: true,
      },
      {
        menuTitle: "Uploaded Sheets List",
        route: "/sheet/uploaded-all",
        menuIcon: uploadedSheets,
        anyPerm: true,
      },
    ],
  },
  {
    menuTitle: "Change Password",
    menuIcon: changePassword,
    route: "/change-password",
  },
];
