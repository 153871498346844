import React from "react";
import { Link } from "react-router-dom";

export default function GrayButton({
  btnText,
  width,
  onClick,
  navigate,
  height,
  textSize,
}) {
  return (
    <Link
      className={`text-dark-blue ${
        textSize ? textSize : ""
      } flex items-center justify-center font-figtree font-semibold bg-lg5 2xs:max-sm:px-1 px-2 rounded-md ${width} hover:bg-transparent border-white hover:border-dark-blue border-2 ${
        height ? "h-9" : "h-47"
      } hover:text-lb5 transition ease-in-out duration-700 hover:font-semibold`}
      onClick={onClick}
      to={navigate}
    >
      {btnText}
    </Link>
  );
}
