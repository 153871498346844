import React from "react";
import GradientButton from "./buttonUI/GradientButton";
import GrayButton from "./buttonUI/GrayButton";
import Modal from "../portal/Modal";
import closeIcon from "../../assets/icons/close.svg";

const Dialog = ({
  messageIcon,
  message,
  confirmBtnText,
  checkAction,
  cancelBtnText,
  closeDialog,
  numbersOfAcceptAndReject,
}) => {
  const getDataCheckCountsObj = JSON.parse(
    localStorage.getItem("dataCheckCounts")
  );
  const numbersOfAcceptAndRejectObj = getDataCheckCountsObj
    ? getDataCheckCountsObj
    : numbersOfAcceptAndReject;

  return (
    <Modal closeDialog={closeDialog}>
      <div className="flex-center-align absolute-center-align dialogBoxWrap">
        <div className="px-12 2xs:max-sm:px-0">
          {messageIcon && (
            <img src={messageIcon} alt="messageIcon" className="mx-auto mb-5" />
          )}
          {message && <p className="dialogBoxMsg">{message}</p>}
          {(numbersOfAcceptAndReject || getDataCheckCountsObj) && (
            <>
              <p className="font-figtree pt-4 text-gray-700">
                {numbersOfAcceptAndRejectObj.trueCount} Expenses Selected to
                Accept
              </p>
              <p className="font-figtree text-gray-700">
                {numbersOfAcceptAndRejectObj.falseCount} Expenses Selected to
                Reject
              </p>
            </>
          )}
          {confirmBtnText && cancelBtnText && (
            <div className="flex-center-align mt-10 2xs:max-sm:mt-4 2xs:max-sm:px-2">
              <div className="p-2 w-full 2xs:max-sm:p-1">
                <GradientButton
                  btnText={confirmBtnText}
                  width="w-full"
                  rounded="rounded-md"
                  onClick={checkAction}
                />
              </div>
              <div className="p-2 w-full 2xs:max-sm:p-1">
                <GrayButton
                  btnText={cancelBtnText}
                  width="w-full"
                  onClick={closeDialog}
                />
              </div>
            </div>
          )}
        </div>
        <img
          src={closeIcon}
          alt="close"
          className="dialogCloseBtn"
          onClick={closeDialog}
        />
      </div>
    </Modal>
  );
};

export default Dialog;
