import React from "react";

import arrowRight from "../../../assets/icons/arrow-right.svg";
import { Link } from "react-router-dom";

export default function ArrowButton(props) {
  return (
    <Link
      className={`flex-center-align dark-blue-filter bg-button-bg text-white py-3 2xs:max-base:py-2 px-1 font-poppins border-1 2xs:max-lg:text-sm hover:text-dark-blue hover:border-dark-blue hover:bg-none transition-all duration-300 ease-linear ${props.width} ${props.rounded}`}
      to='/profile'
    >
      {props.btnText}
      <img src={arrowRight} alt="arrowRight" className="pl-2" />
    </Link>
  );
}
