import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ALL_EXPENSES } from "../../api/allApi_List";
import { fetchData } from "../../redux/dataSlice";
import Expense from "../../components/expense/all/Expense";
import DialogBox from "../../components/UI/DialogBox";
import spin from "../../assets/icons/spin.png";
import { handleErrMessage } from "../../functions/handleErrMessage";
import MainPageLayout from "../../components/MainPageLayout";
import ExpenseListTab from "../../components/expense/all/ExpenseListTabs";

const ExpensePage = () => {
  const [currentListTab, SetCurrentListTab] = useState("expenses");
  const [render, setRender] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.data.data);
  const error = useSelector((state) => state.data.error);
  const errMsg = useSelector((state) => state.data.errMessage);
  const [copyOriginalData, setCopyOriginalData] = useState({});
  const [isTabClicked, setIsTabClicked] = useState(false);

  const ExpenseListTabs = (
    <ExpenseListTab
      currentListTab={currentListTab}
      SetCurrentListTab={SetCurrentListTab}
      copyOriginalData={copyOriginalData}
      setIsTabClicked={setIsTabClicked}
    />
  );

  useEffect(() => {
    dispatch(
      fetchData(
        ALL_EXPENSES,
        setIsLoaded,
        navigate,
        "allExpenses",
        currentListTab,
        setCopyOriginalData
      )
    );
  }, [dispatch, render]);

  const updateStateOnAddRemark = () => {
    setRender(!render);
  };
  return (
    <MainPageLayout ExpenseListTabs={ExpenseListTabs}>
      {isLoaded && !error ? (
        <Expense
          expenseData={data}
          copyOriginalData={copyOriginalData}
          currentListTab={currentListTab}
          isTabClicked={isTabClicked}
          SetCurrentListTab={SetCurrentListTab}
          
          updateStateOnAddRemark={updateStateOnAddRemark}
        />
      ) : error ? (
        <DialogBox
          title="Error"
          message={handleErrMessage(errMsg)}
          isErrorHandleButtons={true}
        />
      ) : (
        <DialogBox message="Loading..." animateIcon={spin} />
      )}
    </MainPageLayout>
  );
};

export default ExpensePage;
