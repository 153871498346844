import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import AddUser from "../../components/user/addUser/AddUser";
import useGetData from "../../CustomHooks/useAxios/useGetData";
import DialogBox from "../../components/UI/DialogBox";
import { handleErrMessage } from "../../functions/handleErrMessage";
import spin from "../../assets/icons/spin.png";
import { USER_SETUP } from "../../api/allApi_List";
import { ADD_USER } from "../../api/allApi_List";
import usePostData from "../../CustomHooks/useAxios/usePostData";
import MainPageLayout from "../../components/MainPageLayout";
import { prepareAddUserFormData } from "../../functions/prepareAddUserFormData";

const AddUserPage = () => {
  const [files, setFiles] = useState({});
  const { data, isLoaded, error, getData, errMsg } = useGetData();
  const {
    executePost,
    loading,
    isSucceed,
    formError,
    formErrMsg,
    responseData,
    setIsSucceed,
    setFormError,
  } = usePostData();
  const navigate = useNavigate();

  useEffect(() => {
    getData(USER_SETUP, navigate);
  }, []);

  // execute post api
  const sendData = async (formValues, reset, setSelectedOption) => {
    // send data into form data
    const formData = prepareAddUserFormData(formValues, files);
    const updateStateOnResponse = () => {
      setSelectedOption({});
      // reset form value
      reset();
    };
    // API calling
    executePost(formData, ADD_USER, updateStateOnResponse);
  };
  return (
    <MainPageLayout headerConfig={{ title: "Add User" }}>
      {isLoaded && !error ? (
        <AddUser
          userSetup={data}
          files={files}
          setFiles={setFiles}
          sendData={sendData}
          isSucceed={isSucceed}
          setIsSucceed={setIsSucceed}
          loading={loading}
          navigate={navigate}
          responseData={responseData}
        />
      ) : error ? (
        <DialogBox
          title="Error"
          message={handleErrMessage(errMsg)}
          isErrorHandleButtons={true}
        />
      ) : (
        <DialogBox message="Loading your data" animateIcon={spin} />
      )}
      {formError && (
        <DialogBox
          title="Error"
          btnText="Ok"
          message={handleErrMessage(formErrMsg)}
          closeAction={() => setFormError(false)}
        />
      )}
    </MainPageLayout>
  );
};
export default AddUserPage;
