export const FilterVisitFlags = {
  name: "Flags",
  optionsList: [
    {
      value: "true_ejectorVacuumCheck",
      label: "Ejector vacuum working ✓",
    },
    {
      value: "false_ejectorVacuumCheck",
      label: "Ejector vacuum working ✕",
    },
    {
      value: "true_ejectorBackPressureCheck",
      label: "Ejector back pressure check ✓",
    },
    {
      value: "false_ejectorBackPressureCheck",
      label: "Ejector back pressure check ✕",
    },
    {
      value: "true_hooterCheck",
      label: "Hooter working ✓",
    },
    {
      value: "false_hooterCheck",
      label: "Hooter working ✕",
    },
    {
      value: "true_leakDetectorCheck",
      label: "Leak detector ✓",
    },
    {
      value: "false_leakDetectorCheck",
      label: "Leak detector ✕",
    },
    {
      value: "true_sampleCheck",
      label: "Sample valid ✓",
    },
    {
      value: "false_sampleCheck",
      label: "Sample valid ✕",
    },
    {
      value: "true_ammoniaLeakageCheck",
      label: "Ammonia Leakage Check ✓",
    },
    {
      value: "false_ammoniaLeakageCheck",
      label: "Ammonia Leakage Check ✕",
    },
  ],
  valueAddress: [
    ["ejectorVacuumCheck"],
    ["ejectorBackPressureCheck"],
    ["hooterCheck"],
    ["leakDetectorCheck"],
    ["sampleCheck"],
    ["ammoniaLeakageCheck"],
  ],
};
