import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { changeExpenseAmountData } from "../functions/changeExpenseAmountData";
import { setFilteredData, setIsFiltered } from "./isTableFilterSlice";

const initialState = {
  data: [],
  error: false,
  errMessage: "",
  isSorted: false,
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
      state.error = false;
    },
    setErrMessage: (state, action) => {
      state.errMessage = action.payload;
    },
    setError: (state) => {
      state.data = [];
      state.error = true;
    },
    setSortStatus: (state, action) => {
      state.isSorted = action.payload;
    },
    setReset: (state) => {
      state.data = [];
      state.error = false;
    },
  },
});

export const {
  setData,
  setError,
  setReset,
  setSortStatus,
  setErrMessage,
  setIsLoaded,
} = dataSlice.actions;

export const fetchData =
  (
    apiEndPoint,
    setIsLoaded,
    navigate,
    dataAmountWillChange,
    currentListTab,
    setCopyOriginalData
  ) =>
  async (dispatch) => {
    dispatch(setReset());
    try {
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: apiEndPoint,
      };

      const response = await axios.request(config);
      if (dataAmountWillChange) {
        const changedExpenseAmountData = changeExpenseAmountData(
          response.data,
          dataAmountWillChange
        );
        if (currentListTab) {
          setCopyOriginalData(changedExpenseAmountData);
        }
        const updateDataByTab = currentListTab
          ? changedExpenseAmountData[currentListTab]
          : changedExpenseAmountData;
        dispatch(setData(updateDataByTab));
      } else {
        dispatch(setData(response.data));
      }

      setIsLoaded(true);
      dispatch(setSortStatus(false));
      dispatch(setFilteredData([]));
      dispatch(setIsFiltered(false));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      } else {
        dispatch(setError());
        dispatch(
          setErrMessage(
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    }
  };
export const updateData = (filteredData) => (dispatch) => {
  dispatch(setData(filteredData));
};

export default dataSlice.reducer;
