import React, { useEffect, useState } from "react";
import search from "../../assets/icons/search.svg";
import { modifyCategory } from "../../functions/filter/modifyCategory";
import sortDataByAlphabetically from "../../functions/sorting/sortDataByAlphabetically";

const FilterSearchDropdown = ({
  open,
  category,
  optionList,
  valueAddress,
  setSelectedItems,
  getStatus,
  status,
  checkboxes,
  setCheckboxes,
}) => {
  const copyOptionList = optionList || [];
  const [searchList, setSearchList] = useState();
  const [optionArr, setOptionArr] = useState([]);
  let modifiedCategory = modifyCategory(category);

  const handleSearch = (e) => {
    let val = e.target.value.trim();
    if (val.length > 0) {
      const filteredItems = copyOptionList.filter((item) => {
        let optItem = status
          ? getStatus(item.label)?.status?.toLowerCase()
          : item.label?.toString()?.toLowerCase();
        return (
          optItem?.includes(val.toLowerCase()) ||
          optionArr.includes(item.value?.toString()?.toLowerCase())
        );
      });
      const sortedOptions = sortDataByAlphabetically(filteredItems, ["label"]);
      setSearchList(sortedOptions); // Update searchList with filtered items
    } else {
      const sortedOptions = sortDataByAlphabetically(copyOptionList, ["label"]);
      setSearchList(sortedOptions);
    }
  };

  const handleCheckbox = (e) => {
    let value = e.target.value;
    const checkboxId = e.target.id.split(" ").join("");
    const getChecked = e.target.checked;
    setCheckboxes((prev) => ({ ...prev, [checkboxId]: getChecked }));

    const isDataChecked = JSON.parse(localStorage.getItem("isCheckData"));
    const retrievedObject = JSON.parse(localStorage.getItem("filterKeys"));
    if (isDataChecked) {
      localStorage.removeItem("isCheckData");
      setSelectedItems((prev) => ({ ...prev, ...retrievedObject }));
    }
    setSelectedItems((prev) => {
      const currentCategory =
        prev[modifiedCategory] && prev[modifiedCategory]["val"]
          ? prev[modifiedCategory]["val"]
          : [];
      const newArr = currentCategory.includes(value)
        ? currentCategory.filter((option) => option !== value)
        : [...currentCategory, value];
      return {
        ...prev,
        [modifiedCategory]: { val: newArr, address: valueAddress },
      };
    });
    setOptionArr((prev) => {
      const newArr = prev.includes(value)
        ? prev.filter((option) => option !== value)
        : [...prev, value];
      return newArr;
    });
  };

  useEffect(() => {
    const sortedOptions = sortDataByAlphabetically(optionList, ["label"]);
    setSearchList(sortedOptions);
  }, [optionList]);

  return (
    <div
      className={`filterSearchDropdownWrap ${
        open === 1 ? "grid opacity-1" : "invisible opacity-0"
      }`}
    >
      <p className="mx-2 mb-2">
        <input
          type="text"
          placeholder="Search"
          onChange={handleSearch}
          className="filterSearchDropdownInput"
        />
        <img src={search} alt="search" className="filterSearchIcon-d" />
      </p>
      {searchList && searchList.length > 0 ? (
        <ul
          className={`${searchList.length > 6 ? "h-56 overflow-y-scroll" : ""}`}
        >
          {searchList.map((item) => {
            return item.label != null && item.label !== undefined ? (
              <li
                key={`${item.value} + ${item.label}`}
                className={`filterDropdown-item ${
                  checkboxes[
                    `${item.value}_${item.label}_${category}`
                      .split(" ")
                      .join("")
                  ]
                    ? "bg-sec-blue text-white"
                    : ""
                }`}
              >
                <input
                  type="checkbox"
                  id={`${item.value}_${item.label}_${category}`}
                  name={item.label}
                  value={item.value.toString().toLowerCase()}
                  className="cursor-pointer accent-dark-blue"
                  onChange={handleCheckbox}
                  checked={
                    checkboxes[
                      `${item.value}_${item.label}_${category}`
                        .split(" ")
                        .join("")
                    ]
                  }
                />
                <label
                  htmlFor={`${item?.value}_${item?.label}_${category}`}
                  className="filterCheckInputLabel"
                >
                  {status ? getStatus(parseInt(item.label)).status : item.label}
                </label>
              </li>
            ) : (
              ""
            );
          })}
        </ul>
      ) : (
        <p className="filterSearchErr">Not Found</p>
      )}
    </div>
  );
};
export default FilterSearchDropdown;
