export const jurisOptions = [
  { id: 1, name: "State" },

  { id: 2, name: "Department" },
  { id: 3, name: "Circle" },
  { id: 4, name: "Division" },
  { id: 5, name: "Sub-Division" },
  { id: 6, name: "JE" },
];

export const jurisArr = [
  "state",
  "department",
  "circle",
  "division",
  "subDivision",
  "je",
];
