import React from "react";

import FilterData from "./Filter/FilterData";
import SearchTable from "./SearchTable";
import MultipleCheck from "./MultipleCheck";
import AddButton from "./UI/buttonUI/AddButton";

const FilterSearch = ({
  categories,
  searchCategories,
  getStatus,
  expenseCheck,
  isMultipleCheck,
  setMultipleCheck,
  setSelectedChecks,
  enableMultiCheckApplyBtn,
  cancelMultipleCheck,
  handleMultiCheckConfirmDialog,
  checkedData,
  checkedRow,
  isActionSucceed,
  currentListTab,
  copyOriginalData,
  isTabClicked,
  isLoaded,
  addBtnText,
  addBtnNavigation,
  isCopyData,
}) => {
  const checkExpenseTabData = currentListTab
    ? copyOriginalData[currentListTab].length !== 0
    : true;
  const showFilter = categories && isCopyData && checkExpenseTabData;
  const showSearch = searchCategories && isCopyData && checkExpenseTabData;
  return (
    <div className="filter-search">
      {expenseCheck ? (
        <div className="flex items-center">
          {checkedRow.length !== 0 && (
            <p className="mr-2 font-figtree">{`(${checkedRow.length})`}</p>
          )}
          <MultipleCheck
            isMultipleCheck={isMultipleCheck}
            setMultipleCheck={setMultipleCheck}
            setSelectedChecks={setSelectedChecks}
            enableMultiCheckApplyBtn={enableMultiCheckApplyBtn}
            cancelMultipleCheck={cancelMultipleCheck}
            handleMultiCheckConfirmDialog={handleMultiCheckConfirmDialog}
            checkedData={checkedData}
          />
        </div>
      ) : (
        ""
      )}
      <div
        className={`flex ${
          !expenseCheck ? "justify-between w-full" : ""
        } filterSearchWrap`}
      >
        {showFilter && (
          <FilterData
            categories={categories}
            getStatus={getStatus}
            isActionSucceed={isActionSucceed}
            currentListTab={currentListTab}
            copyOriginalData={copyOriginalData}
            isTabClicked={isTabClicked}
            isLoaded={isLoaded}
          />
        )}
        <div
          className={`${
            addBtnText === "Add User" ||
            addBtnText === "Upload Sheet" ||
            addBtnText === "Add Officer"
              ? "justify-between w-full"
              : ""
          } flex justify-end w-full`}
        >
          {showSearch && (
            <SearchTable
              searchCategories={searchCategories}
              getStatus={getStatus}
              currentListTab={currentListTab}
              copyOriginalData={copyOriginalData}
              isActionSucceed={isActionSucceed}
            />
          )}
          <div className="pl-1 2xs:max-md:hidden">
            {addBtnText && (
              <AddButton
                btnText={addBtnText}
                navigate={addBtnNavigation}
                height="h-36h"
                textSize="text-sm"
                padding="px-2"
                icon="w-4 h-4 pb-1 mr-1"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default FilterSearch;
