import Session from "supertokens-auth-react/recipe/session";
import { PermissionClaim } from "supertokens-auth-react/recipe/userroles";

 const UsePermissionsClaim = () => {
  let claimValue = Session.useClaimValue(PermissionClaim);
  const checkPermission = (permission) => {
    if (claimValue.loading || !claimValue.doesSessionExist) {
      return null;
    }
    let permissions = claimValue.value;
    if (Array.isArray(permissions) && permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  };
  return { checkPermission };
};

export default UsePermissionsClaim;
