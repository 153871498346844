import React from "react";

const ButtonIcon = ({ btnText, icon, onclick }) => {
  return (
    <button
      className={`flex-center-align dark-blue-filter bg-button-bg text-white py-2 2xs:max-base:py-2 px-2 font-figtree border-1 2xs:max-lg:text-sm hover:text-dark-blue hover:border-dark-blue hover:bg-none transition-all duration-300 ease-linear rounded-md`}
      onClick={onclick}
    >
      <img src={icon} alt="iconButton" className="w-4" />
      <span className="pl-2">{btnText}</span>
    </button>
  );
};

export default ButtonIcon;
