import React, { useState } from "react";
import { useForm } from "react-hook-form";

import CenteredHeading from "../UI/headingsUI/CenteredHeading";
import GrayButton from "../UI/buttonUI/GrayButton";
import DialogBox from "../UI/DialogBox";
import SearchDropdown from "../UI/PageInput/SearchDropdown";
import successIcon from "../../assets/icons/successIcon.svg";
import SubmitButton from "../UI/buttonUI/SubmitButton";

const AssignMachine = ({
  machineSetup,
  sendData,
  setIsSucceed,
  isSucceed,
  loading,
  responseData,
  navigate,
}) => {
  const [selectedOption, setSelectedOption] = useState({});
  const isDataEmpty =
    machineSetup.stations.length === 0 || machineSetup.machines.length === 0;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    sendData(data, reset, setSelectedOption);
  };

  // handle clear form
  const handleClearForm = () => {
    setSelectedOption({});
    // reset form value
    reset();
  };

  return (
    <>
      <div className="form-container">
        <CenteredHeading title="Assign Machine to Station" />
        <form
          onSubmit={!loading ? (e) => handleSubmit(onSubmit)(e) : undefined}
        >
          <div className="flex flex-wrap">
            <div className="leftCol mx-auto pr-0">
              <SearchDropdown
                name="stationId"
                label="Station Name *"
                keyName="name"
                id="id"
                options={machineSetup.stations}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
                setValue={setValue}
                register={{
                  ...register("stationId", {
                    required: "Please select a Station name",
                  }),
                }}
                render={isSucceed}
                error={errors.stationId}
                disabled={machineSetup.stations.length === 0 ? true : false}
                search={true}
              />
              {machineSetup.stations.length === 0 && (
                <p className="machinesErr">All Stations Assigned</p>
              )}
              <SearchDropdown
                name="machineId"
                label="Machine ID *"
                keyName="id"
                id="id"
                options={machineSetup.machines}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
                setValue={setValue}
                register={{
                  ...register("machineId", {
                    required: "Please select a Machine ID",
                  }),
                }}
                render={isSucceed}
                error={errors.machineId}
                disabled={machineSetup.machines.length === 0 ? true : false}
                search={true}
                optionListIsNumber={true}
              />
              {machineSetup.machines.length === 0 && (
                <p className="machinesErr">All Machine Assigned</p>
              )}
            </div>
          </div>
          <div className="addBtn---wrap">
            <p className="addWrap">
              <SubmitButton
                btnText={!loading ? "Assign Machine" : "Adding..."}
                loading={loading}
                height="h-12"
                isDisabled={isDataEmpty}
              />
            </p>
            <p className="grayWrap">
              <GrayButton
                btnText="Clear"
                width="w-40"
                onClick={handleClearForm}
              />
            </p>
          </div>
        </form>
      </div>
      {isSucceed && (
        <DialogBox
          message={`Successfully  Machine Assigned!`}
          messageIcon={successIcon}
          btnText="Ok"
          closeAction={() => setIsSucceed(false)}
        />
      )}
    </>
  );
};
export default AssignMachine;
