import React, { useState } from "react";
import { useSelector } from "react-redux";

import TableHead from "../../UI/TableUI/TableHead";
import { complaintsHeadList } from "../../../utils/TableHeadList";
import { formatDate } from "../../../functions/dateTime/formatDate";
import NoDataDisplay from "../../UI/NoDataDisplay";
import { getComplaintStatus } from "../../../functions/getComplaintStatus";
import sortDataByDate from "../../../functions/sorting/sortDataByDate";
import JustifiedHeading from "../../UI/headingsUI/JustifiedHeading";
import FilterSearch from "../../FilterSearch";
import { complaintSearch } from "../../../utils/tableSearchList";
import { filterCategoryComplaint } from "../../../utils/filterCategoryList";
import ComplaintViewDialog from "../../UI/viewDialogs/complaint/ComplaintViewDialog";
import UseDialogBox from "../../../CustomHooks/UseDialogBox";
import ImageSlider from "../../UI/viewDialogs/ImageSlider";
import GetCheckCross from "../../UI/viewDialogs/GetCheckCross";
import UsePermissionsClaim from "../../../CustomHooks/UsePermissionsClaim";

const Complaints = ({ complaintsData, updateStateOnAddRemark }) => {
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [complaintViewDetails, setComplaintViewDetails] = useState();
  const { showDialogBox, setShowDialogBox } = UseDialogBox();
  const [imageSliderPics, setImageSliderPics] = useState([]);
  const [imageSliderCurrentIndex, setImageSliderCurrentIndex] = useState();

  const isSorted = useSelector((state) => state.data.isSorted);
  const { checkPermission } = UsePermissionsClaim();
  const permAddComplaint = checkPermission("add_complaint");

  let dataList = isSorted
    ? complaintsData
    : sortDataByDate(complaintsData, "createdAt");
  const isDataAvailable = complaintsData.length > 0;

  const [copyData] = useState(complaintsData.slice());
  const isCopyData = copyData.length > 0;

  const openViewDialog = (complaintsData) => {
    setViewDialogOpen(true);
    setComplaintViewDetails(complaintsData);
  };
  const closeViewDialog = () => {
    setViewDialogOpen(false);
  };

  const openImageSlider = (Pics) => {
    closeViewDialog();
    setShowDialogBox(true);
    setImageSliderPics(Pics);
  };

  const closeImageSlider = () => {
    setViewDialogOpen(true);
    setShowDialogBox(false);
  };

  return (
    <>
      <JustifiedHeading
        title="Complaints"
        navigate={"/complaint/add"}
        btnText={permAddComplaint ? "Add Complaint" : undefined}
      />
        <FilterSearch
          categories={filterCategoryComplaint}
          searchCategories={complaintSearch}
          getStatus={getComplaintStatus}
          addBtnNavigation={"/complaint/add"}
          addBtnText={permAddComplaint ? "Add Complaint" : undefined}
          isCopyData={isCopyData}
        />
      <div className={`table-container table-height`}>
        <table className={`table ${!isDataAvailable ? "relative" : ""}`}>
          <tbody>
            <TableHead headList={complaintsHeadList} />
            {isDataAvailable ? (
              <>
                {dataList.map((data, index) => {
                  return (
                    <tr className="table-row" key={index}>
                      <td className="p-3">{data.id}</td>
                      <td className="p-3">{data.station.name}</td>
                      <td className="p-3">{data.fieldTechnician.fullName}</td>
                      <td className="p-3">{formatDate(data.createdAt)}</td>
                      <td className="p-3">{data.description}</td>
                      <td className="p-3">{data.category.name}</td>
                      <td
                        className={`status-data ${
                          getComplaintStatus(data.status).textColor
                        }`}
                      >
                        {getComplaintStatus(data.status).status}
                      </td>
                      <td className="p-3">
                        {" "}
                        <GetCheckCross
                          checkVal={data.visitIdList ? true : false}
                        />
                      </td>
                      <td className="p-3">
                        <button
                          className="tableViewBtn"
                          onClick={() => openViewDialog(dataList[index])}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <NoDataDisplay />
            )}
          </tbody>
        </table>
      </div>
      {viewDialogOpen && (
        <ComplaintViewDialog
          closeDialog={closeViewDialog}
          viewDetails={complaintViewDetails}
          openImageSlider={openImageSlider}
          setImageSliderCurrentIndex={setImageSliderCurrentIndex}
          updateStateOnAddRemark={updateStateOnAddRemark}
          setComplaintViewDetails={setComplaintViewDetails}
        />
      )}
      {showDialogBox && (
        <ImageSlider
          title="Complaint Photos"
          images={imageSliderPics}
          imageSliderCurrentIndex={imageSliderCurrentIndex}
          closeDialogBox={closeImageSlider}
        />
      )}
    </>
  );
};
export default Complaints;
