import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { UPLOAD_SHEET_SETUP } from "../../api/allApi_List";
import useGetData from "../../CustomHooks/useAxios/useGetData";
import DialogBox from "../../components/UI/DialogBox";
import spin from "../../assets/icons/spin.png";
import { handleErrMessage } from "../../functions/handleErrMessage";
import { UPLOAD_SHEET } from "../../api/allApi_List";
import UploadSheets from "../../components/expenseSheets/UploadSheets";
import { uploadSheet } from "../../api/uploadSheet";
import MainPageLayout from "../../components/MainPageLayout";

const UploadSheetPage = () => {
  const navigate = useNavigate();
  const { data, isLoaded, error, getData, errMsg } = useGetData();
  const [loading, setLoading] = useState(false);
  const [sheetDetail, setSheetDetail] = useState({ sheet: undefined });
  const [files, setFiles] = useState({});
  const [isSucceed, setIsSucceed] = useState(false);
  const [formError, setFormError] = useState(false);
  const [formErrMsg, setFormErrMsg] = useState("");
  const [responseData, setResponseData] = useState();
  const [isErrorSheetList, setErrorSheetList] = useState(false);

  useEffect(() => {
    getData(UPLOAD_SHEET_SETUP, navigate);
  }, []);

  const sendData = async (reset, setSelectedOption) => {
    // send data into form data
    const formData = new FormData();
    for (const key in sheetDetail) {
      formData.append(key, sheetDetail[key]);
    }
    if (files.length !== 0) {
      for (const key in files) {
        files[key].forEach((i, ind) => {
          formData.append(key, i);
        });
      }
    }
    const updateStateOnResponse = () => {
      setIsSucceed(true);
      setSheetDetail({ sheet: undefined });
      setSelectedOption({});
      setFiles({});
      // reset form value
      reset();
    };
    //API calling
    uploadSheet(
      formData,
      UPLOAD_SHEET,
      setLoading,
      setFormError,
      setFormErrMsg,
      updateStateOnResponse,
      setResponseData,
      setErrorSheetList,
      navigate
    );
  };
  return (
    <MainPageLayout headerConfig={{ title: "Upload Sheet" }}>
      {isLoaded && !error ? (
        <UploadSheets
          setIsSucceed={setIsSucceed}
          isSucceed={isSucceed}
          setSheetDetail={setSheetDetail}
          sheetDetail={sheetDetail}
          files={files}
          setFiles={setFiles}
          sheetSetup={data}
          loading={loading}
          sendData={sendData}
          navigate={navigate}
          responseData={responseData}
          isErrorSheetList={isErrorSheetList}
          setErrorSheetList={setErrorSheetList}
        />
      ) : error ? (
        <DialogBox
          title="Error"
          message={handleErrMessage(errMsg)}
          isErrorHandleButtons={true}
        />
      ) : (
        <DialogBox message="Loading your data" animateIcon={spin} />
      )}
      {formError && (
        <DialogBox
          title="Error"
          btnText="Ok"
          message={handleErrMessage(formErrMsg)}
          closeAction={() => setFormError(false)}
        />
      )}
    </MainPageLayout>
  );
};
export default UploadSheetPage;
