import React, { useEffect, useState } from "react";
import { formatDate } from "../../../functions/dateTime/formatDate";
import calender from "../../../assets/icons/calendar.svg";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import useClickOutside from "../../../CustomHooks/useClickOutside";
import { formatDateString } from "../../../functions/dateTime/formatDateString";

const DateInput = ({
  name,
  setValue,
  register,
  error,
  isFormClear,
}) => {
  const [date, setDate] = useState();
  const [showDatePicker, serShowDatePicker] = useState(-1);
  const { dropdownRef } = useClickOutside(() => serShowDatePicker(-1));

  const handleShowDatePicker = () => {
    serShowDatePicker((prevIndex) => (prevIndex === 1 ? -1 : 1));
  };
  useEffect(() => {
    if (date) {
      setValue(name, formatDateString(date), { shouldValidate: true });
    } else {
      setValue(name, "");
    }
  }, [date, name, setValue]);

  useEffect(() => {
    setDate();
  }, [isFormClear]);

  const today = new Date();
  return (
    <div>
      <label
        htmlFor="date-time-input"
        className="font-poppins text-dg2 text-sm"
      >
        Date *
      </label>
      <div className="relative" ref={dropdownRef}>
        <div onClick={handleShowDatePicker} className="cursor-pointer">
          <label
            htmlFor="custom-calendar"
            className="text-sm font-poppins absolute left-2 pl-2 pr-3 py-2 top-1 bg-white"
          >
            {date ? formatDate(date) : "Select Date"}
          </label>
          <img
            src={calender}
            alt="calender"
            className="absolute right-2 top-3 w-5"
          />
          <input
            name={name}
            type="text"
            readOnly="readonly"
            className={`w-full border-bw rounded-md cursor-pointer py-9s px-3 focus-visible:outline-dark-blue ${
              error ? "border-red-500" : "border-lg4"
            }`}
            {...register}
          />
        </div>
        {showDatePicker === 1 && (
          <div className="shadow-sh-1 max-w-xs absolute left-0 top-12 bg-white z-9999">
            <DayPicker
              mode="single"
              captionLayout="dropdown"
              defaultMonth={today}
              selected={date}
              onSelect={setDate}
              disableNavigation
              classNames={{
                caption_label:
                  "font-bold font-poppins flex items-center mr-3 mb-2",
                head_row: "flex font-medium text-gray-900",
                head_cell: "w-7 font-normal text-sm",
                row: "flex w-full",
                cell: "text-gray-600 rounded-md h-7 w-7 text-center text-sm p-0",
                day: "h-7 w-7 p-0 font-normal",
                day_range_end: "day-range-end",
                day_selected:
                  "rounded-md text-white hover:bg-gray-900 hover:text-white focus:bg-gray-900 focus:text-white",
              }}
            />
          </div>
        )}
        <p
          className={`text-red-500 font-figtree text-xs h-4 ${
            error ? "visible" : "invisible"
          }`}
        >
          {error?.message}
        </p>
      </div>
    </div>
  );
};

export default DateInput;
