import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useGetData from "../../CustomHooks/useAxios/useGetData";
import DialogBox from "../../components/UI/DialogBox";
import spin from "../../assets/icons/spin.png";
import { MACHINE_COMPONENT_SETUP } from "../../api/allApi_List";
import { ADD_MACHINE_COMPONENT } from "../../api/allApi_List";
import AddMachineComponent from "../../components/site/AddMachineComponent";
import { handleErrMessage } from "../../functions/handleErrMessage";
import usePostData from "../../CustomHooks/useAxios/usePostData";
import MainPageLayout from "../../components/MainPageLayout";

const AddMachineComponentPage = ({ setAddMachineCompPerm }) => {
  const [render, setRender] = useState(false);
  const navigate = useNavigate();
  const { data, isLoaded, error, getData, errMsg } = useGetData();
  const {
    executePost,
    loading,
    isSucceed,
    formError,
    formErrMsg,
    setFormError,
  } = usePostData();

  useEffect(() => {
    getData(MACHINE_COMPONENT_SETUP, navigate);
  }, []);

  const sendData = async (formValues, reset, setSelectedOption) => {
    const updateStateOnResponse = () => {
      setSelectedOption({});
      setRender(!render);
      // reset form value
      reset();
    };
    // API calling
    executePost(formValues, ADD_MACHINE_COMPONENT, updateStateOnResponse);
  };

  return (
    <MainPageLayout headerConfig={{title:"Add Machine Component"}}>
      {isLoaded && !error ? (
        <AddMachineComponent
          machineSetup={data}
          sendData={sendData}
          isSucceed={isSucceed}
          loading={loading}
          navigate={navigate}
          render={render}
        />
      ) : error ? (
        <DialogBox
          title="Error"
          message={handleErrMessage(errMsg)}
          isErrorHandleButtons={true}
        />
      ) : (
        <DialogBox message="Loading your data" animateIcon={spin} />
      )}
      {formError && (
        <DialogBox
          title="Error"
          btnText="Ok"
          message={handleErrMessage(formErrMsg)}
          closeAction={() => setFormError(false)}
        />
      )}
    </MainPageLayout>
  );
};

export default AddMachineComponentPage;
