import { useState } from "react";
import axios from "axios";

const useGetData = () => {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState(false);

  const getData = async (apiEndPoint, navigate, userApi) => {
    const isUserNameSet = localStorage.getItem("userName");
    const isProfilePicSet = localStorage.getItem("profilePic");
    let config = {
      method: "get",
      // maxBodyLength: Infinity,
      url: apiEndPoint,
    };
    await axios
      .request(config)
      .then((response) => {
        setData(response.data);
        setIsLoaded(true);
        if (userApi && isUserNameSet === null) {
          localStorage.setItem("userName", response.data.fullName);
        }
        if (userApi && isProfilePicSet === null && response.data.profilePicUrlList) {
          localStorage.setItem(
            "profilePic",
            response.data.profilePicUrlList[0]
          );
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.status === 401) {
          navigate("/login");
        } else {
          setErrMsg(
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message
          );
          setError(true);
        }
      });
  };

  return {
    data,
    isLoaded,
    error,
    getData,
    setIsLoaded,
    setError,
    errMsg,
    setErrMsg,
    setData,
  };
};

export default useGetData;
