import imageCompression from "browser-image-compression";

export async function getCompressImage(setFiles, pic, key) {
  if (pic.length !== 0) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      for (let i = 0; i < pic.length; i++) {
        const compressedFile = await imageCompression(pic[i], options);
        const blobToFile = new File([compressedFile], compressedFile.name, {
          type: compressedFile.type,
          lastModified: compressedFile.lastModified,
        });
        setFiles((prev) => {
          const pictures = prev[key] ? prev[key] : [];
          return { ...prev, [key]: [...pictures, blobToFile] };
        });
      }
    } catch (error) {
      console.error("Error compressing image:", error);
    }
  }
}
