import React, { useState, useEffect } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import calender from "../../../assets/icons/calendar.svg";
import { formatDate } from "../../../functions/dateTime/formatDate";
import { formatDateString } from "../../../functions/dateTime/formatDateString";
import useClickOutside from "../../../CustomHooks/useClickOutside";

const BackDate = ({
  register,
  setExpenseDetail,
  expenseDetail,
  value = "",
  setValue,
  isFormClear,
}) => {
  const { dropdownRef } = useClickOutside(() => serShowDatePicker(-1));
  const [checked, setChecked] = useState(false);
  const [date, setDate] = useState();
  const [showDatePicker, serShowDatePicker] = useState(-1);

  const handleChecked = () => {
    setChecked((WasChecked) => {
      const updatedChecked = !WasChecked;
      if (updatedChecked === false) {
        setValue("date", "");
        setDate();
        setExpenseDetail((prev) => {
          const { date, ...newExpenseDetail } = prev;
          return newExpenseDetail;
        });
      }
      return updatedChecked;
    });
  };

  const handleShowDatePicker = () => {
    serShowDatePicker((prevIndex) => (prevIndex === 1 ? -1 : 1));
  };

  useEffect(() => {
    if (date) {
      setValue("date", formatDateString(date), { shouldValidate: true });
    } else {
      setValue("date", "");
    }
  }, [date, setValue]);

  useEffect(() => {
    setChecked(false);
    setDate();
  }, [isFormClear]);

  const today = new Date();
  const disabledDays = { after: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1) };

  return (
    <div>
      <p className="p-1">
        <input
          type="checkbox"
          id="date"
          className="cursor-pointer accent-dark-blue focus-visible:outline-dark-blue"
          name="date"
          onClick={handleChecked}
          defaultChecked={checked}
        />
        <label
          htmlFor="date"
          className="text-black text-sm font-poppins cursor-pointer"
        >
          {" "}
          Change Date
        </label>
      </p>
      {checked && (
        <div className="relative mb-2" ref={dropdownRef}>
          <div onClick={handleShowDatePicker} className="cursor-pointer">
            <label
              htmlFor="custom-calendar"
              className="text-sm font-poppins absolute left-2 pl-2 pr-3 py-2 top-1 bg-white"
            >
              {date ? formatDate(date) : "Select Date"}
            </label>
            <img
              src={calender}
              alt="calender"
              className="absolute right-2 top-3 w-5"
            />
            <input
              name="date"
              type="text"
              readOnly="readonly"
              value={value}
              className={`w-full border-bw border-lg4 cursor-pointer rounded-md py-9s px-3 focus-visible:outline-dark-blue`}
              {...register}
            />
          </div>
          {showDatePicker === 1 && (
            <div className="shadow-sh-1 max-w-xs absolute left-0 top-12 bg-white z-9999">
              <DayPicker
                mode="single"
                captionLayout="dropdown"
                defaultMonth={today}
                selected={date}
                onSelect={setDate}
                disabled={disabledDays}
                disableNavigation
                classNames={{
                  caption_label:
                    "font-bold font-poppins flex items-center mr-3 mb-2",
                  head_row: "flex font-medium text-gray-900",
                  head_cell: "w-7 font-normal text-sm",
                  row: "flex w-full",
                  cell: "text-gray-600 rounded-md h-7 w-7 text-center text-sm p-0",
                  day: "h-7 w-7 p-0 font-normal",
                  day_range_end: "day-range-end",
                  day_selected:
                    "rounded-md text-white hover:bg-gray-900 hover:text-white focus:bg-gray-900 focus:text-white",
                  day_disabled: "text-gray-400 cursor-not-allowed",
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BackDate;
