import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import AddOfficer from "../../components/user/officer/AddOfficer";
import { ADD_OFFICER } from "../../api/allApi_List";
import useGetData from "../../CustomHooks/useAxios/useGetData";
import { OFFICER_SETUP } from "../../api/allApi_List";
import DialogBox from "../../components/UI/DialogBox";
import { handleErrMessage } from "../../functions/handleErrMessage";
import spin from "../../assets/icons/spin.png";
import usePostData from "../../CustomHooks/useAxios/usePostData";
import MainPageLayout from "../../components/MainPageLayout";

const AddOfficerPage = () => {
  const {
    executePost,
    loading,
    isSucceed,
    formError,
    formErrMsg,
    setFormError,
    setIsSucceed,
    responseData
  } = usePostData();
  const { data, isLoaded, error, getData, errMsg } = useGetData();
  const navigate = useNavigate();

  useEffect(() => {
    getData(OFFICER_SETUP, navigate);
  }, []);

  // execute post api
  const sendData = async (formDataObj, reset, setLevelDropdown) => {
    const updateStateOnResponse = () => {
      setLevelDropdown([]);
      // reset form value
      reset();
    };
    //API calling
    executePost(formDataObj, ADD_OFFICER, updateStateOnResponse);
  };
  return (
    <MainPageLayout headerConfig={{title: "Add Officer"}}>
      {isLoaded && !error ? (
        <AddOfficer
          officerSetup={data}
          sendData={sendData}
          isSucceed={isSucceed}
          loading={loading}
          navigate={navigate}
          setIsSucceed={setIsSucceed}
          responseData={responseData}
        />
      ) : error ? (
        <DialogBox
          title="Error"
          message={handleErrMessage(errMsg)}
          isErrorHandleButtons={true}
        />
      ) : (
        <DialogBox message="Loading your data" animateIcon={spin} />
      )}
      {formError && (
        <DialogBox
          title="Error"
          btnText="Ok"
          message={handleErrMessage(formErrMsg)}
          closeAction={() => setFormError(false)}
        />
      )}
    </MainPageLayout>
  );
};

export default AddOfficerPage;
