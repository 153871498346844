export function getTimeString(timeString) {
  const date = new Date(timeString);

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  let amOrPm = "AM";
  let adjustedHours = hours;
  if (adjustedHours >= 12) {
    amOrPm = "PM";
    adjustedHours -= 12;
  }
  if (adjustedHours === 0) {
    adjustedHours = 12;
  }

  return ", " + hours + ":" + minutes + ":" + seconds + " " + amOrPm;
}
