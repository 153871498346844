const sortByDate = (data, isArrowToggled, address) => {
  const getNestedProperty = (data, address) => {
    return address.reduce((obj, key) => obj && obj[key], data);
  };
  let sortedData = data.slice().sort((a, b) => {
    let aData = getNestedProperty(a, address);
    let bData = getNestedProperty(b, address);
    let aDate = new Date(aData);
    let bDate = new Date(bData);

    return !isArrowToggled ? aDate - bDate : bDate - aDate;
  });
  return sortedData;
};
export default sortByDate;
