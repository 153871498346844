import { getNestedProperty } from "../getNestedProperty";

export function deleteFilterKeysIfDataChecked(data, filterKeys) {
  Object.keys(filterKeys).forEach((key) => {
    const valueArr = filterKeys[key]["val"];
    const propertyArr = filterKeys[key]["address"];

    if (valueArr.length > 0) {
      // Initialize a set to keep track of all valid values
      let validValues = new Set();

      propertyArr.forEach((element) => {
        const dataValueArr = data.map((i) => {
          return getNestedProperty(i, [...element]);
        });

        // Filter out any undefined values in dataValueArr
        const filteredDataValueArr = dataValueArr.filter(
          (val) => val !== undefined
        );

        // Add matching values to the set
        filteredDataValueArr.forEach((val) => {
          if (valueArr.includes(val.toString())) {
            validValues.add(val.toString());
          }
        });
      });

      // Update the val array to contain only valid values
      filterKeys[key]["val"] = valueArr.filter((val) => validValues.has(val));
    }
  });

  return filterKeys;
}
