import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const usePatchData = () => {
  const [loading, setLoading] = useState(false);
  const [isSucceed, setIsSucceed] = useState(false);
  const [formError, setFormError] = useState(false);
  const [formErrMsg, setFormErrMsg] = useState("");
  const [responseData, setResponseData] = useState({});
  const navigate = useNavigate();

  const executePatch = async (sendData, apiUrl, updateStateOnResponse) => {
    let config = {
      method: "patch",
      url: apiUrl,
      data: sendData,
    };
    setLoading(true);
    await axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setResponseData(response.data);
        setLoading(false);
        setIsSucceed(true);
        updateStateOnResponse();
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.status === 401) {
          navigate("/login");
        } else {
          setFormError(true);
          setLoading(false);
          setFormErrMsg(
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message
          );
        }
      });
  };

  return {
    executePatch,
    loading,
    isSucceed,
    formError,
    formErrMsg,
    responseData,
    setIsSucceed,
    setFormError,
    setResponseData,
  };
};

export default usePatchData;
