export const getNestedProperty = (dataObj, props) => {
  if (!dataObj || props.length === 0) {
    return undefined; // Safeguard against undefined dataObj or empty props
  }
  if (props.length === 1) {
    return dataObj[props[0]];
  }
  const prop = props.shift();
  return getNestedProperty(dataObj[prop], props);
};
