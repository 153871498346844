import React, { useState } from "react";
import Modal from "../../portal/Modal";
import useImageUrl from "../../../CustomHooks/useImageUrl";
import closeIcon from "../../../assets/icons/close.svg";

const ImageSlider = ({
  title,
  images,
  imageSliderCurrentIndex,
  closeDialogBox,
}) => {
  const [currentIndex, setCurrentIndex] = useState(imageSliderCurrentIndex);
  const { getImageUrl } = useImageUrl();

  const prevSlide = () => {
    const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <Modal closeDialog={closeDialogBox}>
      <div className="modal-content absolute-center-align imgSliderContent">
        <h3 className="imgSliderHeading">{title}</h3>
        <div className="relative">
          <button className="left-0 prev-next-btn" onClick={prevSlide}>
            ❮
          </button>
          <div className="mx-7">
            <img
              src={getImageUrl(images[currentIndex])}
              alt={`Slide ${currentIndex}`}
              className="sliderImg"
            />
          </div>
          <button className="right-0 prev-next-btn" onClick={nextSlide}>
            ❯
          </button>
        </div>
        <h3 className="currentSliderImgInd">
          {`${currentIndex + 1}/${images.length}`}
        </h3>
        <img
          src={closeIcon}
          alt="close"
          className="sliderCloseIco"
          onClick={closeDialogBox}
        />
      </div>
    </Modal>
  );
};
export default ImageSlider;
