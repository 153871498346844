import React from "react";

import { moduleList } from "../../utils/moduleList";
import ModuleContent from "./ModuleContent";

const HomeModules = () => {
  return (
    <div className="homeModule-main">
      <div className="homeModule-wrap">
        <h2 className="homeModule-h ">Modules</h2>
        <div className="flex flex-wrap">
          {moduleList.map((listItem) => (
            <ModuleContent key={listItem.moduleTitle} {...listItem} />
          ))}
        </div>
      </div>
    </div>
  );
};
export default HomeModules;
