import React from "react";
import { Link } from "react-router-dom";

export default function AddButton({
  loading,
  height,
  navigate,
  onClick,
  btnText,
  textSize,
  padding,
  isDisabled,
  icon,
}) {
  return (
    <Link
      className={`bg-button-bg text-white ${padding ? padding : "px-4"} ${
        isDisabled ? "cursor-not-allowed pointer-events-none" : ""
      } 2xs:max-sm:text-sm rounded-lg group flex-center-align hover:bg-none border-white hover:border-dark-blue border-2 hover:text-dark-blue transition ease-in-out duration-700
      ${
        !loading ? "" : "cursor-not-allowed pointer-events-none bg-black"
      } ${height}
    `}
      to={navigate}
      onClick={onClick}
    >
      <span
        className={`flex-center-align rounded-full border-1 ${icon ? icon : "pb-0.5 w-5 h-5 mr-2"} group-hover:text-dark-blue group-hover:border-dark-blue`}
      >
        +
      </span>
      <span className={`${textSize ? textSize : "text-lg"}`}>{btnText}</span>
    </Link>
  );
}
