import { configureStore } from "@reduxjs/toolkit";
import dataReducer from "./dataSlice";
import isTableFilterReducer from "./isTableFilterSlice";
import uuidReducer from "./uuidSlice";

export const store = configureStore({
  reducer: {
    data: dataReducer,
    isTableFilter: isTableFilterReducer,
    uuid: uuidReducer,
  },
});
