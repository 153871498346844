import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ALL_VISIT } from "../../api/allApi_List";
import { fetchData } from "../../redux/dataSlice";
import Visits from "../../components/complaint/all/Visits";
import DialogBox from "../../components/UI/DialogBox";
import spin from "../../assets/icons/spin.png";
import { handleErrMessage } from "../../functions/handleErrMessage";
import MainPageLayout from "../../components/MainPageLayout";

const VisitsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [render, setRender] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const data = useSelector((state) => state.data.data);
  const error = useSelector((state) => state.data.error);
  const errMsg = useSelector((state) => state.data.errMessage);
  const dataLength = isLoaded && !errMsg ? data.length : 0;

  useEffect(() => {
    dispatch(fetchData(ALL_VISIT, setIsLoaded, navigate));
  }, [dispatch, render]);

  const updateStateOnAddRemark = () => {
    setRender(!render);
  };

  return (
    <MainPageLayout
      headerConfig={{ title: "Visits" }}
      numberOfData={dataLength}
    >
      {isLoaded && !error ? (
        <Visits
          visitsData={data}
          updateStateOnAddRemark={updateStateOnAddRemark}
        />
      ) : error ? (
        <DialogBox
          title="Error"
          message={handleErrMessage(errMsg)}
          isErrorHandleButtons={true}
        />
      ) : (
        <DialogBox message="Loading your data" animateIcon={spin} />
      )}
    </MainPageLayout>
  );
};

export default VisitsPage;
