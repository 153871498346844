import React, { useState } from "react";
import { useForm } from "react-hook-form";

import CenteredHeading from "../UI/headingsUI/CenteredHeading";
import GrayButton from "../UI/buttonUI/GrayButton";
import SubmitButton from "../UI/buttonUI/SubmitButton";
import CustomInput from "../UI/PageInput/CustomInput";
import SearchDropdown from "../UI/PageInput/SearchDropdown";
import successIcon from "../../assets/icons/successIcon.svg";
import SiteViewDialog from "../UI/viewDialogs/site/SiteViewDialog";

const AddSite = ({
  siteSetup,
  sendData,
  setIsSucceed,
  isSucceed,
  loading,
  navigate,
  responseData,
  setResponseData,
}) => {
  const [selectedOption, setSelectedOption] = useState({});

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    const formValues = {
      ...data,
      isAutomation: false,
    };
    sendData(formValues, reset, setSelectedOption);
  };

  // handle clear form
  const handleClearForm = () => {
    setSelectedOption({});
    // reset form value
    reset();
  };

  return (
    <>
      <div className="form-container">
        <CenteredHeading title="Add Site" />
        <form
          onSubmit={!loading ? (e) => handleSubmit(onSubmit)(e) : undefined}
        >
          <div className="flex flex-wrap">
            <div className="halfCol">
              <div className="px-1">
                <CustomInput
                  type="text"
                  name="name"
                  labelText="Site Name *"
                  placeholder="Enter"
                  register={{
                    ...register("name", {
                      required: "Please Enter a name",
                      validate: (value) => {
                        if (value.trim() === "") {
                          return "Site Name cannot contain only spaces";
                        }
                        return true;
                      },
                    }),
                  }}
                  error={errors.name}
                />
                <SearchDropdown
                  name="jbccFt"
                  label="Field Technician (JBCC) *"
                  keyName="fullName"
                  id="id"
                  options={siteSetup.jbccFieldTechnicians}
                  setSelectedOption={setSelectedOption}
                  selectedOption={selectedOption}
                  setValue={setValue}
                  register={{
                    ...register("jbccFt", {
                      required: "Please select a Field Technician (JBCC)",
                    }),
                  }}
                  error={errors.jbccFt}
                  search={true}
                />
                <SearchDropdown
                  name="jeId"
                  label="JE Name *"
                  keyName="name"
                  id="id"
                  options={siteSetup.jes}
                  setSelectedOption={setSelectedOption}
                  selectedOption={selectedOption}
                  setValue={setValue}
                  register={{
                    ...register("jeId", {
                      required: "Please select a JE Name",
                    }),
                  }}
                  error={errors.jeId}
                  search={true}
                />
              </div>
            </div>
            <div className="halfCol">
              <div className="px-1">
                <SearchDropdown
                  name="stationCategoryId"
                  label="Site Category *"
                  keyName="name"
                  id="id"
                  options={siteSetup.categories}
                  setSelectedOption={setSelectedOption}
                  selectedOption={selectedOption}
                  setValue={setValue}
                  register={{
                    ...register("stationCategoryId", {
                      required: "Please select site category",
                    }),
                  }}
                  error={errors.stationCategoryId}
                  search={true}
                />
                <SearchDropdown
                  name="jnFt"
                  label="Field Technician (JN) *"
                  keyName="fullName"
                  id="id"
                  options={siteSetup.jnFieldTechnicians}
                  setSelectedOption={setSelectedOption}
                  selectedOption={selectedOption}
                  setValue={setValue}
                  register={{
                    ...register("jnFt", {
                      required: "Please select a Field Technician (JN)",
                    }),
                  }}
                  error={errors.jnFt}
                  search={true}
                />
              </div>
            </div>
          </div>
          <div className="addBtn---wrap">
            <p className="addWrap">
              <SubmitButton
                btnText={!loading ? "Add Site" : "Adding..."}
                loading={loading}
                height="h-12"
              />
            </p>
            <p className="grayWrap">
              <GrayButton
                btnText="Clear"
                width="w-40"
                onClick={handleClearForm}
              />
            </p>
          </div>
        </form>
      </div>
      {isSucceed && (
        <SiteViewDialog
          closeDialog={() => navigate("/site/all")}
          modalNotClose={() => setIsSucceed(true)}
          viewDetails={responseData}
          successView={true}
          message="Created Successfully Created!"
          messageIcon={successIcon}
          setSiteViewDetails={setResponseData}
        />
      )}
    </>
  );
};
export default AddSite;