import React, { useState } from "react";
import { useSelector } from "react-redux";

import TableHead from "../../UI/TableUI/TableHead";
import { officersHeadList } from "../../../utils/TableHeadList";
import NoDataDisplay from "../../UI/NoDataDisplay";
import JustifiedHeading from "../../UI/headingsUI/JustifiedHeading";
import { officerSearch } from "../../../utils/tableSearchList";
import UsePermissionsClaim from "../../../CustomHooks/UsePermissionsClaim";
import FilterSearch from "../../FilterSearch";
import { setTableHeight } from "../../../functions/setTableHeight";
import sortDataByAlphabetically from "../../../functions/sorting/sortDataByAlphabetically";
import { formatDate } from "../../../functions/dateTime/formatDate";

const Officers = ({ officersData }) => {
  const isSorted = useSelector((state) => state.data.isSorted);

  let dataList = isSorted
    ? officersData
    : sortDataByAlphabetically(officersData, ["fullName"]);

  const isDataAvailable = officersData.length > 0;

  const [copyData] = useState(officersData.slice());
  const isCopyData = copyData.length > 0;
  const { checkPermission } = UsePermissionsClaim();
  const permAddOfficer = checkPermission("add_officer");

  return (
    <>
      <JustifiedHeading
        title="All Officer"
        navigate={"/user/add-officer"}
        btnText={permAddOfficer ? "Add Officer" : undefined}
      />
      {isCopyData ? (
        <FilterSearch
          searchCategories={officerSearch}
          addBtnNavigation={"/user/add-officer"}
          addBtnText={permAddOfficer ? "Add Officer" : undefined}
          isCopyData={isCopyData}
        />
      ) : (
        ""
      )}
      <div className={`table-container ${setTableHeight(isDataAvailable)}`}>
        <table className={`table ${!isDataAvailable ? "relative" : ""}`}>
          <tbody>
            <TableHead headList={officersHeadList} />
            {isDataAvailable ? (
              <>
                {dataList.map((data, index) => {
                  return (
                    <tr className="table-row" key={index}>
                      <td className="p-3">{index + 1}</td>
                      <td className="p-3">{data.id}</td>
                      <td className="p-3">{data.fullName}</td>
                      <td className="p-3">{formatDate(data.createdAt)}</td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <NoDataDisplay />
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default Officers;
