import React, { useState } from "react";
import { useSelector } from "react-redux";

import TableHead from "../UI/TableUI/TableHead";
import { usersHeadList } from "../../utils/TableHeadList";
import NoDataDisplay from "../UI/NoDataDisplay";
import JustifiedHeading from "../UI/headingsUI/JustifiedHeading";
import { userSearch } from "../../utils/tableSearchList";
import UserViewDetailsDialog from "../UI/viewDialogs/user/UserViewDetailsDialog";
import UsePermissionsClaim from "../../CustomHooks/UsePermissionsClaim";
import FilterSearch from "../FilterSearch";
import { setTableHeight } from "../../functions/setTableHeight";
import UserActions from "./UserActions";
import sortDataByAlphabetically from "../../functions/sorting/sortDataByAlphabetically";

const Users = ({ usersData }) => {
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [userViewDetails, setUserViewDetails] = useState();
  const isSorted = useSelector((state) => state.data.isSorted);

  let dataList = isSorted
    ? usersData
    : sortDataByAlphabetically(usersData, ["fullName"]);

  const isDataAvailable = usersData.length > 0;

  const [copyData] = useState(usersData.slice());
  const isCopyData = copyData.length > 0;
  const { checkPermission } = UsePermissionsClaim();
  const permAddUser = checkPermission("add_user");

  const addLoanPerm = checkPermission("add_loan");
  const addLoanRepaymentPerm = checkPermission("add_loan_expense");
  const addDeductionPerm = checkPermission("add_deduction_expense");
  const addUpdateUserPerm = checkPermission("update_user");
  const checkAllUserActionsPerm =
    addLoanPerm ||
    addLoanRepaymentPerm ||
    addDeductionPerm ||
    addUpdateUserPerm;

  const openViewDialog = (expenseData) => {
    setViewDialogOpen(true);
    setUserViewDetails(expenseData);
  };
  const closeViewDialog = () => {
    setViewDialogOpen(false);
  };

  return (
    <>
      <JustifiedHeading
        title="All users"
        navigate={"/user/add"}
        btnText={permAddUser ? "Add User" : undefined}
      />
      {isCopyData ? (
        <FilterSearch
          searchCategories={userSearch}
          addBtnNavigation={"/user/add"}
          addBtnText={permAddUser ? "Add User" : undefined}
          isCopyData={isCopyData}
        />
      ) : (
        ""
      )}
      <div className={`table-container ${setTableHeight(isDataAvailable)}`}>
        <table className={`table ${!isDataAvailable ? "relative" : ""}`}>
          <tbody>
            <TableHead headList={usersHeadList} />
            {isDataAvailable ? (
              <>
                {dataList.map((data, index) => {
                  return (
                    <tr className="table-row" key={index}>
                      <td className="p-3">{index + 1}</td>
                      <td className="p-3">
                        {data.fullName}
                        <br></br>
                        <span className="lg text-lg3">{data.email}</span>
                      </td>
                      <td className="p-3">{`₹${data.loanBalance}`} </td>
                      <td className="p-3">{`₹${data.pendingBalance}`} </td>
                      <td className="p-3">{`₹${data.totalBalance}`} </td>
                      <td className="p-3">
                        <button
                          className="tableViewBtn"
                          onClick={() => openViewDialog(dataList[index])}
                        >
                          View
                        </button>
                      </td>
                      {checkAllUserActionsPerm && (
                        <td className="p-3">
                          <UserActions userData={data} />
                        </td>
                      )}
                    </tr>
                  );
                })}
              </>
            ) : (
              <NoDataDisplay />
            )}
          </tbody>
        </table>
      </div>
      {viewDialogOpen && (
        <UserViewDetailsDialog
          closeDialog={closeViewDialog}
          viewDetails={userViewDetails}
        />
      )}
    </>
  );
};
export default Users;