import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import CenteredHeading from "../../UI/headingsUI/CenteredHeading";
import PersonalInfo from "./PersonalInfo";
import CompanyInfo from "./CompanyInfo";
import BankInfo from "./BankInfo";
import SubmitButton from "../../UI/buttonUI/SubmitButton";
import GrayButton from "../../UI/buttonUI/GrayButton";
import successIcon from "../../../assets/icons/successIcon.svg";
import UserViewDetailsDialog from "../../UI/viewDialogs/user/UserViewDetailsDialog";
import { getCompressImage } from "../../../functions/getCompressImage";

const AddUser = ({
  files,
  setFiles,
  userSetup,
  sendData,
  isSucceed,
  setIsSucceed,
  loading,
  navigate,
  responseData,
}) => {
  const [selectedOption, setSelectedOption] = useState({});
  const [isFormClear, setIsFormClear] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      roles: [],
      profile_pic: [],
      id_proof: [],
    },
  });

  let pic = watch("profile_pic");
  let IdProofPics = watch("id_proof");
  const dob = watch("dob")
  const roles = watch("roles");

  useEffect(() => {
    const selectedPic = Array.from(pic);
    setFiles((prev) => {
      return {
        ...prev,
        profile_pic: [...selectedPic],
      };
    });
  }, [pic, setFiles]);

  useEffect(() => {
    getCompressImage(setFiles, IdProofPics, "id_proof");
  }, [IdProofPics, setFiles]);

  const onSubmit = (data) => {
    sendData(data, reset, setSelectedOption);
  };

  // handle clear form
  const handleClearForm = () => {
    setSelectedOption({});
    setFiles({});
    setIsFormClear(!isFormClear);
    // reset form value
    reset();
  };
  return (
    <div className="form-container">
      <CenteredHeading title="Add User" />
      <form onSubmit={!loading ? (e) => handleSubmit(onSubmit)(e) : undefined}>
        <PersonalInfo
          userSetup={userSetup}
          register={register}
          errors={errors}
          setValue={setValue}
          dob={dob}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          files={files}
          setFiles={setFiles}
          isFormClear={isFormClear}
        />
       <div>
    <div className="flex flex-wrap">
    <CompanyInfo
          userSetup={userSetup}
          register={register}
          errors={errors}
          setValue={setValue}
          roles={roles}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          render={isSucceed}
        />
        <BankInfo
          userSetup={userSetup}
          register={register}
          errors={errors}
          setValue={setValue}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
    </div>
       </div>
        <div className="flex justify-center pt-8">
          <p className="addWrap">
            <SubmitButton
              btnText={!loading ? "Add User" : "Adding..."}
              loading={loading}
              height="h-12"
            />
          </p>
          <p className="grayWrap">
            <GrayButton
              btnText="Clear"
              width="w-40"
              onClick={handleClearForm}
            />
          </p>
        </div>
      </form>
      {isSucceed && (
        <UserViewDetailsDialog
          closeDialog={() => navigate("/user/all")}
          modalNotClose={() => setIsSucceed(true)}
          viewDetails={responseData}
          successView={true}
          message="User Successfully Created!"
          messageIcon={successIcon}
        />
      )}
    </div>
  );
};

export default AddUser;
