import React from "react";
import loginImage from "../../assets/images/login-image.svg";

const LoginHeader = () => {
  return (
    <div className="login-header">
      <img src={loginImage} alt="loginImg" className="login-header--img" />
    </div>
  );
};
export default LoginHeader;