export function siteCategoryColor(stateValue) {
  const colorsMap = {
    Pending: "text-yellow",
    Installation: "text-sec-blue",
    Testing: "text-green",
    Deactivated: "text-lg3",
  };

  return colorsMap[stateValue] || "";
}
