import React from "react";
import { useSelector } from "react-redux";
import { uploadSheetHeadList } from "../../utils/TableHeadList";
import TableHead from "../UI/TableUI/TableHead";
import { formatDate } from "../../functions/dateTime/formatDate";
import xlsIcon from "../../assets/icons/xlsIcon.svg";
import NoDataDisplay from "../UI/NoDataDisplay";

const ListTableContent = ({ handleDownloadSheet, handleSheetView }) => {
  const data = useSelector((state) => state.data.data);
  const isDataAvailable = data.length > 0;

  return (
    <div className={`table-container table-height`}>
      <table className={`table ${!isDataAvailable ? "relative" : ""}`}>
        <tbody>
          <TableHead headList={uploadSheetHeadList} />
          {isDataAvailable ? (
            <>
              {data.map((data, index) => {
                return (
                  <tr className="table-row" key={index}>
                    <td className="p-3">{index + 1}</td>
                    <td className="p-3">
                      <span className="lg text-lg3">
                        {data.createdBy.fullName}
                      </span>
                    </td>
                    <td className="p-3">{formatDate(data.createdAt)}</td>
                    <td className="p-3">
                      <img
                        src={xlsIcon}
                        alt="xls"
                        className="mx-auto cursor-pointer"
                        onClick={() => handleDownloadSheet(data.url)}
                      />
                    </td>
                    <td className="p-3">
                      <button
                        className="tableViewBtn"
                        onClick={() => handleSheetView(data.id)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                );
              })}
            </>
          ) : (
            <NoDataDisplay />
          )}
        </tbody>
      </table>
    </div>
  );
};
export default ListTableContent;