import React, { useState } from "react";
import JustifiedHeading from "../UI/headingsUI/JustifiedHeading";
import ListTableContent from "./ListTableContent";
import { uploadSheetSearch } from "../../utils/tableSearchList";
import useImageUrl from "../../CustomHooks/useImageUrl";
import useGetData from "../../CustomHooks/useAxios/useGetData";
import { SINGLE_UPLOADED_SHEET } from "../../api/allApi_List";
import SheetView from "../UI/viewDialogs/expense/SheetView";
import DialogBox from "../UI/DialogBox";
import { handleErrMessage } from "../../functions/handleErrMessage";
import FilterSearch from "../FilterSearch";
import UsePermissionsClaim from "../../CustomHooks/UsePermissionsClaim";

const UploadedSheetList = ({ sheetsData, navigate }) => {
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const { data, isLoaded, error, getData, setIsLoaded, errMsg, setData } =
    useGetData();
  const { getImageUrl } = useImageUrl();

  const [copyData] = useState(sheetsData.slice());
  const isCopyData = copyData.length > 0;
  const { checkPermission } = UsePermissionsClaim();
  const permAddSheet = checkPermission("add_sheets");

  const handleDownloadSheet = (sheetUrl) => {
    const urlSheet = getImageUrl(sheetUrl);
    // Validate image URL (optional)
    if (!urlSheet || !urlSheet.startsWith("https")) {
      console.error(
        'Invalid image URL. Please provide a valid URL starting with "http" or "https".'
      );
      return;
    }

    // Create anchor element and set attributes
    const link = document.createElement("a");
    link.href = urlSheet;
    link.download = "urlSheet.split(" / ").pop()"; // Get filename from URL
    link.click();
  };

  const handleSheetView = (dataId) => {
    setViewDialogOpen(true);
    getData(SINGLE_UPLOADED_SHEET + dataId, navigate);
  };

  const handleCloseViewDialog = () => {
    setIsLoaded(false);
    setViewDialogOpen(false);
    setData({});
  };
  return (
    <>
      {/* <JustifiedHeading
        title="Uploaded Expense Sheets"
        navigate={"/sheet/add"}
        btnText={permAddSheet ? "Upload Sheet" : undefined}
      /> */}
      {isCopyData ? (
        <FilterSearch
          searchCategories={uploadSheetSearch}
          addBtnNavigation={"/sheet/add"}
          addBtnText={permAddSheet ? "Upload Sheet" : undefined}
          isCopyData={isCopyData}
        />
      ) : (
        ""
      )}
      <ListTableContent
        handleDownloadSheet={handleDownloadSheet}
        handleSheetView={handleSheetView}
      />
      {viewDialogOpen && !error ? (
        <SheetView
          viewDetails={data}
          isLoaded={isLoaded}
          error={error}
          closeDialog={handleCloseViewDialog}
        />
      ) : error ? (
        <DialogBox title="Error" message={handleErrMessage(errMsg)} />
      ) : (
        ""
      )}
    </>
  );
};

export default UploadedSheetList;
