import React, { useState } from "react";
import dotsIcon from "../../assets/icons/dotsIcon.svg";
import { Link } from "react-router-dom";
import useClickOutside from "../../CustomHooks/useClickOutside";
import addLoan from "../../assets/icons/loanadd.svg";
import loanRepayicon from "../../assets/icons/loanRepayicon.svg";
import deductIcon from "../../assets/icons/deductIcon.svg";
import updateUserIcon from "../../assets/icons/updateUser.svg";
import UsePermissionsClaim from "../../CustomHooks/UsePermissionsClaim";

function UserActions({ userData }) {
  const [open, setOpen] = useState(-1);
  const { dropdownRef } = useClickOutside(() => setOpen(-1));
  const { checkPermission } = UsePermissionsClaim();
  const addLoanPerm = checkPermission("add_loan");
  const addLoanRepaymentPerm = checkPermission("add_loan_expense");
  const addDeductionPerm = checkPermission("add_deduction_expense");
  const updateUser = checkPermission("update_user");
  const { id, fullName, loanBalance } = userData;

  const handleShow = () => {
    setOpen((prevIndex) => (prevIndex === 1 ? -1 : 1));
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <img
        src={dotsIcon}
        alt="dotsIcon"
        className="w-25"
        onClick={handleShow}
      />
      {open === 1 && (
        <div className={`actionsWrap--u ${open === 1 ? "grid" : "invisible"}`}>
          {addLoanPerm && (
            <Link
              className="actionLink--u"
              to={`/expense/add-loan/${id}/${fullName}/${loanBalance}`}
              state={{
                state: { name: fullName, userId: id, loanBalance: loanBalance },
              }}
            >
              <img src={addLoan} alt="addLoan" className="w-6" />
              <span className="actionLinkText--u">Add Loan</span>
            </Link>
          )}
          {addLoanRepaymentPerm && (
            <Link
              className="actionLink--u"
              to={`/expense/add-loan-repayment/${id}/${fullName}/${loanBalance}`}
              state={{
                state: { name: fullName, userId: id, loanBalance: loanBalance },
              }}
            >
              <img src={loanRepayicon} alt="loanRepayicon" className="w-6" />
              <span className="actionLinkText--u"> Add Loan Repayment</span>
            </Link>
          )}
          {addDeductionPerm && (
            <Link
              className="actionLink--u"
              to={`/expense/add-deduction/${id}/${fullName}`}
              state={{
                state: { name: fullName, userId: id },
              }}
            >
              <img src={deductIcon} alt="deductIcon" className="w-6" />
              <span className="actionLinkText--u">Add Deduction</span>
            </Link>
          )}
          {updateUser && (
            <Link
              className="actionLink--u"
              to={`/user/update/${id}`}
              state={{
                state: userData,
              }}
            >
              <img src={updateUserIcon} alt="updateUserIcon" className="w-6" />
              <span className="actionLinkText--u">Update User</span>
            </Link>
          )}
        </div>
      )}
    </div>
  );
}
export default UserActions;