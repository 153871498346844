import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateData } from "../../redux/dataSlice";
import reset from "../../assets/icons/reset.svg";
import LeftIconButton from "../UI/buttonUI/LeftIconButton";
import { setIsFiltered, setFilteredData } from "../../redux/isTableFilterSlice";

const FilterReset = ({ setSelectedItems, setCheckboxes }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data.data);
  const [originalData] = useState(data.slice());

  const filterReset = () => {
    dispatch(updateData(originalData));
    setSelectedItems({});
    setCheckboxes({});
    document.querySelectorAll('input[type="checkbox"]');
    var checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach(function (checkbox) {
      checkbox.checked = false;
    });
    // isFiltered slice states
    dispatch(setIsFiltered(false));
    dispatch(setFilteredData([]));
    localStorage.removeItem("isCheckData");
    localStorage.removeItem("filterKeys");
    localStorage.removeItem("filterCheckBoxes");
  };

  return (
    <LeftIconButton
      text="Reset"
      textColor="text-dark-blue font-figtreeMedium"
      bgColor="bg-lg5 border-lg5"
      icon={reset}
      iconStyle="W-4 2xs:max-md:w-3"
      margin="ml-1 mr-2"
      hover="hover:bg-transparent hover:border-dark-blue"
      onClick={filterReset}
    />
  );
};

export default FilterReset;
