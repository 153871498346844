export const prepareAddUserFormData = (formValues, files) => {
  const formData = new FormData();
  Object.entries(formValues).forEach(([key, value]) => {
    // Skip profile_pic and id_proof
    if (key === "profile_pic" || key === "id_proof") {
      return;
    }
    // Handle array values
    if (Array.isArray(value)) {
      value.forEach((item) => formData.append(key, item));
    } else if (value !== "" && value !== null && value !== undefined) {
      // Append only non-empty, non-null, and defined values
      formData.append(key, value);
    }
  });
  Object.entries(files).forEach(([key, val]) => {
    // Check if val is an array and not empty
    if (Array.isArray(val) && val.length !== 0) {
      val.forEach((i) => {
        formData.append(key, i);
      });
    }
  });
  return formData;
};
