import { useState } from "react";
import axios from "axios";

const useCheckData = (setRender, method, setIsLoaded) => {
  const [confirmDialogBox, setConfirmDialogBox] = useState(false);
  const [apiEndPoint, setApiEndPoint] = useState("");
  const [buttonStatus, setButtonStatus] = useState();
  const [bodyData, setBodyData] = useState({});
  const [isActionSucceed, setIsActionSucceed] = useState(false);
  const [checkResponseData, setCheckResponseData] = useState([]);
  const [checkLoading, setCheckLoading] = useState(false);
  const [checkedErr, setCheckedErr] = useState(false);
  const [checkedErrMsg, setCheckedErrMsg] = useState("");

  const handleCheck = async () => {
    let config = {
      method: method,
      url: apiEndPoint,
      data: bodyData,
    };
    setCheckLoading(true);
    await axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setCheckResponseData(response.data);
        setIsActionSucceed(true);
        setConfirmDialogBox(false);
        setCheckLoading(false);
        setRender((prev) => !prev);
        setIsLoaded(false)
        localStorage.setItem("isCheckData", true);
      })
      .catch((error) => {
        console.log(error);
        setCheckedErr(true);
        setConfirmDialogBox(false);
        setCheckLoading(false);
        setCheckedErrMsg(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
      });
  };
  return {
    confirmDialogBox,
    setConfirmDialogBox,
    setApiEndPoint,
    setBodyData,
    isActionSucceed,
    setIsActionSucceed,
    buttonStatus,
    setButtonStatus,
    handleCheck,
    checkResponseData,
    checkedErr,
    checkedErrMsg,
    setCheckedErr,
    checkLoading,
  };
};

export default useCheckData;
