export const allExpenseHeadList = [
  { title: "SN" },
  {
    title: "User",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["user", "fullName"],
  },
  { title: "Amount", sortArrow: true, sortBy: "number", address: ["amount"] },
  { title: "Description" },
  { title: "Date", sortArrow: true, sortBy: "date", address: ["date"] },
  {
    title: "Category",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["category", "name"],
  },
  { title: "Status", sortArrow: true, sortBy: "number", address: ["state"] },
  { title: "Action" },
];

export const pendingExpenseHeadList = [
  { title: "SN" },
  {
    title: "User",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["user", "fullName"],
  },
  { title: "Amount", sortArrow: true, sortBy: "number", address: ["amount"] },
  { title: "Description" },
  { title: "Date", sortArrow: true, sortBy: "date", address: ["date"] },
  {
    title: "Category",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["category", "name"],
  },
  {
    title: "Status",
  },
  { title: "Accept/Reject" },
  { title: "Action" },
];

export const checkHistoryList = [
  { title: "SN" },
  {
    title: "Name",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["user", "fullName"],
  },
  { title: "Amount", sortArrow: true, sortBy: "number", address: ["amount"] },
  { title: "Description" },
  { title: "Date", sortArrow: true, sortBy: "date", address: ["date"] },
  {
    title: "Category",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["category", "name"],
  },
  { title: "Status", sortArrow: true, sortBy: "status", address: ["state"] },
  { title: "Action" },
];

export const loansHeadList = [
  { title: "SN" },
  {
    title: "User",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["fullName"],
  },
  {
    title: "Amount",
    sortArrow: true,
    sortBy: "number",
    address: ["loanBalance"],
  },
  { title: "Supervisor" },
  { title: "Action" },
  { title: "History" },
];

export const sitesHeadList = [
  {
    title: "Site ID",
    sortArrow: true,
    sortBy: "number",
    address: ["id"],
  },
  {
    title: "Site Name",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["name"],
  },
  {
    title: "Site Engineer",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["jbccFieldTechnician", "fullName"],
  },
  {
    title: "Subdivision",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["subDivisionName"],
  },
  {
    title: "Automation",
  },
  {
    title: "Category",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["category", "name"],
  },
  { title: "Action" },
];

export const visitsHeadList = [
  {
    title: "Site ID",
    sortArrow: true,
    sortBy: "number",
    address: ["site", "id"],
  },
  {
    title: "Site Name",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["site", "name"],
  },
  {
    title: "Operator Phone",
  },
  { title: "Date", sortArrow: true, sortBy: "date", address: ["visitedAt"] },
  {
    title: "Field Technician",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["fieldTechnician", "fullName"],
  },
  {
    title: "Category",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["category", "name"],
  },
  { title: "Work Done" },
  { title: "Status", sortArrow: true, sortBy: "number", address: ["status"] },
  {
    title: "Action",
  },
];

export const visitPendingHeadList = [
  {
    title: "Site ID",
    sortArrow: true,
    sortBy: "number",
    address: ["site", "id"],
  },
  {
    title: "Site Name",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["site", "name"],
  },
  {
    title: "Operator Phone",
  },
  { title: "Date", sortArrow: true, sortBy: "date", address: ["visitedAt"] },
  {
    title: "Field Technician",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["fieldTechnician", "fullName"],
  },
  {
    title: "Category",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["category", "name"],
  },
  { title: "Work Done" },
  { title: "Action" },
];

export const complaintsHeadList = [
  { title: "ID", sortArrow: true, sortBy: "number", address: ["id"] },
  {
    title: "Site Name",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["station", "name"],
  },
  {
    title: "Field Technician",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["fieldTechnician", "fullName"],
  },
  {
    title: "Created At",
    sortArrow: true,
    sortBy: "date",
    address: ["createdAt"],
  },
  { title: "Description" },
  {
    title: "Category",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["category", "name"],
  },
  { title: "Status", sortArrow: true, sortBy: "number", address: ["status"] },
  { title: "Visit" },
  { title: "Action" },
];
export const complaintPendingHeadList = [
  { title: "ID", sortArrow: true, sortBy: "number", address: ["id"] },
  {
    title: "Site Name",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["station", "name"],
  },
  {
    title: "Field Technician",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["fieldTechnician", "fullName"],
  },
  {
    title: "Created At",
    sortArrow: true,
    sortBy: "date",
    address: ["createdAt"],
  },
  { title: "Description" },
  {
    title: "Category",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["category", "name"],
  },
  { title: "Status", sortArrow: true, sortBy: "number", address: ["status"] },
  { title: "Visit" },
  { title: "Action" },
];

export const usersHeadList = [
  { title: "SN" },
  {
    title: "Name",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["fullName"],
  },
  {
    title: "Loan Balance",
    sortArrow: true,
    sortBy: "number",
    address: ["loanBalance"],
  },
  {
    title: "Pending Balance",
    sortArrow: true,
    sortBy: "number",
    address: ["totalBalance"],
  },
  {
    title: "Total Balance",
    sortArrow: true,
    sortBy: "number",
    address: ["totalBalance"],
  },
  { title: "View" },
  { title: "" },
];

export const uploadSheetHeadList = [
  { title: "SN" },
  {
    title: "Uploaded By",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["createdBy", "fullName"],
  },
  {
    title: "Uploaded At",
    sortArrow: true,
    sortBy: "date",
    address: ["createdAt"],
  },
  {
    title: "Download",
  },
  { title: "View" },
];

export const machinesHeadList = [
  {
    title: "Machine ID",
    sortArrow: true,
    sortBy: "number",
    address: ["id"],
  },
  {
    title: "Site Name",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["station", "name"],
  },
  {
    title: "Category",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["category", "name"],
  },
  {
    title: "Automation",
  },
  { title: "Action" },
];

export const officersHeadList = [
  { title: "SN" },
  {
    title: "Officer ID",
    sortArrow: true,
    sortBy: "number",
    address: ["id"],
  },
  {
    title: "Officer Name",
    sortArrow: true,
    sortBy: "alphabetically",
    address: ["fullName"],
  },
  {
    title: "Created At",
    sortArrow: true,
    sortBy: "date",
    address: ["createdAt"],
  },
];
