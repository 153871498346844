import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { COMPLAINT_SETUP } from "../../api/allApi_List";
import AddComplaint from "../../components/complaint/add/AddComplaint";
import useGetData from "../../CustomHooks/useAxios/useGetData";
import DialogBox from "../../components/UI/DialogBox";
import spin from "../../assets/icons/spin.png";
import { handleErrMessage } from "../../functions/handleErrMessage";
import { ADD_COMPLAINT } from "../../api/allApi_List";
import usePostData from "../../CustomHooks/useAxios/usePostData";
import MainPageLayout from "../../components/MainPageLayout";
import { prepareFormData } from "../../functions/prepareFormData";

const AddComplaintPage = () => {
  const [files, setFiles] = useState({});
  const navigate = useNavigate();
  const { data, isLoaded, error, getData, errMsg } = useGetData();
  const {
    executePost,
    loading,
    isSucceed,
    formError,
    formErrMsg,
    responseData,
    setIsSucceed,
    setFormError,
    setResponseData,
  } = usePostData();

  useEffect(() => {
    getData(COMPLAINT_SETUP, navigate);
  }, []);

  const sendData = async (formValues, reset, setSelectedOption) => {
    // send data into form data
    const formData = prepareFormData(formValues, files);
    const updateStateOnResponse = () => {
      setSelectedOption({});
      setFiles({});
      // reset form value
      reset();
    };
    //API calling
    executePost(formData, ADD_COMPLAINT, updateStateOnResponse);
  };
  return (
    <MainPageLayout headerConfig={{ title: "Add Complaint" }}>
      {isLoaded && !error ? (
        <AddComplaint
          setIsSucceed={setIsSucceed}
          isSucceed={isSucceed}
          files={files}
          setFiles={setFiles}
          complaintSetup={data}
          loading={loading}
          sendData={sendData}
          navigate={navigate}
          responseData={responseData}
          setResponseData={setResponseData}
        />
      ) : error ? (
        <DialogBox
          title="Error"
          message={handleErrMessage(errMsg)}
          isErrorHandleButtons={true}
        />
      ) : (
        <DialogBox message="Loading your data" animateIcon={spin} />
      )}
      {formError && (
        <DialogBox
          title="Error"
          btnText="Ok"
          message={handleErrMessage(formErrMsg)}
          closeAction={() => setFormError(false)}
        />
      )}
    </MainPageLayout>
  );
};

export default AddComplaintPage;
