import React, { useState } from "react";
import { useForm } from "react-hook-form";

import CenteredHeading from "../UI/headingsUI/CenteredHeading";
import SubmitButton from "../UI/buttonUI/SubmitButton";
import GrayButton from "../UI/buttonUI/GrayButton";
import SearchDropdown from "../UI/PageInput/SearchDropdown";
import successIcon from "../../assets/icons/successIcon.svg";
import MachineViewDialog from "../UI/viewDialogs/site/MachineViewDialog";

const AddMachine = ({
  machineSetup,
  sendData,
  setIsSucceed,
  isSucceed,
  loading,
  responseData,
  navigate,
  setResponseData,
}) => {
  const [selectedOption, setSelectedOption] = useState({});

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    sendData(data, reset, setSelectedOption);
  };

  // handle clear form
  const handleClearForm = () => {
    setSelectedOption({});
    // reset form value
    reset();
  };
  return (
    <>
      <div className="form-container">
        <CenteredHeading title="Add Machine" />
        <form
          onSubmit={!loading ? (e) => handleSubmit(onSubmit)(e) : undefined}
        >
          <div className="flex flex-wrap">
            <div className="leftCol mx-auto pr-0">
              <SearchDropdown
                name="categoryId"
                label="Category *"
                keyName="name"
                id="id"
                options={machineSetup.categories}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
                setValue={setValue}
                register={{
                  ...register("categoryId", {
                    required: "Please select a Field Technician (JBCC)",
                  }),
                }}
                error={errors.categoryId}
              />
            </div>
          </div>
          <div className="addBtn---wrap ">
            <p className="addWrap">
              <SubmitButton
                btnText={!loading ? "Add Machine" : "Adding..."}
                loading={loading}
                height="h-12"
              />
            </p>
            <p className="grayWrap">
              <GrayButton
                btnText="Clear"
                width="w-40"
                onClick={handleClearForm}
              />
            </p>
          </div>
        </form>
      </div>
      {isSucceed && (
        <MachineViewDialog
          closeDialog={() => navigate("/site/all-machine")}
          modalNotClose={() => setIsSucceed(true)}
          viewDetails={responseData}
          successView={true}
          message="Machine Successfully Added!"
          messageIcon={successIcon}
          setMachineViewDetails={setResponseData}
        />
      )}
    </>
  );
};
export default AddMachine;
