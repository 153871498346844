import React from "react";
import upArrow from "../../../assets/icons/upArrow.svg";

const CustomFileInput = ({
  name,
  placeholder,
  accept,
  register,
  setValue,
  multiple,
  error,
}) => {
  const clickHandle = () => {
    // Set the value of the input to empty on each onclick event
    setValue(name, "");
  };
  return (
    <>
      <div
        className={`rounded-md border-bw flex justify-between items-center h-45px mt-6 2xs:max-sm:mt-3 relative ${
          error ? "border-red-500" : "border-lg4"
        }`}
      >
        <p className="pl-2 text-xs 2xs:max-base:text-xs">{placeholder}</p>
        <input
          type="file"
          name={name}
          className="w-108 opacity-0 z-55"
          accept={accept}
          {...register}
          onClick={setValue ? clickHandle : undefined}
          multiple={multiple}
        />
        <p className="flex absolute right-1 items-center border-1 border-dark-blue rounded-md text-xs p-2 px-3 cursor-pointer">
          <img src={upArrow} alt="upArrow" />
          <span className="pl-2">Choose File</span>
        </p>
      </div>
      <p
          className={`text-red-500 font-figtree text-xs h-4 ${
            error ? "visible" : "invisible"
          }`}
        >
          {error?.message}
        </p>
    </>
  );
};

export default CustomFileInput;
