import { Routes, Route, Navigate } from "react-router-dom";
import { SuperTokensWrapper } from "supertokens-auth-react";
import { PermissionClaim } from "supertokens-auth-react/recipe/userroles";
import { SessionAuth } from "supertokens-auth-react/recipe/session";

import "./App.css";
import LoginPage from "./pages/auth/LoginPage";
import HomePage from "./pages/home/HomePage";
import AddExpensePage from "./pages/expense/AddExpensePage";
import ExpensePage from "./pages/expense/ExpensePage";
import ExpenseHistoryPage from "./pages/expense/ExpenseHistoryPage";
import ReceiverPendingPage from "./pages/expense/ReceiverPendingPage";
import SupervisorPendingPage from "./pages/expense/SupervisorPendingPage";
import AdminPendingPage from "./pages/expense/AdminPendingPage";
import SuperAdminPendingPage from "./pages/expense/SuperAdminPendingPage";
import ViewProfile from "./pages/user/ViewProfile";
import AddLoanRepaymentPage from "./pages/expense/AddLoanRepaymentPage";
import AddLoanPage from "./pages/expense/AddLoanPage";
import SitesPage from "./pages/site/SitesPage";
import ComplaintsPage from "./pages/complaint/ComplaintsPage";
import AddComplaintPage from "./pages/complaint/AddComplaintPage";
import UsersPage from "./pages/user/UsersPage";
import UpdateUserPage from "./pages/user/UpdateUserPage";
import VisitPendingPage from "./pages/complaint/VisitPendingPage";
import ComplaintPendingPage from "./pages/complaint/ComplaintPendingPage";
import AddDeductionExpensePage from "./pages/expense/AddDeductionExpensePage";
import AddUserPage from "./pages/user/AddUserPage";
import AddOfficerPage from "./pages/user/AddOfficerPage";
import OfficersPage from "./pages/user/OfficersPage";
import AddJurisdictionPage from "./pages/site/AddJurisdictionPage";
import AddSitePage from "./pages/site/AddSitePage";
import AddMachinePage from "./pages/site/AddMachinePage";
import MachinesPage from "./pages/site/MachinesPage";
import AddMachineComponentPage from "./pages/site/AddMachineComponentPage";
import PageNotFound from "./components/PageNotFound";
import UploadSheetPage from "./pages/expenseSheets/UploadSheetPage";
import UploadedSheetsListPage from "./pages/expenseSheets/UploadedSheetsListPage";
import ChangePasswordPage from "./pages/auth/ChangePasswordPage";
import VisitsPage from "./pages/complaint/VisitsPage";
import AssignMachinePage from "./pages/site/AssignMachinePage";
import { getSuperTokenInit } from "./functions/getSuperTokenInit";

getSuperTokenInit();

function App() {
  const protectedRoute = (element, claims) => (
    <SessionAuth
      accessDeniedScreen={PageNotFound}
      overrideGlobalClaimValidators={(globalValidators) => [
        ...globalValidators,
       PermissionClaim.validators.includesAny(claims),
      ]}
    >
      {element}
    </SessionAuth>
  );

  return (
    <SuperTokensWrapper>
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/home" element={<SessionAuth><HomePage /></SessionAuth>} />
        <Route path="/profile" element={<SessionAuth><ViewProfile /></SessionAuth>} />
        <Route path="/user/all" element={protectedRoute(<UsersPage />, ["view_user"])} />
        <Route path="/user/add" element={protectedRoute(<AddUserPage />, ["add_user"])} />
        <Route path="/user/update/:id" element={protectedRoute(<UpdateUserPage />, ["update_user"])} />
        <Route path="/user/add-officer" element={protectedRoute(<AddOfficerPage />, ["add_officer"])} />
        <Route path="/officer/all" element={protectedRoute(<OfficersPage />, ["add_officer"])} />
        <Route path="/expense/add" element={protectedRoute(<AddExpensePage />, ["add_expense"])} />
        <Route path="/expense/all" element={<SessionAuth><ExpensePage /></SessionAuth>} />
        <Route path="/expense/history" element={protectedRoute(<ExpenseHistoryPage />, ["sup_check", "sup_admin_check", "admin_check"])} />
        <Route path="/expense/receiver-pending" element={<SessionAuth><ReceiverPendingPage /></SessionAuth>} />
        <Route path="/expense/supervisor-pending" element={protectedRoute(<SupervisorPendingPage />, ["sup_check"])} />
        <Route path="/expense/admin-pending" element={protectedRoute(<AdminPendingPage />, ["admin_check"])} />
        <Route path="/expense/super-admin-pending" element={protectedRoute(<SuperAdminPendingPage />, ["sup_admin_check"])} />
        <Route path="/expense/add-loan/:id/:name/:loanBalance" element={protectedRoute(<AddLoanPage />, ["add_loan"])} />
        <Route path="/expense/add-loan-repayment/:id/:name/:loanBalance" element={protectedRoute(<AddLoanRepaymentPage />, ["add_loan_expense"])} />
        <Route path="/expense/add-deduction/:id/:name" element={protectedRoute(<AddDeductionExpensePage />, ["add_deduction_expense"])} />
        <Route path="/sheet/add" element={protectedRoute(<UploadSheetPage />, ["add_sheets"])} />
        <Route path="/sheet/uploaded-all" element={protectedRoute(<UploadedSheetsListPage />, ["view_sheets"])} />
        <Route path="/complaint/visit-pending" element={protectedRoute(<VisitPendingPage />, ["check_visit"])} />
        <Route path="/complaint/visit-all" element={protectedRoute(<VisitsPage />, ["view_visit"])} />
        <Route path="/complaint/all" element={protectedRoute(<ComplaintsPage />, ["view_complaint"])} />
        <Route path="/complaint/pending" element={protectedRoute(<ComplaintPendingPage />, ["resolve_complaint"])} />
        <Route path="/complaint/add" element={protectedRoute(<AddComplaintPage />, ["add_complaint"])} />
        <Route path="/site/all" element={protectedRoute(<SitesPage />, ["view_site"])} />
        <Route path="/site/add-jurisdiction" element={protectedRoute(<AddJurisdictionPage />, ["add_hier"])} />
        <Route path="/site/add" element={protectedRoute(<AddSitePage />, ["add_site"])} />
        <Route path="/site/add-machine" element={protectedRoute(<AddMachinePage />, ["add_machine"])} />
        <Route path="/site/assign-machine" element={protectedRoute(<AssignMachinePage />, ["add_machine"])} />
        <Route path="/site/all-machine" element={protectedRoute(<MachinesPage />, ["view_machine"])} />
        <Route path="/site/add-machine-component" element={protectedRoute(<AddMachineComponentPage />, ["add_machine_component"])} />
        <Route path="/change-password" element={<SessionAuth><ChangePasswordPage /></SessionAuth>} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </SuperTokensWrapper>
  );
}
export default App;