import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import CustomDescriptionInput from "../UI/PageInput/CustomDescriptionInput";
import AddButton from "../UI/buttonUI/AddButton";
import GrayButton from "../UI/buttonUI/GrayButton";
import StatusMessage from "./StatusMessage";
import usePostData from "../../CustomHooks/useAxios/usePostData";

const AddRemarkForm = ({
  dataId,
  apiUrl,
  updateStateOnAddRemark,
  setViewDetails,
  showAddRemark,
  setShowAddRemark,
  setOpenRemarkList,
}) => {
  const [remarkData, setRemarkData] = useState({ id: dataId, remark: "" });
  const {
    executePost,
    loading,
    isSucceed,
    formError,
    formErrMsg,
    responseData,
    setIsSucceed,
  } = usePostData();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    watch((value) => {
      setRemarkData((prev) => {
        return { ...prev, ...value };
      });
    });
  }, [watch]);

  const updateStateOnResponse = () => {
    setRemarkData({ id: dataId, remark: "" });
    if (updateStateOnAddRemark) {
      updateStateOnAddRemark();
    }
    setShowAddRemark(false);
    setOpenRemarkList(true);
    // reset form value
    reset();
  };

  const clearForm = () => {
    setRemarkData({ id: dataId, remark: "" });
    reset();
  };

  const onSubmit = () => {
    //API calling
    executePost(remarkData, apiUrl, updateStateOnResponse);
  };

  useEffect(() => {
    const a = () => {
      setIsSucceed(false);
    };
    isSucceed && setTimeout(a, 500);
  }, [isSucceed]);

  // expense view dialog list updated when remark successfully added
  if (isSucceed) {
    setViewDetails(responseData);
  }
  return (
    <>
      <StatusMessage
        isSucceed={isSucceed}
        formError={formError}
        formErrMsg={formErrMsg}
      />
      {showAddRemark ? (
        <div className="remark-form-wrap">
          <form>
            <CustomDescriptionInput
              name="remark"
              labelText="Remark *"
              register={{
                ...register("remark", {
                  required: "Please Enter Remark",
                }),
              }}
              error={errors.remark}
            />
          </form>
          <div className="remark-form-btn-wrap">
            <p className="pr-1 2xs:max-sm:pr-1">
              <AddButton
                btnText={!loading ? "Add Remark" : "Adding..."}
                onClick={!loading ? handleSubmit(onSubmit) : undefined}
                loading={loading}
                height="h-9"
                textSize="text-xs"
                padding="px-2"
              />
            </p>
            <p className="pl-1 h-full 2xs:max-sm:pl-1">
              <GrayButton
                btnText="Clear"
                width="w-20"
                height="h-9"
                textSize="text-xs"
                onClick={clearForm}
              />
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default AddRemarkForm;
