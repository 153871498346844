import React from "react";
import useImageUrl from "../../../CustomHooks/useImageUrl";

const PictureList = ({
  pictureList,
  openImageSlider,
  setImageSliderCurrentIndex,
}) => {
  const { getImageUrl } = useImageUrl();

  const handleImageSlider = (index) => {
    setImageSliderCurrentIndex(index);
    openImageSlider(pictureList);
  };

  return (
    <div className="pictureListWrap">
      <h3 className="uploadImgTxt">
        Uploaded Images
        {pictureList && (
          <span className="text-xs">{` (${pictureList.length})`}</span>
        )}
      </h3>
      <div
        className={`flex flex-wrap align-top ${
          pictureList.length > 10 ? "h-40 overflow-auto" : ""
        }`}
      >
        {pictureList.map((pic, index) => {
          return (
            <div className="uploadImg" key={index}>
              <img
                src={getImageUrl(pic)}
                alt="expensePic"
                className="p-1 cursor-pointer w-full h-full"
                key={index}
                onClick={() => handleImageSlider(index)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default PictureList;
