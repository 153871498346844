export function getDropdownOptionsData(originalData, keysArr) {
    let objects = [];
    const seenIds = new Set();
    keysArr.val.forEach((valArray, index) => {
      const labelArray = keysArr.lab[index];
      originalData.forEach((data) => {
        let value = valArray.reduce((obj, key) => obj && obj[key], data);
        let label = labelArray.reduce((obj, key) => obj && obj[key], data);
        if (value !== undefined && label !== null && !seenIds.has(value)) {
          objects.push({ value: value, label: label });
          seenIds.add(value);
        }
      });
    });
    return objects;
  }
  