import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { EXPENSE_SETUP } from "../../api/allApi_List";
import AddExpense from "../../components/expense/add/AddExpense";
import useGetData from "../../CustomHooks/useAxios/useGetData";
import DialogBox from "../../components/UI/DialogBox";
import spin from "../../assets/icons/spin.png";
import { handleErrMessage } from "../../functions/handleErrMessage";
import { Add_EXPENSE } from "../../api/allApi_List";
import usePostData from "../../CustomHooks/useAxios/usePostData";
import MainPageLayout from "../../components/MainPageLayout";

const AddExpensePage = () => {
  const userId = localStorage.getItem("userId");
  const [expenseDetail, setExpenseDetail] = useState({
    user_id: userId,
  });
  const [files, setFiles] = useState({});
  const navigate = useNavigate();
  const { data, isLoaded, error, getData, errMsg } = useGetData();
  const {
    executePost,
    loading,
    isSucceed,
    formError,
    formErrMsg,
    responseData,
    setIsSucceed,
    setFormError,
    setResponseData,
  } = usePostData();

  useEffect(() => {
      getData(EXPENSE_SETUP, navigate);
  }, []);

  // execute post api
  const sendData = async (reset, setSelectedOption) => {
    // send data into form data
    const formData = new FormData();
    for (const key in expenseDetail) {
      formData.append(key, expenseDetail[key]);
    }
    for (const key in files) {
      files[key].forEach((i, ind) => {
        formData.append(key, i);
      });
    }
    const updateStateOnResponse = () => {
      setExpenseDetail({
        user_id: userId,
      });
      setSelectedOption({});
      setFiles({});
      // reset form value
      reset();
    };
    //API calling
    executePost(formData, Add_EXPENSE, updateStateOnResponse);
  };

  return (
    <MainPageLayout headerConfig={{title:"Add Expense"}}>
      {isLoaded && !error ? (
        <AddExpense
          files={files}
          setFiles={setFiles}
          sendData={sendData}
          isSucceed={isSucceed}
          setExpenseDetail={setExpenseDetail}
          expenseDetail={expenseDetail}
          expenseSetup={data}
          loading={loading}
          navigate={navigate}
          setIsSucceed={setIsSucceed}
          responseData={responseData}
          setResponseData={setResponseData}
        />
      ) : error ? (
        <DialogBox
          title="Error"
          message={handleErrMessage(errMsg)}
          isErrorHandleButtons={true}
        />
      ) : (
        <DialogBox message="Loading your data" animateIcon={spin} />
      )}
      {formError && (
        <DialogBox
          title="Error"
          btnText="Ok"
          message={handleErrMessage(formErrMsg)}
          closeAction={() => setFormError(false)}
        />
      )}
    </MainPageLayout>
  );
};
export default AddExpensePage;