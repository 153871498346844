const getNestedProperty = (data, namesAddress, categoryKey) => {
  let result = [];
  namesAddress.forEach((keys) => {
    let keysVal = keys.reduce((obj, key) => obj && obj[key], data);
    keysVal = categoryKey === "flags" ? keysVal + "_" + keys[0] : keysVal;
    result.push(keysVal);
  });
  return result;
};

const filterSpecification = (item, selectedItems) => {
  if (!item) {
    return false; // If item is null, return false
  }

  const isSatisfied = Object.keys(selectedItems).map((key) => {
    const selectedVal = selectedItems[key]["val"];
    if (selectedVal.length > 0) {
      const nestedProperty = getNestedProperty(
        item,
        selectedItems[key]["address"],
        key
      );
      return nestedProperty.some(
        (i) =>
          i !== null &&
          i !== undefined &&
          selectedVal.includes(i.toString().toLowerCase())
      );
    }
    return true; // If there is no selection, we treat it as satisfied.
  });

  return isSatisfied.every(Boolean);
};

export const betterFilter = (data, selectedItems) => {
  return data.filter((item) => filterSpecification(item, selectedItems));
};
