import { useSelector } from "react-redux";
import { setTableHeight } from "../../../functions/setTableHeight";
import { complaintPendingHeadList } from "../../../utils/TableHeadList";
import { formatDate } from "../../../functions/dateTime/formatDate";
import { getComplaintStatus } from "../../../functions/getComplaintStatus";
import NoDataDisplay from "../../UI/NoDataDisplay";
import sortDataByDate from "../../../functions/sorting/sortDataByDate";
import TableHead from "../../UI/TableUI/TableHead";
import GetCheckCross from "../../UI/viewDialogs/GetCheckCross";

const TableComplaintContent = ({ openViewDialog }) => {
  const data = useSelector((state) => state.data.data);
  const isSorted = useSelector((state) => state.data.isSorted);

  let dataList = isSorted ? data : sortDataByDate(data, "createdAt");
  const isDataAvailable = data.length > 0;
  return (
    <div className={`table-container ${setTableHeight(isDataAvailable)}`}>
      <table className={`table ${!isDataAvailable ? "relative" : ""}`}>
        <tbody>
          <TableHead headList={complaintPendingHeadList} />
          {isDataAvailable ? (
            <>
              {dataList.map((data, index) => {
                return (
                  <tr className="table-row" key={index}>
                    <td className="p-3">{data.id}</td>
                    <td className="p-3">{data.station.name}</td>
                    <td className="p-3">{data.fieldTechnician.fullName}</td>
                    <td className="p-3">{formatDate(data.createdAt)}</td>
                    <td className="p-3">{data.description}</td>
                    <td className="p-3">{data.category.name}</td>
                    <td
                      className={`status-data ${
                        getComplaintStatus(data.status).textColor
                      }`}
                    >
                      {getComplaintStatus(data.status).status}
                    </td>
                    <td className="p-3">
                      {" "}
                      <GetCheckCross
                        checkVal={data.visitIdList ? true : false}
                      />
                    </td>
                    <td className="p-3">
                      <button
                        className="tableViewBtn"
                        onClick={() => openViewDialog(dataList[index])}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                );
              })}
            </>
          ) : (
            <NoDataDisplay />
          )}
        </tbody>
      </table>
    </div>
  );
};
export default TableComplaintContent;