import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const useDelete = () => {
  const [loading, setLoading] = useState(false);
  const [isSucceed, setIsSucceed] = useState(false);
  const [formError, setFormError] = useState(false);
  const [formErrMsg, setFormErrMsg] = useState("");
  const [responseData, setResponseData] = useState({});
  const navigate = useNavigate();

  const executeDelete = async (
    sendData,
    apiUrl,
    updateStateOnResponse,
    isChangePassword
  ) => {
    let config = {
      method: "delete",
      url: apiUrl,
      data: sendData,
    };
    setLoading(true);
    await axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setResponseData(response.data);
        setLoading(false);
        setIsSucceed(true);
        if (updateStateOnResponse) {
          updateStateOnResponse();
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.status === 401) {
          navigate("/login");
        } else if (
          error.response &&
          error.response.status === 502 &&
          error.response.data.message === "Request Body in wrong format" &&
          isChangePassword
        ) {
          setFormError(true);
          setLoading(false);
          setFormErrMsg("Invalid Credential");
        } else {
          setFormError(true);
          setLoading(false);
          setFormErrMsg(
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message
          );
        }
      });
  };

  return {
    executeDelete,
    loading,
    isSucceed,
    formError,
    formErrMsg,
    responseData,
    setIsSucceed,
    setFormError,
    setResponseData,
  };
};

export default useDelete;
