import React from "react";
import check from "../../../assets/icons/accept.svg";
import cross from "../../../assets/icons/reject.svg";

function GetCheckCross({ checkVal }) {
  return (
    <>
      {checkVal !== null ? (
        checkVal ? (
          <img src={check} alt="accept" className="w-5 mt-1 mx-auto" />
        ) : (
          <img src={cross} alt="reject" className="w-4 mt-1 mx-auto" />
        )
      ) : (
        "-"
      )}
    </>
  );
}

export default GetCheckCross;
