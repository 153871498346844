import React, { useState } from "react";
import { useForm } from "react-hook-form";

import CenteredHeading from "../UI/headingsUI/CenteredHeading";
import GrayButton from "../UI/buttonUI/GrayButton";
import CustomInput from "../UI/PageInput/CustomInput";
import SearchDropdown from "../UI/PageInput/SearchDropdown";
import DateInput from "../UI/PageInput/DateInput";
import successIcon from "../../assets/icons/successIcon.svg";
import DialogBox from "../UI/DialogBox";
import SubmitButton from "../UI/buttonUI/SubmitButton";

const AddMachineComponent = ({
  machineSetup,
  sendData,
  isSucceed,
  loading,
  navigate,
  render,
}) => {
  const [selectedOption, setSelectedOption] = useState({});
  const [isFormClear, setIsFormClear] = useState(false);

  const machinesArray = machineSetup.machines.map((item) => ({
    id: item.station.machineID,
    name: item.station.name,
  }));
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  const fgDate = watch("fgDate");

  const onSubmit = (data) => {
    sendData(data, reset, setSelectedOption);
  };

  // handle clear form
  const handleClearForm = () => {
    setSelectedOption({});
    setIsFormClear(!isFormClear);
    // reset form value
    reset();
  };

  return (
    <>
      <div className="form-container ">
        <CenteredHeading title="Add Machine Component" />
        <form
          onSubmit={!loading ? (e) => handleSubmit(onSubmit)(e) : undefined}
        >
          <div className="flex flex-wrap">
            <div className="halfCol">
              <div className="px-1">
                <SearchDropdown
                  name="categoryId"
                  label="Category *"
                  keyName="name"
                  id="id"
                  options={machineSetup.categories}
                  setSelectedOption={setSelectedOption}
                  selectedOption={selectedOption}
                  setValue={setValue}
                  register={{
                    ...register("categoryId", {
                      required: "Please select Category",
                    }),
                  }}
                  error={errors.categoryId}
                />
                <CustomInput
                  type="text"
                  name="cardNumber"
                  labelText="Card Name *"
                  placeholder="Enter"
                  register={{
                    ...register("cardNumber", {
                      required: "Please Enter a card number",
                      validate: (value) => {
                        if (value.trim() === "") {
                          return "Card Name cannot contain only spaces";
                        }
                        return true;
                      },
                    }),
                  }}
                  error={errors.cardNumber}
                />
              </div>
            </div>
            <div className="halfCol">
              <div className="px-1">
                <SearchDropdown
                  name="machineId"
                  label="Machine *"
                  keyName="name"
                  id="id"
                  options={machinesArray}
                  setSelectedOption={setSelectedOption}
                  selectedOption={selectedOption}
                  setValue={setValue}
                  register={{
                    ...register("machineId", {
                      required: "Please select Machine",
                    }),
                  }}
                  error={errors.machineId}
                  search={true}
                />
                <DateInput
                  label="Date *"
                  name={"fgDate"}
                  value={fgDate}
                  setValue={setValue}
                  register={{
                    ...register("fgDate", {
                      required: "Please Select date",
                    }),
                  }}
                  error={errors.fgDate}
                  isFormClear={isFormClear}
                  render={render}
                />
              </div>
            </div>
          </div>
          <div className="addBtn---wrap">
            <p className="addWrap">
              <SubmitButton
                btnText={!loading ? "Add Machine Component" : "Adding..."}
                onClick={!loading ? handleSubmit(onSubmit) : undefined}
                loading={loading}
                height="h-12"
              />
            </p>
            <p className="grayWrap">
              <GrayButton
                btnText="Clear"
                width="w-40"
                onClick={handleClearForm}
              />
            </p>
          </div>
        </form>
      </div>
      {isSucceed && (
        <DialogBox
          message="Successfully Machine Component"
          messageIcon={successIcon}
          btnText="Ok"
          closeAction={() => navigate("/home")}
        />
      )}
    </>
  );
};

export default AddMachineComponent;
