import { useNavigate } from "react-router-dom";

import GradientButton from "./buttonUI/GradientButton";
import ButtonIcon from "./buttonUI/ButtonIcon";
import goHome from "../../assets/icons/goHome.svg";
import refresh from "../../assets/icons/reset.svg";
import LeftIconButton from "./buttonUI/LeftIconButton";
import { logoutHandler } from "../../functions/logoutHandler";

export default function DialogBox(props) {
  const navigate = useNavigate();
  const {
    title,
    message,
    phoneNo,
    messageIcon,
    animateIcon,
    btnText,
    closeAction,
    isErrorHandleButtons,
    isHome,
  } = props;
  const errorHandleBtnText = isHome ? "Go to Login" : "Go to Home";
  const handleReload = () => {
    window.location.reload();
  };
  return (
    <div className="overlay before:z-9999">
      <div className="flex-center-align dialogBoxWrap absolute-center-align">
        <div>
          {animateIcon && (
            <div className="animateSpin" viewBox="0 0 24 24">
              <img src={animateIcon} alt="animateIcon" />
            </div>
          )}
          {messageIcon && (
            <img src={messageIcon} alt="messageIcon" className="mx-auto mb-5" />
          )}
          {title && <h3 className="dialogBoxTitle">{title}</h3>}
          <p className="dialogBoxMsg">{message}</p>
          {phoneNo && <p className="dialogBoxPhone">{phoneNo}</p>}
          {btnText && (
            <div className="mt-5">
              <GradientButton
                btnText={btnText}
                width="w-56"
                rounded="rounded-full"
                onClick={closeAction}
              />
            </div>
          )}
          {isErrorHandleButtons && (
            <p className="flex justify-center pt-8">
              <p className="px-1">
                <ButtonIcon
                  btnText={errorHandleBtnText}
                  icon={goHome}
                  onclick={isHome ? logoutHandler : () => navigate("/")}
                />
              </p>
              <p className="px-1">
                <LeftIconButton
                  text="Try Again"
                  textColor="text-dark-blue font-figtreeMedium"
                  bgColor="bg-lg5 border-lg5"
                  icon={refresh}
                  iconW="W-4"
                  hover="hover:bg-transparent hover:border-dark-blue"
                  onClick={handleReload}
                />
              </p>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
