import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import successIcon from "../../../assets/icons/successIcon.svg";
import rejected from "../../../assets/icons/rejected.svg";
import JustifiedHeading from "../../UI/headingsUI/JustifiedHeading";
import { filterCategoryVisitPending } from "../../../utils/filterCategoryList";
import FilterSearch from "../../FilterSearch";
import { visitSearch } from "../../../utils/tableSearchList";
import VisitTableContent from "./VisitTableContent";
import VisitViewDialog from "../../UI/viewDialogs/complaint/VisitViewDialog";
import UseDialogBox from "../../../CustomHooks/UseDialogBox";
import ImageSlider from "../../UI/viewDialogs/ImageSlider";
import VisitCheckConfirm from "../../UI/viewDialogs/complaint/VisitCheckConfirm";
import Dialog from "../../UI/Dialog";

const VisitPending = ({
  handleConfirmDialogBox,
  viewDialogOpen,
  setViewDialogOpen,
  confirmDialogBox,
  setConfirmDialogBox,
  isActionSucceed,
  setIsActionSucceed,
  buttonStatus,
  handleCheck,
  checkLoading,
  setBodyData,
  updateStateOnAddRemark,
  isLoaded,
}) => {
  const [visitViewDetails, setVisitViewDetails] = useState();
  const [isViewOpen, setIsViewOpen] = useState(false);
  const { showDialogBox, setShowDialogBox } = UseDialogBox();
  const [imageSliderPics, setImageSliderPics] = useState([]);
  const [imageSliderCurrentIndex, setImageSliderCurrentIndex] = useState();
  const data = useSelector((state) => state.data.data);
  const [copyData] = useState(data.slice());
  const isCopyData = copyData.length > 0;

  const openViewDialog = (visitData) => {
    setIsViewOpen(true);
    setViewDialogOpen(true);
    setVisitViewDetails(visitData);
  };
  const closeViewDialog = () => {
    setViewDialogOpen(false);
    setIsViewOpen(false);
  };

  const closeConfirmDialogBox = () => {
    setConfirmDialogBox(false);
    if (isViewOpen) {
      setViewDialogOpen(true);
    }
  };

  const openImageSlider = (Pics) => {
    closeViewDialog();
    setShowDialogBox(true);
    setImageSliderPics(Pics);
  };

  const closeImageSlider = () => {
    setViewDialogOpen(true);
    setShowDialogBox(false);
  };

  useEffect(() => {
    const a = () => {
      setIsActionSucceed(false);
    };
    isActionSucceed && setTimeout(a, 2000);
  }, [isActionSucceed]);

  return (
    <>
      {/* page title container for mobile */}
      <JustifiedHeading title="Visits Pending" />
      {/* data filter and data search container  */}
      {isCopyData ? (
        <FilterSearch
          categories={filterCategoryVisitPending}
          searchCategories={visitSearch}
          isActionSucceed={isActionSucceed}
          isLoaded={isLoaded}
          isCopyData={isCopyData}
        />
      ) : (
        ""
      )}
      {/* visit pending table list */}
      <VisitTableContent openViewDialog={openViewDialog} />
      {/* show view dialog box for the specific visit details */}
      {viewDialogOpen && (
        <VisitViewDialog
          closeDialog={closeViewDialog}
          viewDetails={visitViewDetails}
          openImageSlider={openImageSlider}
          setImageSliderCurrentIndex={setImageSliderCurrentIndex}
          accept={() => handleConfirmDialogBox(visitViewDetails.id, true)}
          reject={() => handleConfirmDialogBox(visitViewDetails.id, false)}
          updateStateOnAddRemark={updateStateOnAddRemark}
          setVisitViewDetails={setVisitViewDetails}
        />
      )}
      {/* show confirm dialog box for the visit checked (accept or reject) */}
      {confirmDialogBox && (
        <VisitCheckConfirm
          title={
            buttonStatus
              ? "Are you sure to Approve this Visit ?"
              : "Are you sure to Reject this Visit ?"
          }
          cancelOnclick={closeConfirmDialogBox}
          checkAction={handleCheck}
          setBodyData={setBodyData}
          checkLoading={checkLoading}
        />
      )}
      {/* show success dialog box after visit checked (accept or reject) */}
      {isActionSucceed && (
        <Dialog
          message={
            buttonStatus ? "Visit Successfully Approved!" : "Visit Rejected!"
          }
          messageIcon={buttonStatus ? successIcon : rejected}
          closeDialog={() => setIsActionSucceed(false)}
        />
      )}
      {/* show view dialog images in image slider box */}
      {showDialogBox && (
        <ImageSlider
          title="Visit Photos"
          images={imageSliderPics}
          imageSliderCurrentIndex={imageSliderCurrentIndex}
          closeDialogBox={closeImageSlider}
        />
      )}
    </>
  );
};

export default VisitPending;
