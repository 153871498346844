import React, { useEffect, useState } from "react";
import deleteIcon from "../../assets/icons/deletIcon.svg";
import useDelete from "../../CustomHooks/useAxios/useDelete";
import StatusMessage from "./StatusMessage";

const splitRemark = (remark) => {
  const regex = /^(.*?) \(By (.*?) at (.*)\)$/;
  const matches = remark.match(regex);

  if (matches) {
    return {
      firstPart: matches[1],
      secondPart: matches[2],
      thirdPart: matches[3],
    };
  }
  return null;
};

const RemarkList = ({
  remarkList,
  isRemarkDelete,
  OpenRemarkList,
  dataId,
  updateStateOnAddRemark,
  setViewDetails,
  apiUrl,
}) => {
  const [currentDeleted, setCurrentDeleted] = useState(null);
  const {
    executeDelete,
    loading,
    isSucceed,
    formError,
    formErrMsg,
    responseData,
    setIsSucceed,
  } = useDelete();

  const onSubmit = (remarkId) => {
    const remarkData = {
      id: dataId,
      remarkId: remarkId,
    };
    //API calling
    executeDelete(remarkData, apiUrl, updateStateOnAddRemark);
    setCurrentDeleted(remarkId);
  };

  useEffect(() => {
    const a = () => {
      setIsSucceed(false);
    };
    isSucceed && setTimeout(a, 500);
  }, [isSucceed]);
  // expense view dialog list updated when remark successfully deleted
  if (isSucceed) {
    setViewDetails(responseData);
  }
  return (
    <>
      <StatusMessage
        isSucceed={isSucceed}
        formError={formError}
        formErrMsg={formErrMsg}
      />
      {OpenRemarkList && remarkList && (
        <div className={`px-4 pb-4 ${remarkList.length > 2 ? "h-40" : ""}`}>
          <table
            className={`remark-table ${remarkList.length > 2 ? "h-full" : ""}`}
          >
            <thead
              className={`remark-thead ${
                remarkList.length > 2 ? "w-99%" : "w-full"
              }`}
            >
              <th className="remarkTd py-2">SN</th>
              <th className="remarkTd">User</th>
              <th className="remarkTd">Description</th>
              <th className="remarkTd">Date</th>
              {isRemarkDelete && <th className="remarkTd">Delete</th>}
            </thead>
            <tbody className="remark-tbody">
              {remarkList.map((remark, ind) => {
                const parts = splitRemark(remark);
                return (
                  <tr key={ind} className="remark-tr">
                    <td className="remarkTd py-2">{ind + 1}</td>
                    <td className="remarkTd">{parts.secondPart}</td>
                    <td className="remarkTd">{parts.firstPart}</td>
                    <td className="remarkTd">{parts.thirdPart}</td>
                    {isRemarkDelete && (
                      <td className="remarkTd text-red-600">
                        {loading && currentDeleted === ind ? (
                          "Deleting..."
                        ) : (
                          <img
                            src={deleteIcon}
                            alt="delete"
                            className="mx-auto cursor-pointer"
                            onClick={() => onSubmit(ind)}
                          />
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
export default RemarkList;
