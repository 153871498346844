import React from "react";
import GradientButton from "../UI/buttonUI/GradientButton";
import Modal from "../portal/Modal";
import changePass from "../../assets/icons/successChangePass.svg";

function SuccessChangePassword({ closeAction }) {
  return (
    <Modal closeDialog={() => undefined}>
      <div className="successChangePassWrap absolute-center-align">
        <img src={changePass} alt="passChange" className="mx-auto w-11" />
        <h4 className="successPassHeading">Password Updated!</h4>
        <p className="font-figtree py-4">
          Your Password has been updated successfully! 
        </p>
        <GradientButton
          btnText="Sign In"
          width="w-56"
          rounded="rounded-full"
          onClick={closeAction}
        />
      </div>
    </Modal>
  );
}
export default SuccessChangePassword;
