import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { EXPENSE_HISTORY } from "../../api/allApi_List";
import { fetchData } from "../../redux/dataSlice";
import ExpenseHistory from "../../components/expense/all/ExpenseHistory";
import DialogBox from "../../components/UI/DialogBox";
import spin from "../../assets/icons/spin.png";
import { handleErrMessage } from "../../functions/handleErrMessage";
import MainPageLayout from "../../components/MainPageLayout";

const ExpenseHistoryPage = () => {
  const [render, setRender] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const data = useSelector((state) => state.data.data);
  const error = useSelector((state) => state.data.error);
  const errMsg = useSelector((state) => state.data.errMessage);
  const dataLength = isLoaded && !errMsg ? data.length : 0;

  useEffect(() => {
    dispatch(fetchData(EXPENSE_HISTORY, setIsLoaded, navigate));
  }, [dispatch, render]);

  const updateStateOnAddRemark = () => {
    setRender(!render);
  };

  return (
    <MainPageLayout
      headerConfig={{ title: "Check History" }}
      numberOfData={dataLength}
    >
      {isLoaded && !error ? (
        <ExpenseHistory
          expenseData={data}
          updateStateOnAddRemark={updateStateOnAddRemark}
        />
      ) : error ? (
        <DialogBox
          title="Error"
          message={handleErrMessage(errMsg)}
          isErrorHandleButtons={true}
        />
      ) : (
        <DialogBox message="Loading..." animateIcon={spin} />
      )}
    </MainPageLayout>
  );
};

export default ExpenseHistoryPage;
