import React from "react";
import { useSelector } from "react-redux";

import AddButton from "../buttonUI/AddButton";
import SearchTable from "../../SearchTable";

const JustifiedHeading = (props) => {
  const data = useSelector((state) => state.data.data);
  const countListItem = data ? data.length : 0;
  const {
    children,
    searchCategories,
    isCopyData,
    btnText,
    title,
    navigate,
  } = props;

  return (
    <div
      className={`items-center w-full ${"justify-between"} 2xs:max-md:flex hidden`}
    >
      {children}
      {title && (
        <h2 className="text-2xl text-dark-blue font-poppins font-bold py-2 2xs:max-md:text-sm">
          {title} <span className="text-xs">{`(${countListItem})`}</span>
        </h2>
      )}
      {btnText && (
        <AddButton
          btnText={btnText}
          navigate={navigate}
          height="h-7"
          textSize="text-xs"
          padding="px-2"
          icon="w-3 h-3 pb-1 mr-0.5 mt-0.5"
        />
      )}
      {searchCategories && isCopyData && (
        <SearchTable searchCategories={searchCategories} />
      )}
    </div>
  );
};

export default JustifiedHeading;
