import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filteredData: [],
  isFiltered: false,
};

const isTableFilterSlice = createSlice({
  name: "isTableFilter",
  initialState,
  reducers: {
    setFilteredData: (state, action) => {
      state.filteredData = action.payload;
    },
    setIsFiltered: (state, action) => {
      state.isFiltered = action.payload;
    },
  },
});

export const { setIsFiltered, setFilteredData} = isTableFilterSlice.actions;

export default isTableFilterSlice.reducer;
