import React from "react";
import { Link } from "react-router-dom";
import UsePermissionsClaim from "../../CustomHooks/UsePermissionsClaim";

const ModuleContent = ({ moduleTitle, moduleIcon, route, disable }) => {
  const { checkPermission } = UsePermissionsClaim();
  const permViewUser = checkPermission("view_user");
  const permViewComplaint = checkPermission("view_complaint");
  const permViewSites = checkPermission("view_site");
  return (
    <>
      <Link
        // key={index}
        className={`homeModule-link ${
          disable ||
          (moduleTitle === "user" && !permViewUser) ||
          (moduleTitle === "complaint" && !permViewComplaint) ||
          (moduleTitle === "site manager" && !permViewSites)
            ? "pointer-events-none"
            : ""
        }`}
        to={route && route}
      >
        <div className="homeModule-wrap">
          <div
            className={`rounded-md bg-lb3 ${
              disable ||
              (moduleTitle === "user" && !permViewUser) ||
              (moduleTitle === "complaint" && !permViewComplaint) ||
              (moduleTitle === "site manager" && !permViewSites)
                ? ""
                : "shadow-sh-2"
            }`}
          >
            <div className="homeModule-wrap-img">
              <img
                src={moduleIcon}
                alt="moduleIcon"
                className="homeModule-img"
              />
            </div>
            <div
              className={`homeModule-title ${
                disable ||
                (moduleTitle === "user" && !permViewUser) ||
                (moduleTitle === "complaint" && !permViewComplaint) ||
                (moduleTitle === "site manager" && !permViewSites)
                  ? ""
                  : "shadow-sh-2"
              }`}
            >
              {moduleTitle}
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default ModuleContent;
