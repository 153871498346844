import React, { useState } from "react";

import TableHead from "../UI/TableUI/TableHead";
import { machinesHeadList } from "../../utils/TableHeadList";
import NoDataDisplay from "../UI/NoDataDisplay";
import JustifiedHeading from "../UI/headingsUI/JustifiedHeading";
import FilterSearch from "../FilterSearch";
import { filterCategorySearch } from "../../utils/filterCategoryList";
import { machineSearch } from "../../utils/tableSearchList";
import MachineViewDialog from "../UI/viewDialogs/site/MachineViewDialog";
import check from "../../assets/icons/accept.svg";
import cross from "../../assets/icons/reject.svg";
import UsePermissionsClaim from "../../CustomHooks/UsePermissionsClaim";
import { checkValue } from "../../functions/checkValue";

const Machines = ({ machinesData, updateStateOnAddRemark }) => {
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [machineViewDetails, setMachineViewDetails] = useState({});
  const isDataAvailable = machinesData.length > 0;

  const [copyData] = useState(machinesData.slice());
  const isCopyData = copyData.length > 0;
  const { checkPermission } = UsePermissionsClaim();
  const permAddMachine = checkPermission("add_machine");

  const openViewDialog = (data) => {
    setIsViewDialogOpen(true);
    setMachineViewDetails(data);
  };
  const closeViewDialog = () => {
    setIsViewDialogOpen(false);
  };
  return (
    <>
      <JustifiedHeading
        title="Machines"
        navigate={"/site/add-machine"}
        btnText={permAddMachine ? "Add Machine" : undefined}
      />
      <FilterSearch
        categories={filterCategorySearch}
        searchCategories={machineSearch}
        addBtnNavigation={"/site/add-machine"}
        addBtnText={permAddMachine ? "Add Machine" : undefined}
        isCopyData={isCopyData}
      />
      <div className={`table-container table-height`}>
        <table className={`table ${!isDataAvailable ? "relative" : ""}`}>
          <tbody>
            <TableHead headList={machinesHeadList} />
            {isDataAvailable ? (
              <>
                {machinesData.map((data, index) => {
                  return (
                    <tr className="table-row" key={index}>
                      <td className="p-3">{data.id}</td>
                      <td className="p-3">{checkValue(data.station?.name)}</td>
                      <td className="p-3">{data.category.name}</td>
                      <td className="p-3">
                        <img
                          src={data.station?.isAutomation ? check : cross}
                          alt="icon"
                          className={`mx-auto ${
                            data.station?.isAutomation ? "w-6" : "w-5"
                          }`}
                        />
                      </td>
                      <td className="p-3">
                        <button
                          className="tableViewBtn"
                          onClick={() => openViewDialog(data)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <NoDataDisplay />
            )}
          </tbody>
        </table>
      </div>
      {isViewDialogOpen && (
        <MachineViewDialog
          closeDialog={closeViewDialog}
          viewDetails={machineViewDetails}
          updateStateOnAddRemark={updateStateOnAddRemark}
          setMachineViewDetails={setMachineViewDetails}
        />
      )}
    </>
  );
};
export default Machines;
