import React from "react";
import SearchDropdown from "../../UI/PageInput/SearchDropdown";
import CustomInput from "../../UI/PageInput/CustomInput";

const BankInfo = ({
  userSetup,
  register,
  errors,
  setValue,
  selectedOption,
  setSelectedOption,
}) => {
  return (
    <div className="halfCol pl-1">
      <div className="userInfoWrap">
      <h4 className="userInfoHeading">Bank Info</h4>
      <div className="px-4 py-6">
        <SearchDropdown
          name="bankNameId"
          label="Bank Name"
          keyName="name"
          id="id"
          options={userSetup.banks}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          setValue={setValue}
          register={{
            ...register("bankNameId"),
          }}
          error={errors.bankNameId}
          search={true}
        />
        <CustomInput
          type="text"
          name="ifsc"
          labelText="IFSC Code"
          placeholder="Enter"
          register={{
            ...register("ifsc"),
          }}
          error={errors.ifsc}
        />
        <CustomInput
          type="number"
          name="accountNumber"
          labelText="Bank Account No."
          placeholder="Enter"
          register={{
            ...register("accountNumber", {
              min: {
                value: 1,
                message: "Account Number can't be 0",
              },
              pattern: {
                value: /^\d+$/,
                message: "Only numbers are allowed",
              },
            }),
          }}
          error={errors.accountNumber}
        />
        <CustomInput
          type="text"
          name="branchName"
          labelText="Bank Branch"
          placeholder="Enter"
          register={{
            ...register("branchName"),
          }}
          error={errors.branchName}
        />
      </div>
      </div>
    </div>
  );
};

export default BankInfo;
