import React from "react";

export default function CustomCheckBoxInput(props) {
  return (
    <div className="flex">
      <input
        type="checkbox"
        name={props.name}
        className="accent-input"
        checked={props.checked}
        onChange={props.handleChange}
      />
      <label htmlFor={props.name} className="checkInput-label">
        {props.labelText}
      </label>
    </div>
  );
}
