import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import { useDispatch } from "react-redux";

import Home from "../../components/home/Home";
import useGetData from "../../CustomHooks/useAxios/useGetData";
import { GET_USER } from "../../api/allApi_List";
import DialogBox from "../../components/UI/DialogBox";
import spin from "../../assets/icons/spin.png";
import { handleErrMessage } from "../../functions/handleErrMessage";
import MainPageLayout from "../../components/MainPageLayout";
import { fetchUuid } from "../../redux/uuidSlice";
import { UUID_URL } from "../../api/allApi_List";

const HomePage = () => {
  const { data, isLoaded, error, getData, errMsg } = useGetData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let session = useSessionContext();
  let sessionLoading = session.loading;
  const uuid = localStorage.getItem("uuid");

  console.log(session)

  useEffect(() => {
    if (uuid === null) {
      dispatch(fetchUuid(UUID_URL, navigate));
    }
  }, []);

  useEffect(() => {
    if (!sessionLoading) {
      let userId = session.accessTokenPayload.custom_user_id;
      localStorage.setItem("userId", userId);
      getData(GET_USER + userId, navigate, "userApi");
    }
  }, [sessionLoading]);

  return (
    <SessionAuth>
      <MainPageLayout headerConfig={{title:"Home"}}>
        {isLoaded && !error ? (
          <Home userData={data} />
        ) : error ? (
          <DialogBox
            title="Error"
            message={handleErrMessage(errMsg)}
            isErrorHandleButtons={true}
            isHome={true}
          />
        ) : (
          <DialogBox message="Loading..." animateIcon={spin} />
        )}
      </MainPageLayout>
    </SessionAuth>
  );
};

export default HomePage;