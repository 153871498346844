import React, { Fragment } from "react";

export default function CustomInput({
  type,
  name,
  labelText,
  placeholder,
  register,
  handleClick,
  inputIcon,
  imgAlt,
  showPasswordStatus,
  error,
  value,
}) {
  return (
    <Fragment>
      <label htmlFor={name} className="font-poppins text-dg2 text-sm">
        {labelText}
      </label>
      <p className="relative">
        <input
          type={type}
          min={type === "number" ? "1" : undefined}
          name={name}
          placeholder={placeholder}
          className={`w-full py-3 px-3 border-bw rounded-md text-sm font-figtree placeholder:text-sm placeholder:font-figtree placeholder:text-black focus-visible:outline-dark-blue ${
            error ? "border-red-500" : "border-lg4"
          }`}
          {...register}
          value={value}
        />
        {handleClick ? (
          <img
            src={inputIcon}
            className={`absolute right-3 top-4 cursor-pointer ${
              showPasswordStatus ? "w-4 h-4" : "w-5 h-5"
            }`}
            alt={imgAlt}
            onClick={handleClick}
          />
        ) : (
          <img
            src={inputIcon}
            className="absolute right-3 top-5"
            alt={imgAlt}
          />
        )}
      </p>
      <p
        className={`text-red-500 font-figtree text-xs h-4 ${
          error ? "visible" : "invisible"
        }`}
      >
        {error?.message}
      </p>
    </Fragment>
  );
}
