import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import CenteredHeading from "../../UI/headingsUI/CenteredHeading";
import GrayButton from "../../UI/buttonUI/GrayButton";
import CustomInput from "../../UI/PageInput/CustomInput";
import { jurisOptions } from "../../../utils/jurisdictionDetail";
import { jurisArr } from "../../../utils/jurisdictionDetail";
import JurisDropdown from "../../UI/PageInput/JurisDropdown";
import successIcon from "../../../assets/icons/successIcon.svg";
import showPass from "../../../assets/icons/showpass.svg";
import hidePass from "../../../assets/icons/hidepass.svg";
import useShowHidePassword from "../../../CustomHooks/useShowHidePassword";
import SubmitButton from "../../UI/buttonUI/SubmitButton";
import OfficerViewDetailsDialog from "../../UI/viewDialogs/user/OfficerViewDetailsDialog";

const AddOfficer = ({
  officerSetup,
  sendData,
  isSucceed,
  loading,
  navigate,
  setIsSucceed,
  responseData,
}) => {
  const [levelDropdown, setLevelDropdown] = useState([]);
  const [isFormClear, setIsFormClear] = useState(false);
  const [jurisName, setJurisName] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm();
  const { showPassword, handleClickShowPassword } = useShowHidePassword();

  const formValues = watch();

  // Get a dropdown list for a specific level
  useEffect(() => {
    if (formValues.accessLevel) {
      const updatedLevelDropdown =
        officerSetup[jurisArr[formValues.accessLevel - 1]] || [];
      setLevelDropdown(updatedLevelDropdown);
      setJurisName(jurisArr[formValues.accessLevel - 1]);
    }
  }, [formValues.accessLevel, officerSetup]);

  const onSubmit = () => {
    sendData(formValues, reset, setLevelDropdown);
  };

  // handle clear form
  const handleClearForm = () => {
    setLevelDropdown([]);
    setIsFormClear(!isFormClear);
    setJurisName("");
    // reset form value
    reset();
  };

  return (
    <>
      <div className="form-container ">
        <CenteredHeading title="Add Officer" />
        <form
          onSubmit={!loading ? (e) => handleSubmit(onSubmit)(e) : undefined}
        >
          <div className="flex flex-wrap">
            <div className="halfCol">
              <div className="px-1">
                <CustomInput
                  type="text"
                  name="name"
                  labelText="Name *"
                  placeholder="Enter"
                  register={{
                    ...register("name", {
                      required: "Please Enter a name",
                      validate: (value) => {
                        if (value.trim() === "") {
                          return "Name cannot contain only spaces";
                        }
                        return true;
                      },
                    }),
                  }}
                  error={errors.name}
                />
                <JurisDropdown
                  name="accessLevel"
                  label="Jurisdiction Level *"
                  keyName="name"
                  id="id"
                  options={jurisOptions}
                  value={formValues.accessLevel}
                  setValue={setValue}
                  render={isSucceed}
                  register={{
                    ...register("accessLevel", {
                      required: "Please select an option",
                    }),
                  }}
                  error={errors.accessLevel}
                  isFormClear={isFormClear}
                />
                <CustomInput
                  type="text"
                  name="email"
                  labelText="Email *"
                  placeholder="Enter"
                  register={{
                    ...register("email", {
                      required: "Please Enter a email",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: "Please enter a valid email",
                      },
                    }),
                  }}
                  error={errors.email}
                />
              </div>
            </div>
            <div className="halfCol">
              <div className="px-1">
                <CustomInput
                  type="text"
                  name="phoneNumber"
                  labelText="Phone No. *"
                  placeholder="Enter"
                  register={{
                    ...register("phoneNumber", {
                      required: "Please Enter phone number",
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Please enter a valid 10-digit phone number.",
                      },
                    }),
                  }}
                  error={errors.phoneNumber}
                />
                <JurisDropdown
                  name="accessId"
                  label={` ${jurisName} Name *`}
                  keyName="name"
                  id="id"
                  options={levelDropdown}
                  value={formValues.accessId}
                  setValue={setValue}
                  render={isSucceed}
                  register={{
                    ...register("accessId", {
                      required: "Please select an option",
                    }),
                  }}
                  error={levelDropdown.length > 0 && errors.accessId}
                  disabled={levelDropdown.length > 0 ? false : true}
                  isFormClear={isFormClear}
                />
                <CustomInput
                  type={showPassword.password ? "text" : "password"}
                  name="password"
                  labelText="Password *"
                  placeholder="Enter"
                  register={{
                    ...register("password", {
                      required: "Please Enter a password",
                      minLength: {
                        value: 6,
                        message: "Password must be at least 6 characters long",
                      },
                    }),
                  }}
                  handleClick={() => handleClickShowPassword("password")}
                  inputIcon={showPassword.password ? hidePass : showPass}
                  imgAlt="pass"
                  error={errors.password}
                />
              </div>
            </div>
          </div>
          <div className="addBtn---wrap">
            <p className="addWrap">
              <SubmitButton
                btnText={!loading ? "Add Officer" : "Adding..."}
                loading={loading}
                height="h-12"
              />
            </p>
            <p className="grayWrap">
              <GrayButton
                btnText="Clear"
                width="w-40"
                onClick={handleClearForm}
              />
            </p>
          </div>
        </form>
      </div>
      {isSucceed && (
        <OfficerViewDetailsDialog
          closeDialog={() => navigate("/officer/all")}
          modalNotClose={() => setIsSucceed(true)}
          viewDetails={responseData}
          message="Officer Successfully Created!"
          messageIcon={successIcon}
        />
      )}
    </>
  );
};

export default AddOfficer;
