import React from "react";
import acceptAll from "../../../assets/icons/acceptAll.svg";
import rejectAll from "../../../assets/icons/rejectAll.svg";
import filledAcceptAll from "../../../assets/icons/filledAcceptAll.svg";
import filledRejectAll from "../../../assets/icons/filledRejectAll.svg";

const AllAcceptReject = ({
  checkedRow,
  setCheckedData,
  ind,
  setCheckedType,
  checkedType,
}) => {
  const handleAllChecked = (status) => {
    const updatedData = checkedRow.map((id) => ({
      id: id,
      status: status,
    }));
    setCheckedData(updatedData);
    if (status) {
      setCheckedType({ accept: true, reject: false });
    } else {
      setCheckedType({ accept: false, reject: true });
    }
  };

  const handleAllUnchecked = () => {
    setCheckedData([]);
    setCheckedType({ accept: false, reject: false });
  };

  return (
    <th
      className="py-2 px-1 text-base font-normal sm:max-lg:text-sm 2xs:max-sm:text-xs"
      key={ind}
    >
      <span className="flex justify-center">
        {checkedType.accept ? (
          <img
            src={filledAcceptAll}
            alt="filledAcceptAll"
            className="mx-2"
            onClick={handleAllUnchecked}
          />
        ) : (
          <img
            src={acceptAll}
            alt="acceptAll"
            className="mx-2 cursor-pointer"
            onClick={() => handleAllChecked(true)}
          />
        )}
        {checkedType.reject ? (
          <img
            src={filledRejectAll}
            alt="filledRejectAll"
            className="mx-2 cursor-pointer"
            onClick={handleAllUnchecked}
          />
        ) : (
          <img
            src={rejectAll}
            alt="rejectAll"
            className="mx-2 cursor-pointer"
            onClick={() => handleAllChecked(false)}
          />
        )}
      </span>
    </th>
  );
};

export default AllAcceptReject;
