import React from "react";
import { Link, useNavigate } from "react-router-dom";

import whiteArrow from "../../assets/icons/white-arrow.svg";
import user from "../../assets/icons/dark_user.svg";
import { logoutHandler } from "../../functions/logoutHandler";
import logout from "../../assets/icons/logout.svg";
import dummyUser from "../../assets/images/dummyUser.svg";
import useImageUrl from "../../CustomHooks/useImageUrl";

const UserProfile = () => {
  const navigate = useNavigate();
  const { getImageUrl } = useImageUrl();
  const userName = localStorage.getItem("userName");
  const profilePic = localStorage.getItem("profilePic");
  return (
    <>
      {userName ? (
        <div className="group profileDropdown-wrap">
          <img
            src={profilePic ? getImageUrl(profilePic) : dummyUser}
            alt="profileImg"
            className="w-8 h-8 rounded-full"
          />
          <p className="usernameText">{userName}</p>
          <img src={whiteArrow} alt="arrow" className="profile-dropdown-arr" />
          <div className="group-hover:opacity-100 group-hover:visible show-dropdown-wrap">
            <Link to="/profile" className="py-3 dropdown-link icon-filter">
              <img
                src={user}
                alt="profile"
                className="pr-3 w-8 2xs:max-sm:w-7"
              />
              View Profile
            </Link>
            <Link
              className="pb-3 dropdown-link icon-filter"
              onClick={() => logoutHandler(navigate)}
              to="/login"
            >
              <img
                src={logout}
                alt="logout"
                className="pr-3 w-8 2xs:max-sm:w-7"
              />
              Logout
            </Link>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default UserProfile;
