export function getExpenseStatus(stateValue) {
  const statusMap = {
    0: { status: "Receiver Pending", textColor: "text-yellow" },
    1: { status: "Supervisor Pending", textColor: "text-yellow" },
    "-1": { status: "Rejected By Receiver", textColor: "text-red-1" },
    3: { status: "Accepted By Supervisor", textColor: "text-green" },
    "-3": { status: "Rejected By Supervisor", textColor: "text-red-1" },
    7: { status: "Accepted By Admin", textColor: "text-green" },
    "-7": { status: "Rejected By Admin", textColor: "text-red-1" },
    15: { status: "Accept By Company", textColor: "text-green" },
    "-15": { status: "Rejected By Company", textColor: "text-red-1" },
  };

  return statusMap[stateValue] || "";
}
