import React, { useState } from "react";
import TableHead from "../UI/TableUI/TableHead";
import { sitesHeadList } from "../../utils/TableHeadList";
import NoDataDisplay from "../UI/NoDataDisplay";
import { checkValue } from "../../functions/checkValue";
import JustifiedHeading from "../UI/headingsUI/JustifiedHeading";
import FilterSearch from "../FilterSearch";
import { filterCategorySite } from "../../utils/filterCategoryList";
import { siteSearch } from "../../utils/tableSearchList";
import SiteViewDialog from "../UI/viewDialogs/site/SiteViewDialog";
import { siteCategoryColor } from "../../functions/siteCategoryColor";
import UsePermissionsClaim from "../../CustomHooks/UsePermissionsClaim";
import check from "../../assets/icons/accept.svg";
import cross from "../../assets/icons/reject.svg";

const Sites = ({ sitesData, updateStateOnAddRemark }) => {
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [siteViewDetails, setSiteViewDetails] = useState({});
  const isDataAvailable = sitesData.length > 0;

  const [copyData] = useState(sitesData.slice());
  const isCopyData = copyData.length > 0;
  const { checkPermission } = UsePermissionsClaim();
  const permAddSite = checkPermission("add_site");

  const openViewDialog = (data) => {
    setIsViewDialogOpen(true);
    setSiteViewDetails(data);
  };
  const closeViewDialog = () => {
    setIsViewDialogOpen(false);
  };
  return (
    <>
      <JustifiedHeading
        title="Sites"
        navigate={"/site/add"}
        btnText={permAddSite ? "Add Site" : undefined}
      />
      <FilterSearch
        categories={filterCategorySite}
        searchCategories={siteSearch}
        addBtnNavigation={"/site/add"}
        addBtnText={permAddSite ? "Add Site" : undefined}
        isCopyData={isCopyData}
      />
      <div className={`table-container table-height`}>
        <table className={`table ${!isDataAvailable ? "relative" : ""}`}>
          <tbody>
            <TableHead headList={sitesHeadList} />
            {isDataAvailable ? (
              <>
                {sitesData.map((data, index) => {
                  return (
                    <tr className="table-row" key={index}>
                      <td className="p-3">{data.id}</td>
                      <td className="p-3">{data.name}</td>
                      <td className="p-3">
                        {checkValue(data.jbccFieldTechnician?.fullName)}
                      </td>
                      <td className="p-3">
                        {checkValue(data.je.subDivision.name)}
                      </td>
                      <td className="p-3">
                        <img
                          src={data.isAutomation ? check : cross}
                          alt="icon"
                          className={`mx-auto ${
                            data.isAutomation ? "w-6" : "w-5"
                          }`}
                        />
                      </td>
                      <td
                        className={`p-3 font-semibold ${siteCategoryColor(
                          data.category.name
                        )}`}
                      >
                        {data.category.name}
                      </td>
                      <td className="p-3">
                        <button
                          className="tableViewBtn"
                          onClick={() => openViewDialog(data)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <NoDataDisplay />
            )}
          </tbody>
        </table>
      </div>
      {isViewDialogOpen && (
        <SiteViewDialog
          closeDialog={closeViewDialog}
          viewDetails={siteViewDetails}
          updateStateOnAddRemark={updateStateOnAddRemark}
          setSiteViewDetails={setSiteViewDetails}
        />
      )}
    </>
  );
};
export default Sites;
