import { useEffect } from "react";
import disableCheck from "../../../assets/icons/disableCheck.svg";
import disableCross from "../../../assets/icons/disableCross.svg";
import activeCheck from "../../../assets/icons/activeCheck.svg";
import activeCross from "../../../assets/icons/activeCross.svg";
import filledCheck from "../../../assets/icons/filledCheck.svg";
import filledCross from "../../../assets/icons/filledCross.svg";

const AcceptRejectOnMultipleCheck = ({
  isRowSelected,
  setCheckedData,
  dataId,
  checkedData,
  isAllCheck,
}) => {
  const getStatusById = (array, id) => {
    const item = array.find((obj) => obj.id === id);
    return item ? item.status : undefined; // Returns undefined if the id is not found
  };
  const status = getStatusById(checkedData, dataId);

  const handleCheck = (checkStatus) => {
    setCheckedData((prev) => {
      const existingIndex = prev.findIndex((item) => item.id === dataId);
      if (existingIndex !== -1) {
        // Update the status of the existing object
        const updatedData = [...prev];
        updatedData[existingIndex] = {
          ...updatedData[existingIndex],
          status: checkStatus,
        };
        return updatedData;
      } else {
        // Add a new object
        return [...prev, { id: dataId, status: checkStatus }];
      }
    });
  };

  useEffect(() => {
    if (!isRowSelected && !isAllCheck) {
      setCheckedData((prev) => {
        const removeUnSelectedObj = prev.filter((item) => item.id !== dataId);
        return removeUnSelectedObj;
      });
    }
  }, [dataId, isRowSelected, setCheckedData]);
  return (
    <>
      <button className="dataCheckedBtn">
        {isRowSelected ? (
          <img
            src={status && status !== undefined ? filledCheck : activeCheck}
            alt="check"
            className="dataCheckedIcon"
            onClick={() => handleCheck(true)}
          />
        ) : (
          <img
            src={disableCheck}
            alt="check"
            className="dataCheckedIcon cursor-not-allowed"
          />
        )}
      </button>
      <button className="dataCheckedBtn">
        {isRowSelected ? (
          <img
            src={!status && status !== undefined ? filledCross : activeCross}
            alt="filled"
            className="dataCheckedIcon5"
            onClick={() => handleCheck(false)}
          />
        ) : (
          <img
            src={disableCross}
            alt="check"
            className="dataCheckedIcon cursor-not-allowed"
          />
        )}
      </button>
    </>
  );
};

export default AcceptRejectOnMultipleCheck;
