import React, { useState } from "react";
import blueArrow from "../../../../assets/icons/blueArrow.svg";
import { checkValue } from "../../../../functions/checkValue";
import { timeStampToDateAndTime } from "../../../../functions/dateTime/timeStampToDateAndTime";

function ExpensesList({ expenseList }) {
  const [isOpen, setIsOpen] = useState(false);
  const amountArr = expenseList.map((item) => item.amount);
  const amountSum = amountArr.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);
  return (
    <div className="rounded-md 3xl border border-lg5 shadow-md mt-4 relative">
      <button
        className="text-dark-blue 2xs:max-lg:text-sm cursor-pointer p-4 w-full text-left relative"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span className="font-semibold font-figtree">Sheet Details</span>
        <img
          src={blueArrow}
          alt="arrow"
          className={`2xs right-5 w-5 py-2 absolute ${
            isOpen ? "rotate-180 top-3" : "top-4"
          }`}
        />
      </button>

      {isOpen && (
        <>
          <div className={`px-4 pb-4 ${expenseList.length > 5 ? "h-50" : ""}`}>
            <table
              className={`w-full table-fixed text-center border-collapse border border-lg5 flex flex-col ${
                expenseList.length > 5 ? "h-full" : ""
              }`}
            >
              <thead
                className={`text-white font-poppins text-sm font-extralight bg-dark-blue 2xs:max-sm:text-sm table table-fixed ${
                  expenseList.length > 5 ? "w-99%" : "w-full"
                }`}
              >
                <th className="border border-lg5 py-2">SN</th>
                <th className="border border-lg5">User</th>
                <th className="border border-lg5">Amount</th>
                <th className="border border-lg5">Date</th>
                <th className="border border-lg5">Category</th>
                <th className="border border-lg5">Description</th>
              </thead>
              <tbody className="font-figtree 2xs:max-sm:text-xs overflow-y-auto">
                {expenseList.map((expense, ind) => {
                  return (
                    <tr
                      key={ind}
                      className="w-full overflow-y-auto table table-fixed text-sm odd:bg-white even:bg-lg6"
                    >
                      <td className="border border-lg5 py-2">{ind + 1}</td>
                      <td className="border border-lg5">
                        {expense.user.fullName}
                      </td>
                      <td className="border border-lg5">{`Rs. ${expense.amount}/-`}</td>
                      <td className="border border-lg5">
                        {timeStampToDateAndTime(expense.createdAt)}
                      </td>
                      <td className="border border-lg5">
                        {expense.category.name}
                      </td>
                      <td className="border border-lg5">
                        {checkValue(expense.description)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <table class="w-full table-fixed mt-2">
              <tbody>
                <tr className="bg-lg6">
                  <td class="p-2 border border-lg5 text-dark-blue font-figtreeMedium text-sm">
                    Total Expenses : {expenseList.length}
                  </td>
                  <td class="p-2 border border-lg5 text-dark-blue font-figtreeMedium text-sm">
                    Total Amount : {`Rs. ${amountSum}/-`}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}

export default ExpensesList;
