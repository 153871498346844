import React, { useState } from "react";
import { useSelector } from "react-redux";

import TableHead from "../../UI/TableUI/TableHead";
import { allExpenseHeadList } from "../../../utils/TableHeadList";
import { formatDate } from "../../../functions/dateTime/formatDate";
import NoDataDisplay from "../../UI/NoDataDisplay";
import sortDataByDate from "../../../functions/sorting/sortDataByDate";
import { checkValue } from "../../../functions/checkValue";
import { getExpenseStatus } from "../../../functions/getExpenseStatus";
import JustifiedHeading from "../../UI/headingsUI/JustifiedHeading";
import { filterCategoryExpense } from "../../../utils/filterCategoryList";
import { expenseSearch } from "../../../utils/tableSearchList";
import FilterSearch from "../../FilterSearch";
import ExpenseViewDialog from "../../UI/viewDialogs/expense/ExpenseViewDialog";
import ImageSlider from "../../UI/viewDialogs/ImageSlider";
import UseDialogBox from "../../../CustomHooks/UseDialogBox";
import ExpenseListTabs from "./ExpenseListTabs";
import UsePermissionsClaim from "../../../CustomHooks/UsePermissionsClaim";

const Expense = ({
  expenseData,
  copyOriginalData,
  currentListTab,
  isTabClicked,
  SetCurrentListTab,
  setIsTabClicked,
  updateStateOnAddRemark,
}) => {
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [expenseViewDetails, setExpenseViewDetails] = useState({});
  const { showDialogBox, setShowDialogBox } = UseDialogBox();
  const [imageSliderPics, setImageSliderPics] = useState([]);
  const [imageSliderCurrentIndex, setImageSliderCurrentIndex] = useState();

  const isSorted = useSelector((state) => state.data.isSorted);

  const checkAnyListIsAvailable =
    copyOriginalData.expenses?.length > 0 ||
    copyOriginalData.loanExpenses?.length > 0 ||
    copyOriginalData.pendingExpenses?.length > 0;
  const { checkPermission } = UsePermissionsClaim();
  const permAddExpense = checkPermission("add_expense");

  let dataList = isSorted ? expenseData : sortDataByDate(expenseData, "date");
  const isDataAvailable = expenseData?.length > 0;

  const openViewDialog = (expenseData) => {
    setViewDialogOpen(true);
    setExpenseViewDetails(expenseData);
  };
  const closeViewDialog = () => {
    setViewDialogOpen(false);
  };

  const openImageSlider = (Pics) => {
    closeViewDialog();
    setShowDialogBox(true);
    setImageSliderPics(Pics);
  };

  const closeImageSlider = () => {
    setViewDialogOpen(true);
    setShowDialogBox(false);
  };
  return (
    <>
      {/* <JustifiedHeading
        navigate={"/expense/add"}
        btnText={permAddExpense ? "Add Expense" : undefined}
      >
        <ExpenseListTabs
          currentListTab={currentListTab}
          SetCurrentListTab={SetCurrentListTab}
          copyOriginalData={copyOriginalData}
          setIsTabClicked={setIsTabClicked}
        />
      </JustifiedHeading> */}

      <FilterSearch
        categories={filterCategoryExpense}
        searchCategories={expenseSearch}
        getStatus={getExpenseStatus}
        currentListTab={currentListTab}
        copyOriginalData={copyOriginalData}
        isTabClicked={isTabClicked}
        addBtnNavigation={"/expense/add"}
        addBtnText={permAddExpense ? "Add Expense" : undefined}
        isCopyData={checkAnyListIsAvailable}
      />

      <div className={`table-container table-height`}>
        <table className={`table ${!isDataAvailable ? "relative" : ""}`}>
          <tbody>
            <TableHead headList={allExpenseHeadList} />
            {isDataAvailable ? (
              <>
                {dataList.map((data, index) => {
                  return (
                    <tr className="table-row" key={index}>
                      <td className="p-3">{index + 1}</td>
                      <td className="p-3">
                        {data.user.fullName} (U) <br></br>
                        {data.receiver && <>{data.receiver.fullName} (R)</>}
                      </td>
                      <td className="p-3">{`₹${data.amount}`}</td>
                      <td className="p-3 w-72">
                        {checkValue(data.description)}
                      </td>
                      <td className="p-3">
                        {formatDate(data.date)}
                        {data.backDated && (
                          <span className="backDatedBtn">BD</span>
                        )}
                      </td>
                      <td className="p-3">{data.category.name}</td>
                      <td
                        className={`status-data ${
                          getExpenseStatus(data.state).textColor
                        }`}
                      >
                        {getExpenseStatus(data.state).status}
                      </td>
                      <td className="p-3">
                        <button
                          className="tableViewBtn"
                          onClick={() => openViewDialog(dataList[index])}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <NoDataDisplay />
            )}
          </tbody>
        </table>
      </div>
      {viewDialogOpen && (
        <ExpenseViewDialog
          closeDialog={closeViewDialog}
          viewDetails={expenseViewDetails}
          openImageSlider={openImageSlider}
          setImageSliderCurrentIndex={setImageSliderCurrentIndex}
          updateStateOnAddRemark={updateStateOnAddRemark}
          setExpenseViewDetails={setExpenseViewDetails}
        />
      )}
      {showDialogBox && (
        <ImageSlider
          title="Expense Photos"
          images={imageSliderPics}
          imageSliderCurrentIndex={imageSliderCurrentIndex}
          closeDialogBox={closeImageSlider}
        />
      )}
    </>
  );
};
export default Expense;
