import React from "react";
import HomeModules from "./HomeModules";
import HomeProfileBox from "./HomeProfileBox";

const Home = ({ userData }) => {
  return (
    <div className="home-wrapper">
      <HomeProfileBox userData={userData} />
      <HomeModules />
    </div>
  );
};
export default Home;