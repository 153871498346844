export const expenseSearch = {
  user: ["user", "fullName"],
  receiver: ["receiver", "fullName"],
  category: ["category", "name"],
  description: ["description"],
  amount: ["amount"],
  status: ["state"],
};

export const complaintSearch = {
  user: ["id"],
  site: ["station", "name"],
  fieldTechnician: ["fieldTechnician", "fullName"],
  category: ["category", "name"],
  description: ["description"],
  status: ["status"],
};

export const visitSearch = {
  user: ["station", "id"],
  site: ["station", "name"],
  category: ["category", "name"],
  fieldTechnician: ["fieldTechnician", "fullName"],
  workDone: ["workDone"],
  status: ["status"],
};

export const siteSearch = {
  user: ["id"],
  site: ["name"],
  category: ["category", "name"],
  subDivisionName: ["subDivisionName"],
  fieldTechnician: ["jbccFieldTechnician", "fullName"],
};

export const machineSearch = {
  id: ["id"],
  machine: ["station", "name"],
};

export const userSearch = {
  user: ["fullName"],
};

export const uploadSheetSearch = {
  user: ["createdBy", "fullName"],
};

export const officerSearch = {
  officer: ["fullName"],
  id: ["id"],
};
