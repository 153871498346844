import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import logo from "../../assets/images/logo.svg";
import { menuList } from "../../utils/menubar/menuList";
import whiteArrow from "../../assets/icons/white-arrow.svg";
import { logoutHandler } from "../../functions/logoutHandler";
import logout from "../../assets/icons/logout-white.svg";
import closeArrow from "../../assets/icons/closeArrow.svg";
import { handleClickOutside } from "../../functions/handleClickOutside";
import UsePermissionsClaim from "../../CustomHooks/UsePermissionsClaim";

const SideBar = ({ openSidebar, setOpenSidebar }) => {
  const [open, setOpen] = useState(-1);
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;
  const { checkPermission } = UsePermissionsClaim();

  const permViewUser = checkPermission("view_user");
  const permAddUser = checkPermission("add_user");
  const permAddOfficer = checkPermission("add_officer");
  const permViewComplaint = checkPermission("view_complaint");
  const permAddComplaint = checkPermission("addComplaint");
  const permCheckComplaint = checkPermission("resolve_complaint");
  const permCheckVisit = checkPermission("check_visit");
  const permAddSite = checkPermission("add_site");
  const permViewSite = checkPermission("view_site");
  const permAddMachine = checkPermission("add_machine");
  const permAddHier = checkPermission("add_hier");
  const permAddSheets = checkPermission("add_sheets");
  const permViewSheets = checkPermission("view_sheets");
  const permViewMachines = checkPermission("view_machine");
  const permAddMachineComp = checkPermission("add_machine_component");

  const mainMenuPermMap = {
    user: permViewUser || permAddUser || permAddOfficer,
    Complaint:
      permAddComplaint ||
      permViewComplaint ||
      permCheckComplaint ||
      permCheckVisit,
    "Expense Sheet": permViewSheets || permAddSheets,
    "Site Manager":
      permAddSite ||
      permViewSite ||
      permAddMachine ||
      permAddHier ||
      permViewMachines ||
      permAddMachineComp,
  };
  const checkExpenseHistory =
    checkPermission("sup_check") ||
    checkPermission("admin_check") ||
    checkPermission("sup_admin_check");

  const subMenuPermMap = {
    Users: checkPermission("view_user"),
    "Add User": checkPermission("add_user"),
    "Add Officer": checkPermission("add_officer"),
    Officers: checkPermission("add_officer"),
    "Add Expense": checkPermission("add_expense"),
    "Supervisor Pending": checkPermission("sup_check"),
    "Admin Pending": checkPermission("admin_check"),
    "Super Admin Pending": checkPermission("sup_admin_check"),
    "Add Loan": checkPermission("add_loan"),
    "Add Deduction": checkPermission("add_deduction_expense"),
    Complaints: checkPermission("view_complaint"),
    "Add Complaint": checkPermission("add_complaint"),
    "Complaint Pending": checkPermission("resolve_complaint"),
    "Visit Pending": checkPermission("check_visit"),
    Visits: checkPermission("view_visit"),
    Sites: checkPermission("view_site"),
    "Add Site": checkPermission("add_site"),
    "Add Machine": checkPermission("add_machine"),
    "Assign Machine": checkPermission("add_machine"),
    "Add Jurisdiction": checkPermission("add_hier"),
    "Upload Sheets": checkPermission("add_sheets"),
    "Uploaded Sheets List": checkPermission("view_sheets"),
    Machines: checkPermission("view_machine"),
    "Add Machine Component": checkPermission("add_machine_component"),
    "Add Loan Repayment": checkPermission("add_loan_expense"),
    "Check History": checkExpenseHistory,
  };
  const isCurrentRoute = (subMenu) => {
    if (subMenu && open === -1) {
      return subMenu.some((subItem) => subItem.route === currentUrl);
    } else {
      return false;
    }
  };

  const handleDropdown = (ind) => {
    setOpen((prevIndex) => (prevIndex === ind ? -1 : ind));
  };
  const handleMenu = () => {
    setOpenSidebar(false);
  };
  const handleSidebar = () => {
    setOpen(-1);
    setOpenSidebar(false);
  };
  useEffect(() => {
    handleClickOutside([openSidebar, setOpenSidebar]);
  }, [setOpenSidebar, openSidebar]);

  return (
    <>
      <div className={`sidebar-main-1 ${openSidebar ? "sidebar-main-2" : ""}`}>
        <div
          className={`modal-content sidebar-wrap ${
            openSidebar ? "2xs:max-md:left-0" : "2xs:max-md:left-400n"
          }`}
        >
          <div className="sidebar-logo-wrap">
            <Link to="/">
              <img src={logo} alt="logo" className="sidebar-logo" />
            </Link>
            <img
              src={closeArrow}
              alt="closeBar"
              className="md:hidden"
              onClick={handleSidebar}
            />
          </div>
          <div className="menu-wrap">
            {menuList.map((menu, index) => {
              if (!mainMenuPermMap[menu.menuTitle] && menu.anyPerm) return null;
              return (
                <div key={index}>
                  <Link
                    onClick={() =>
                      menu.route ? handleSidebar() : handleDropdown(index)
                    }
                    className={`main-menu ${
                      open === index || isCurrentRoute(menu.subMenu)
                        ? "active-filter"
                        : ""
                    }`}
                    to={menu.route && menu.route}
                  >
                    <img
                      src={menu.menuIcon}
                      alt="menuIcon"
                      className="menu-ico"
                    />
                    <span className="pl-2">{menu.menuTitle}</span>
                    {menu.subMenu && (
                      <img
                        src={whiteArrow}
                        className={`white-icon-filter menu-arr ${
                          open === index || isCurrentRoute(menu.subMenu)
                            ? "rotate-180"
                            : ""
                        } `}
                        alt="arrow"
                      />
                    )}
                  </Link>
                  {menu.subMenu && (
                    <ul
                      className={`subMenu--ul ${
                        open === index || isCurrentRoute(menu.subMenu)
                          ? `ease-in ${menu.height} visible py-3`
                          : "ease-out h-0 invisible"
                      } `}
                    >
                      {menu.subMenu.map((submenu, ind) => {
                        if (
                          !subMenuPermMap[submenu.menuTitle] &&
                          submenu.anyPerm
                        )
                          return null;
                        return (
                          <li key={ind} onClick={handleMenu}>
                            <Link
                              className={`subMenu-link ${
                                currentUrl === submenu.route ? "bg-lb3" : ""
                              }`}
                              to={submenu.route && submenu.route}
                            >
                              <img
                                src={submenu.menuIcon}
                                alt="submenuIcon"
                                className="subMenuIco"
                              />
                              <span className="pl-2">{submenu.menuTitle}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              );
            })}
            <Link
              onClick={() => logoutHandler(navigate)}
              className="logout-link"
            >
              <img
                src={logout}
                alt="logout"
                className="logout-ico white-icon-filter"
              />
              <span className="pl-2">Logout</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default SideBar;
