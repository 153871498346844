import React, { forwardRef } from "react";
import closeIcon from "../../../assets/icons/close-blue.svg";
import ViewModal from "../../portal/ViewModal";

const ImageDialog = forwardRef(function ImageDialog(
  { image, closeDialog },
  ref
) {
  return (
    <ViewModal ref={ref} closeDialog={closeDialog}>
      <div className="img-dialog-container">
        <div className="img-dialog-wrap">
          <img src={image} alt="viewImage" className="img-dialog" />
          <img
            src={closeIcon}
            alt="close"
            className="img-dialog-closeIco"
            onClick={closeDialog}
          />
        </div>
      </div>
    </ViewModal>
  );
});
export default ImageDialog;