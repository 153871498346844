import arrow from "../../assets/icons/arrowLeft.svg";
import { useNavigate } from "react-router-dom";

function PageTitle({ pageTitle, numberOfData }) {
  const navigate = useNavigate();
  const handleBackButtonClick = () => {
    navigate(-1);
  };
  return (
    <div className="backBtn">
      <img
        src={arrow}
        alt="arrow"
        className="w-3 cursor-pointer"
        onClick={handleBackButtonClick}
      />
      <h2 className="pageTitleHeading">
        {pageTitle}{" "}
        {numberOfData ? (
          <span className="text-sm">
            ({numberOfData > 0 ? numberOfData : 0})
          </span>
        ) : (
          ""
        )}
      </h2>
    </div>
  );
}

export default PageTitle;
