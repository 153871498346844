import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import CenteredHeading from "../UI/headingsUI/CenteredHeading";
import GrayButton from "../UI/buttonUI/GrayButton";
import CustomFileInput from "../UI/PageInput/CustomFileInput";
import SelectedImagesBox from "../expense/add/SelectedImagesBox";
import SearchDropdown from "../UI/PageInput/SearchDropdown";
import successIcon from "../../assets/icons/successIcon.svg";
import SheetErrors from "./SheetErrors";
import close from "../../assets/icons/close-black.svg";
import SheetView from "../UI/viewDialogs/expense/SheetView";
import { getCompressImage } from "../../functions/getCompressImage";
import SubmitButton from "../UI/buttonUI/SubmitButton";

const UploadSheets = ({
  setIsSucceed,
  isSucceed,
  setSheetDetail,
  sheetDetail,
  files,
  setFiles,
  sheetSetup,
  loading,
  sendData,
  navigate,
  responseData,
  isErrorSheetList,
  setErrorSheetList,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      pictures: [], // Initialize pictures as an empty array
    },
  });

  const [selectedOption, setSelectedOption] = useState({});
  const [isFormClear, setIsFormClear] = useState(false);

  let pic = watch("pictures");

  useEffect(() => {
    watch(
      (value) => {
        const excludedKeys = ["pictures"]; // Keys to exclude from update by default
        const updatedDetail = Object.fromEntries(
          Object.entries(value).filter(([key]) => !excludedKeys.includes(key))
        );
        setSheetDetail({
          ...sheetDetail,
          ...updatedDetail,
          sheet: value.sheet ? value.sheet[0] : undefined,
        });
      },
      [watch]
    );
  }, [watch]);

  useEffect(() => {
    getCompressImage(setFiles, pic, "pictures");
  }, [pic, setFiles]);

  const onSubmit = () => {
    sendData(reset, setSelectedOption);
  };

  // handle clear form
  const handleClearForm = () => {
    setSheetDetail({});
    setSelectedOption({});
    setFiles({});
    setIsFormClear(!isFormClear);
    // reset form value
    reset();
  };

  const removeSelectedFile = () => {
    setValue("sheet", "");
    setFiles((prev) => ({ ...prev, sheet: undefined }));
  };

  return (
    <div className="form-container">
      <CenteredHeading title="Upload Sheets" />
      <form
        className="max-w-3xl mx-auto"
        onSubmit={!loading ? (e) => handleSubmit(onSubmit)(e) : undefined}
      >
        <CustomFileInput
          name="sheet"
          placeholder="Add File* (file format .xlsx)"
          accept=".xlsx, file/xlsx"
          register={{
            ...register("sheet", {
              required: "Please choose a file",
            }),
          }}
          setValue={setValue}
          multiple={true}
          error={errors.sheet}
        />
        {sheetDetail.sheet && (
          <span className="uploaded--sheet-name">
            {sheetDetail.sheet.name}
            <img
              src={close}
              alt="close"
              className="remove--uploaded--sheet"
              onClick={removeSelectedFile}
            />
          </span>
        )}
        <CustomFileInput
          name="pictures"
          placeholder="Add Image* (jpg, png)"
          accept=".png, .jpg, image/png, image/jpeg"
          register={{
            ...register("pictures", {
              required: files.pictures
                ? files.pictures?.length === 0
                  ? "Please choose a file"
                  : false
                : "Please choose a file",
            }),
          }}
          multiple={true}
          error={errors.pictures}
        />
        <SelectedImagesBox
          errors={errors.pictures}
          selectedImages={files.pictures}
          setFiles={setFiles}
          setValue={setValue}
          name="pictures"
        />
        <p className="pt-2">
          <SearchDropdown
            name="category_id"
            label="Category *"
            keyName="name"
            id="id"
            options={sheetSetup}
            value={sheetDetail.category_id}
            setSelectedOption={setSelectedOption}
            selectedOption={selectedOption}
            setValue={setValue}
            register={{
              ...register("category_id", {
                required: "Please select an option",
              }),
            }}
            error={errors.category_id}
          />
        </p>
        <div className="sheet-upload-btn-wrap">
          <p className="pr-2">
            <SubmitButton
              btnText={!loading ? "Upload Sheet" : "Adding..."}
              loading={loading}
              height="h-12"
            />
          </p>
          <p className="pl-2">
            <GrayButton
              btnText="Clear"
              width="w-40"
              onClick={handleClearForm}
            />
          </p>
        </div>
      </form>
      {isErrorSheetList && (
        <SheetErrors
          errorList={responseData.sheetErrors}
          closeAction={() => setErrorSheetList(false)}
        />
      )}
      {isSucceed && (
        <SheetView
          viewDetails={responseData}
          message="Successfully Sheet Uploaded!"
          messageIcon={successIcon}
          btnText="Ok"
          closeDialog={() => navigate("/sheet/uploaded-all")}
          modalNotClose={() => setIsSucceed(true)}
          successView={true}
          isLoaded={true}
        />
      )}
    </div>
  );
};

export default UploadSheets;
