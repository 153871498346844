import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import sortByDate from "../functions/sorting/arrowSorting/sortByDate";
import sortByAlphabetically from "../functions/sorting/arrowSorting/sortByAlphabetically";
import sortByNumber from "../functions/sorting/arrowSorting/sortByNumber";
import { updateData } from "../redux/dataSlice";
import sortArrow from "../assets/icons/sort-arrow.svg";
import { setSortStatus } from "../redux/dataSlice";

const ArrowSorting = ({ sortBy, address }) => {
  const dispatch = useDispatch();
  const [isArrowToggled, setIsArrowToggled] = useState(false);
  const data = useSelector((state) => state.data.data);

  const handleSorting = () => {
    dispatch(setSortStatus(true));
    setIsArrowToggled((arrowToggle) => !arrowToggle);
    if (sortBy === "date") {
      dispatch(updateData(sortByDate(data, isArrowToggled, address)));
    } else if (sortBy === "alphabetically") {
      dispatch(updateData(sortByAlphabetically(data, isArrowToggled, address)));
    } else if (sortBy === "number") {
      dispatch(updateData(sortByNumber(data, isArrowToggled, address)));
    }
  };

  return (
    <>
      <img
        src={sortArrow}
        alt="sortArrow"
        className={`w-5 cursor-pointer ${
          isArrowToggled ? "pl-2" : "rotate-180 pr-2"
        }`}
        onClick={handleSorting}
      />
    </>
  );
};

export default ArrowSorting;
