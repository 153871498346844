import closeIcon from "../../../../assets/icons/close-blue.svg";
import DetailRow from "../DetailRow";
import HeadingDialog from "../HeadingDialog";
import { timeStampToDateAndTime } from "../../../../functions/dateTime/timeStampToDateAndTime";
import { getComplaintStatus } from "../../../../functions/getComplaintStatus";
import { checkValue } from "../../../../functions/checkValue";
import Modal from "../../../portal/Modal";
import GradientButton from "../../buttonUI/GradientButton";
import GrayButton from "../../buttonUI/GrayButton";
import Remarks from "../../../remark/Remarks";
import { COMPLAINT_REMARK } from "../../../../api/allApi_List";
import PictureList from "../PictureList";
import { formatDate } from "../../../../functions/dateTime/formatDate";

export default function ComplaintViewDialog({
  closeDialog,
  viewDetails,
  openImageSlider,
  setImageSliderCurrentIndex,
  successView,
  message,
  modalNotClose,
  accept,
  reject,
  messageIcon,
  updateStateOnAddRemark,
  setComplaintViewDetails,
}) {
  return (
    <Modal closeDialog={!successView ? closeDialog : modalNotClose}>
      <div className="view-dialog-content absolute-center-align">
        {!successView && <HeadingDialog heading="Complaint Details" />}
        <div
          className={`${
            viewDetails.pictureList ? "h-500" : "h-400"
          } px-2 overflow-auto`}
        >
          {successView && (
            <>
              <img
                src={messageIcon}
                alt="messageIcon"
                className="mx-auto mb-1 w-9"
              />

              <p className="view-success-msg-d">{message}</p>
            </>
          )}
          <div className="view-dialog-content-wrap">
            <DetailRow title="Complaint ID" value={viewDetails.id} />
            <div
              className={`${getComplaintStatus(viewDetails.status).textColor}`}
            >
              <DetailRow
                title="Status"
                value={getComplaintStatus(viewDetails.status).status}
              />
            </div>
            <DetailRow title="Category" value={viewDetails.category.name} />
            <DetailRow
              title="Description"
              value={checkValue(viewDetails.description)}
              textJustify="text-justify"
              widthDesc="w-64"
            />
            <DetailRow
              title="Site Id"
              value={checkValue(viewDetails.station.id)}
            />
            <DetailRow
              title="Site Name"
              value={checkValue(viewDetails.station.name)}
            />
            <DetailRow
              title="Assignee"
              value={checkValue(viewDetails.fieldTechnician.fullName)}
            />
            <DetailRow
              title="Created At"
              value={timeStampToDateAndTime(viewDetails.createdAt)}
            />
            {viewDetails.checkedAt && (
              <DetailRow
                title="Checked At"
                value={timeStampToDateAndTime(viewDetails.checkedAt)}
              />
            )}
            <DetailRow
              title="Created By"
              value={checkValue(viewDetails.createdBy.fullName)}
            />
            <DetailRow
              title="Received At"
              value={formatDate(viewDetails.receivedAt)}
            />
            <DetailRow
              title="Received By"
              value={viewDetails.receiver.fullName}
            />
            <DetailRow
              title="Phone No."
              value={checkValue(viewDetails.complainantPhoneNumber)}
            />
            <DetailRow
              title="Last Updated"
              value={timeStampToDateAndTime(viewDetails.updatedAt)}
            />
            <DetailRow
              title="Actual fault"
              value={checkValue(viewDetails.actualFault)}
            />
            <DetailRow
              title="Office Remark"
              value={checkValue(viewDetails.officeRemark)}
            />
            <DetailRow
              title="Number of visit"
              value={checkValue(
                viewDetails.visitIdList ? viewDetails.visitIdList.length : 0
              )}
            />
          </div>
          {viewDetails.pictureList && (
            <PictureList
              pictureList={viewDetails.pictureList}
              openImageSlider={openImageSlider}
              setImageSliderCurrentIndex={setImageSliderCurrentIndex}
            />
          )}
          <Remarks
            remarkList={viewDetails.remarks}
            dataId={viewDetails.id}
            apiUrl={COMPLAINT_REMARK}
            updateStateOnAddRemark={updateStateOnAddRemark}
            setViewDetails={setComplaintViewDetails}
          />
        </div>
        {accept && reject && (
          <div className="view-dialog-btn-wrap flex-center-align">
            <div className="px-2  2xs:max-sm:p-1">
              <GradientButton
                btnText="Complaint Resolved"
                width="w-48 2xs:max-sm:w-full"
                rounded="rounded-lg"
                onClick={accept}
              />
            </div>
            <div className="px-2 2xs:max-sm:p-1">
              <GrayButton
                btnText="Reject"
                width="w-32 2xs:max-sm:w-20"
                onClick={reject}
              />
            </div>
          </div>
        )}
        {successView && (
          <div className="mt-5 pt-3 text-center">
            <GradientButton
              btnText="Ok"
              width="w-56"
              rounded="rounded-full"
              onClick={closeDialog}
            />
          </div>
        )}
        {!successView && (
          <img
            src={closeIcon}
            alt="close"
            className="view-dialog-close"
            onClick={closeDialog}
          />
        )}
      </div>
    </Modal>
  );
}
