const sortByAlphabetically = (data, isArrowToggled, address) => {
  const sortOrder = !isArrowToggled ? -1 : 1;
  const getNestedProperty = (data, address) => {
    return address.reduce((obj, key) => obj && obj[key], data);
  };
  let sortedData = data.slice().sort((a, b) => {
    let aData = getNestedProperty(a, address);
    let bData = getNestedProperty(b, address);

    if (aData < bData) {
      return sortOrder;
    }
    if (aData > bData) {
      return -sortOrder;
    }
    return 0;
  });
  return sortedData;
};
export default sortByAlphabetically;
