import React, { useEffect } from "react";
import GradientButton from "../../buttonUI/GradientButton";
import GrayButton from "../../buttonUI/GrayButton";
import { useForm } from "react-hook-form";
import CustomDescriptionInput from "../../PageInput/CustomDescriptionInput";
import Modal from "../../../portal/Modal";

const VisitCheckConfirm = ({
  title,
  cancelOnclick,
  checkAction,
  setBodyData,
  checkLoading,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    watch((value) => {
      setBodyData((prev) => ({ ...prev, ...value }));
    });
  }, [watch]);

  const onSubmit = () => {
    checkAction();
  };
  return (
    <Modal closeDialog={cancelOnclick}>
      <div className="visit-check-confirm absolute-center-align">
        <div className="px-10">
          <h1 className="visit-check-confirm-title">{title}</h1>
          <form>
            <CustomDescriptionInput
              name="remark"
              labelText="Remark *"
              register={{
                ...register("remark", {
                  required: "Please Enter Description",
                }),
              }}
              error={errors.remark}
            />
          </form>
          <div className="visit-check-confirmBtn-wrap flex-center-align">
            <div className="p-2 w-full">
              <GradientButton
                btnText={!checkLoading ? "Confirm" : "Loading..."}
                width="w-full"
                rounded="rounded-md"
                onClick={!checkLoading ? handleSubmit(onSubmit) : undefined}
              />
            </div>
            <div className="p-2 w-full">
              <GrayButton
                btnText="Cancel"
                width="w-full"
                onClick={cancelOnclick}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VisitCheckConfirm;
