import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useGetData from "../../CustomHooks/useAxios/useGetData";
import DialogBox from "../../components/UI/DialogBox";
import spin from "../../assets/icons/spin.png";
import { MACHINE_SETUP } from "../../api/allApi_List";
import { ADD_MACHINE } from "../../api/allApi_List";
import AddMachine from "../../components/site/AddMachine";
import { handleErrMessage } from "../../functions/handleErrMessage";
import usePostData from "../../CustomHooks/useAxios/usePostData";
import MainPageLayout from "../../components/MainPageLayout";

const AddMachinePage = () => {
  const navigate = useNavigate();
  const { data, isLoaded, error, getData, errMsg } = useGetData();
  const {
    executePost,
    loading,
    isSucceed,
    formError,
    formErrMsg,
    responseData,
    setIsSucceed,
    setFormError,
    setResponseData,
  } = usePostData();

  useEffect(() => {
      getData(MACHINE_SETUP, navigate);
  }, []);

  const sendData = async (formValue, reset, setSelectedOption) => {
    const updateStateOnResponse = () => {
      setSelectedOption({});
      // reset form value
      reset();
    };
    // API calling
    executePost(formValue, ADD_MACHINE, updateStateOnResponse);
  };

  return (
    <MainPageLayout headerConfig={{title:"Add Machine"}}>
      {isLoaded && !error ? (
        <AddMachine
          machineSetup={data}
          sendData={sendData}
          setIsSucceed={setIsSucceed}
          isSucceed={isSucceed}
          loading={loading}
          responseData={responseData}
          navigate={navigate}
          setResponseData={setResponseData}
        />
      ) : error ? (
        <DialogBox
          title="Error"
          message={handleErrMessage(errMsg)}
          isErrorHandleButtons={true}
        />
      ) : (
        <DialogBox message="Loading your data" animateIcon={spin} />
      )}
      {formError && (
        <DialogBox
          title="Error"
          btnText="Ok"
          message={handleErrMessage(formErrMsg)}
          closeAction={() => setFormError(false)}
        />
      )}
    </MainPageLayout>
  );
};

export default AddMachinePage;
