import TableRow from "./TableRow";

const BalanceTable = ({
  balances,
  userName,
  receiverName,
  expenseCategory,
  expenseUserId,
  expenseReceiverId,
  superVisorId,
  adminId,
}) => {
  const userLoginId = parseInt(localStorage.getItem("userId"));
  const isAccountAndCashAndPendingCategory =
    expenseCategory === 1 || expenseCategory === 2;
  const isSupervisorAdminOrCompanyLoggedIn =
    superVisorId === userLoginId ||
    adminId === userLoginId ||
    userLoginId === 1;

  const labels = [
    " Initial Loan Balance (U)",
    " Final Loan Balance (U)",
    " Initial Total Balance (U)",
    " Final Total Balance (U)",
    " Initial Loan Balance (R)",
    " Final Loan Balance (R)",
    " Initial Total Balance (R)",
    " Final Total Balance (R)",
  ];

  const shortLabels = [
    " Initial Balance (U)",
    " Final Balance (U)",
    " Initial Balance (R)",
    " Final Balance (R)",
  ];
  // Function to style specific parts of the label
  const styledLabel = (label) => {
    return label.replace(
      /\((U|R)\)/g,
      (match) => `<span style="color: gray; font-size: 14px;">${match}</span>`
    );
  };

  console.log(balances, "balances");

  // Helper function to render rows based on conditions
  const renderRow = (label, value, isAlternate, index) => (
    <TableRow
      key={index}
      label={<span dangerouslySetInnerHTML={{ __html: styledLabel(label) }} />} // Applying gray color using HTML
      value={value}
      isAlternate={isAlternate}
    />
  );
  /// Handle Add Loan Repayment category balances
  if (expenseCategory === 3) {
    return (
      <>
        {balances.map((balance, index) => {
          const name = index < 4 ? userName : receiverName;
          const shouldRender =
            (userLoginId !== 1 && index < 4) || userLoginId === 1;
          return shouldRender
            ? renderRow(name + labels[index], balance, index % 2 === 1, index)
            : null;
        })}
      </>
    );
  }
  /// Handle Account, Cash, pending category balances
  if (isAccountAndCashAndPendingCategory) {
    const displayUserRowsByAccountAndCash = userLoginId === expenseUserId;
    const displayReceiverRowsByAccountAndCash =
      userLoginId === expenseReceiverId;
    const isUserOrReceiverLoggedIn =
      displayUserRowsByAccountAndCash || displayReceiverRowsByAccountAndCash;

    return isUserOrReceiverLoggedIn && !isSupervisorAdminOrCompanyLoggedIn ? (
      <>
        {displayUserRowsByAccountAndCash && (
          <>
            {renderRow(userName + shortLabels[0], balances[0], false, 0)}
            {renderRow(userName + shortLabels[1], balances[1], true, 1)}
          </>
        )}
        {displayReceiverRowsByAccountAndCash && (
          <>
            {renderRow(receiverName + shortLabels[2], balances[2], false, 2)}
            {renderRow(receiverName + shortLabels[3], balances[3], true, 3)}
          </>
        )}
      </>
    ) : (
      <>
        {(expenseUserId !== 1 || userLoginId === 1) && (
          <>
            {renderRow(userName + shortLabels[0], balances[0], false, 0)}
            {renderRow(userName + shortLabels[1], balances[1], true, 1)}
          </>
        )}
        {renderRow(receiverName + shortLabels[2], balances[2], false, 2)}
        {renderRow(receiverName + shortLabels[3], balances[3], true, 3)}
      </>
    );
  }

  // Handle loan and Pending Category
  if (expenseCategory === 15 || expenseCategory === 4) {
    return (
      <>
        {balances.map((balance, index) => {
          const name = index < 2 ? userName : receiverName;
          const shouldRender =
            (userLoginId !== 1 && index > 1) || userLoginId === 1;
          return shouldRender
            ? renderRow(name + labels[index], balance, index % 2 === 1, index)
            : null;
        })}
      </>
    );
  }

  // Handle all other categories
  if (expenseUserId === 1 && userLoginId !== 1) {
    // only company can see balance
    return null;
  } else {
    return (
      <>
        {renderRow(userName + shortLabels[0], balances[0], false, 0)}
        {renderRow(userName + shortLabels[1], balances[1], true, 1)}
      </>
    );
  }
};
export default BalanceTable;
