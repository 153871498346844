import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import CustomInput from "../../UI/PageInput/CustomInput";
import CustomDescriptionInput from "../../UI/PageInput/CustomDescriptionInput";
import ComplaintDate from "../../UI/PageInput/ComplaintDate";
import successIcon from "../../../assets/icons/successIcon.svg";
import CenteredHeading from "../../UI/headingsUI/CenteredHeading";
import SubmitButton from "../../UI/buttonUI/SubmitButton";
import GrayButton from "../../UI/buttonUI/GrayButton";
import CustomFileInput from "../../UI/PageInput/CustomFileInput";
import SelectedImagesBox from "../../expense/add/SelectedImagesBox";
import SearchDropdown from "../../UI/PageInput/SearchDropdown";
import ImageSlider from "../../UI/viewDialogs/ImageSlider";
import ComplaintViewDialog from "../../UI/viewDialogs/complaint/ComplaintViewDialog";
import UseDialogBox from "../../../CustomHooks/UseDialogBox";
import { getCompressImage } from "../../../functions/getCompressImage";

const AddComplaint = ({
  setIsSucceed,
  isSucceed,
  files,
  setFiles,
  complaintSetup,
  loading,
  sendData,
  navigate,
  responseData,
  setResponseData,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      pictures: [], // Initialize pictures as an empty array
    },
  });

  const [selectedOption, setSelectedOption] = useState({});
  const [isFormClear, setIsFormClear] = useState(false);
  const { showDialogBox, setShowDialogBox } = UseDialogBox();
  const [imageSliderPics, setImageSliderPics] = useState([]);
  const [imageSliderCurrentIndex, setImageSliderCurrentIndex] = useState();

  const pic = watch("pictures");

  useEffect(() => {
    getCompressImage(setFiles, pic, "pictures");
  }, [pic, setFiles]);

  const onSubmit = (data) => {
    sendData(data, reset, setSelectedOption);
  };

  // handle clear form
  const handleClearForm = () => {
    setSelectedOption({});
    setFiles({});
    setIsFormClear(!isFormClear);
    // reset form value
    reset();
  };

  const openImageSlider = () => {
    setIsSucceed(false);
    setShowDialogBox(true);
    setImageSliderPics(responseData.pictureList);
  };

  const closeImageSlider = () => {
    setIsSucceed(true);
    setShowDialogBox(false);
  };
  return (
    <div className="form-container">
      <CenteredHeading title="Add Complaint" />
      <form
        className="2xs:max-lg:pt-0"
        onSubmit={!loading ? (e) => handleSubmit(onSubmit)(e) : undefined}
      >
        <div className="flex flex-wrap">
          <div className="complaintLeftCol">
            <div className="sm:flex">
              <div className="w-full sm:pr-1">
                <SearchDropdown
                  name="station_id"
                  label="Site Name *"
                  keyName="name"
                  id="id"
                  options={complaintSetup.stations}
                  setSelectedOption={setSelectedOption}
                  selectedOption={selectedOption}
                  setValue={setValue}
                  register={{
                    ...register("station_id", {
                      required: "Please select an option",
                    }),
                  }}
                  error={errors.station_id}
                  search={true}
                />
              </div>
              <div className="w-full sm:pl-1">
                <SearchDropdown
                  name="category_id"
                  label="category *"
                  keyName="name"
                  id="id"
                  options={complaintSetup.categories}
                  setSelectedOption={setSelectedOption}
                  selectedOption={selectedOption}
                  setValue={setValue}
                  register={{
                    ...register("category_id", {
                      required: "Please select an option",
                    }),
                  }}
                  error={errors.category_id}
                />
              </div>
            </div>
            <div className="sm:flex">
              <div className="w-full sm:pr-1">
                <SearchDropdown
                  name="receiver_id"
                  label="Received By *"
                  keyName="fullName"
                  id="id"
                  options={complaintSetup.users}
                  setSelectedOption={setSelectedOption}
                  selectedOption={selectedOption}
                  setValue={setValue}
                  register={{
                    ...register("receiver_id", {
                      required: "Please select an option",
                    }),
                  }}
                  error={errors.receiver_id}
                  search={true}
                />
              </div>
              <div className="w-full sm:pl-1">
                <ComplaintDate
                  name={"received_at"}
                  setValue={setValue}
                  register={{
                    ...register("received_at", {
                      required: "Please Select date",
                    }),
                  }}
                  error={errors.received_at}
                  isFormClear={isFormClear}
                />
              </div>
            </div>
            <CustomDescriptionInput
              name="description"
              labelText="Description *"
              register={{
                ...register("description", {
                  required: "Please Enter Description",
                  validate: (value) => {
                    if (value.trim() === "") {
                      return "Description cannot be empty or just spaces";
                    }
                    return true;
                  },
                }),
              }}
              error={errors.description}
            />
          </div>
          <div className="complaintRightCol">
            <CustomFileInput
              name="pictures"
              placeholder="Add Image* (jpg, png)"
              accept=".png, .jpg, image/png, image/jpeg"
              register={{
                ...register("pictures"),
              }}
              multiple={true}
            />
            <SelectedImagesBox
              errors={errors.pictures}
              selectedImages={files.pictures}
              setFiles={setFiles}
              setValue={setValue}
              name="pictures"
            />
          </div>
        </div>
        <div className="flex flex-wrap 2xs:max-md:pt-2">
          <div className="complaintLeftCol">
            <CustomInput
              type="text"
              name="actual_fault"
              labelText="Actual Fault"
              placeholder="Enter"
              register={{
                ...register("actual_fault", {
                  validate: (value) => {
                    if (value && value.trim() === "") {
                      return "Actual fault cannot contain only spaces";
                    }
                    return true;
                  },
                }),
              }}
              error={errors.actual_fault}
            />
          </div>
          <div className="complaintRightCol">
            <div className="sm:flex">
              <div className="w-full sm:pr-1">
                <CustomInput
                  type="text"
                  name="office_remark"
                  labelText="Office Remark"
                  placeholder="Enter"
                  register={{
                    ...register("office_remark", {
                      validate: (value) => {
                        if (value && value.trim() === "") {
                          return "Office Remark cannot contain only spaces";
                        }
                        return true;
                      },
                    }),
                  }}
                  error={errors.office_remark}
                />
              </div>
              <div className="w-full sm:pl-1">
                <CustomInput
                  type="number"
                  name="complainant_phone_number"
                  labelText="Phone No."
                  placeholder="Enter"
                  register={{
                    ...register("complainant_phone_number", {
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Please enter a valid 10-digit phone number.",
                      },
                    }),
                  }}
                  error={errors.complainant_phone_number}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="complaintBtnWrap">
          <p className="pr-2">
            <SubmitButton
              btnText={!loading ? "Add Complaint" : "Adding..."}
              loading={loading}
              height="h-12"
            />
          </p>
          <p className="pl-2">
            <GrayButton
              btnText="Clear"
              width="w-40"
              onClick={handleClearForm}
            />
          </p>
        </div>
      </form>
      {isSucceed && (
        <ComplaintViewDialog
          closeDialog={() => navigate("/complaint/all")}
          modalNotClose={() => setIsSucceed(true)}
          viewDetails={responseData}
          openImageSlider={openImageSlider}
          setImageSliderCurrentIndex={setImageSliderCurrentIndex}
          successView={true}
          message="Complaint Successfully Created!"
          messageIcon={successIcon}
          setComplaintViewDetails={setResponseData}
        />
      )}
      {showDialogBox && (
        <ImageSlider
          title="Expense Photos"
          images={imageSliderPics}
          imageSliderCurrentIndex={imageSliderCurrentIndex}
          closeDialogBox={closeImageSlider}
        />
      )}
    </div>
  );
};
export default AddComplaint;
