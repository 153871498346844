import React from "react";

export default function UserDetails(props) {
  return (
    <p className="font-poppins pb-1 pl-3 sm:max-lg:text-sm sm:max-lg:pl-2 2xs:max-base:text-xs text-black text-sm">
      <span className="text-dark-blue font-semibold">{props.title} : </span>
      {props.value}
    </p>
  );
}
