import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import checkIcon from "../../../assets/icons/checkIcon.svg";
import ArrowSorting from "../../ArrowSorting";
import AllAcceptReject from "./AllAcceptReject";
import UsePermissionsClaim from "../../../CustomHooks/UsePermissionsClaim";

export default function TableHead({
  headList,
  isMultipleCheck,
  isAllCheck,
  setAllCheck,
  setCheckedRow,
  checkedRow,
  setCheckedData,
  checkedType,
  setCheckedType,
}) {
  const data = useSelector((state) => state.data.data);
  const dataIds = data.map((item) => item.id);
  const handleAllChecks = () => {
    setAllCheck((prev) => {
      // !prev ? setCheckedRow(dataIds) : setCheckedRow([]);
      if (!prev && (checkedType.accept || checkedType.reject)) {
        const getIdsStatus = checkedType.accept ? true : false;
        const updatedCheckedData = dataIds.map((id) => ({
          id: id,
          status: getIdsStatus,
        }));
        setCheckedData(updatedCheckedData);
        setCheckedRow(dataIds);
      } else {
        setCheckedData([]);
        setCheckedType({
          accept: false,
          reject: false,
        });
        setCheckedRow([]);
      }
      return !prev;
    });
  };

  useEffect(() => {
    if (isAllCheck) {
      setCheckedRow(dataIds);
    }
  }, [isAllCheck, setCheckedRow, data]);

  const { checkPermission } = UsePermissionsClaim();
  const addLoanPerm = checkPermission("add_loan");
  const addLoanRepaymentPerm = checkPermission("add_loan_expense");
  const addDeductionPerm = checkPermission("add_deduction_expense");
  const updateUserPerm = checkPermission("update_user");
  const checkAllUserActionsPerm =
    addLoanPerm || addLoanRepaymentPerm || addDeductionPerm || updateUserPerm;

  return (
    <tr className="table-head-row">
      {isMultipleCheck && (
        <th className="py-2 px-1">
          <button
            className={`allCheckBox ${isAllCheck ? "bg-dark-blue" : ""}`}
            onClick={handleAllChecks}
          >
            <img
              src={checkIcon}
              alt="check"
              className={`${isAllCheck ? "" : "opacity-0"}`}
            />
          </button>
        </th>
      )}
      {headList.map((item, ind) => {
        if (
          checkedRow &&
          checkedRow.length > 0 &&
          item.title === "Accept/Reject"
        )
          return (
            <AllAcceptReject
              checkedRow={checkedRow}
              setCheckedData={setCheckedData}
              ind={ind}
              setCheckedType={setCheckedType}
              checkedType={checkedType}
            />
          );
        return !checkAllUserActionsPerm && item.title === "" ? (
          ""
        ) : (
          <th className="table-head-th" key={ind}>
            <span className="flex justify-center">
              {item.title}
              {item.sortArrow && data.length > 1 && (
                <ArrowSorting sortBy={item.sortBy} address={item.address} />
              )}
            </span>
          </th>
        );
      })}
    </tr>
  );
}
