import React, { Fragment } from "react";

export default function CustomDescriptionInput({
  name,
  labelText,
  register,
  error,
}) {
  return (
    <Fragment>
      <label htmlFor={name} className="font-poppins text-dg2 text-sm">
        {labelText}
      </label>
      <p className="relative">
        <textarea
          type="text"
          name={name}
          placeholder="Enter"
          className={`w-full py-2 px-3 h-28 border-bw rounded-md text-sm font-figtree focus-visible:outline-dark-blue placeholder:text-sm placeholder:font-figtree placeholder:text-black ${
            error ? "border-red-500" : "border-lg4"
          }`}
          {...register}
        />
      </p>
      <p
        className={`text-red-500 font-figtree text-xs h-4 ${
          error ? "visible" : "invisible"
        }`}
      >
        {error?.message}
      </p>
    </Fragment>
  );
}
