import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import AddLoanRepayment from "../../components/expense/add/AddLoanRepayment";
import DialogBox from "../../components/UI/DialogBox";
import { handleErrMessage } from "../../functions/handleErrMessage";
import { Add_EXPENSE } from "../../api/allApi_List";
import usePostData from "../../CustomHooks/useAxios/usePostData";
import MainPageLayout from "../../components/MainPageLayout";
import { prepareFormData } from "../../functions/prepareFormData";

const AddLoanRepaymentPage = () => {
  const navigate = useNavigate();
  const [files, setFiles] = useState({});
  const {
    executePost,
    loading,
    isSucceed,
    formError,
    formErrMsg,
    responseData,
    setIsSucceed,
    setFormError,
    setResponseData,
  } = usePostData();

  // execute post api
  const sendData = async (data, reset) => {
    // send data into form data
    const formData = prepareFormData(data, files);
    const updateStateOnResponse = () => {
      setFiles({});
      // reset form value
      reset();
    };
    //API calling
    executePost(formData, Add_EXPENSE, updateStateOnResponse);
  };

  return (
    <MainPageLayout headerConfig={{ title: "Add Loan Repayment" }}>
      <AddLoanRepayment
        files={files}
        setFiles={setFiles}
        sendData={sendData}
        isSucceed={isSucceed}
        loading={loading}
        navigate={navigate}
        setIsSucceed={setIsSucceed}
        responseData={responseData}
        setResponseData={setResponseData}
      />
      {formError && (
        <DialogBox
          title="Error"
          btnText="Ok"
          message={handleErrMessage(formErrMsg)}
          closeAction={() => setFormError(false)}
        />
      )}
    </MainPageLayout>
  );
};

export default AddLoanRepaymentPage;
