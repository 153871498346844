import closeIcon from "../../../../assets/icons/close-blue.svg";
import DetailRow from "../DetailRow";
import HeadingDialog from "../HeadingDialog";
import { timeStampToDateAndTime } from "../../../../functions/dateTime/timeStampToDateAndTime";
import { formatDate } from "../../../../functions/dateTime/formatDate";
import { getVisitStatus } from "../../../../functions/getVisitStatus";
import { checkValue } from "../../../../functions/checkValue";
import Modal from "../../../portal/Modal";
import GradientButton from "../../buttonUI/GradientButton";
import GrayButton from "../../buttonUI/GrayButton";
import Remarks from "../../../remark/Remarks";
import { VISIT_REMARK } from "../../../../api/allApi_List";
import PictureList from "../PictureList";
import GetCheckCross from "../GetCheckCross";
import VisitSigns from "./VisitSigns";

export default function VisitViewDialog({
  closeDialog,
  viewDetails,
  openImageSlider,
  setImageSliderCurrentIndex,
  accept,
  reject,
  updateStateOnAddRemark,
  setVisitViewDetails,
}) {
  const checkCylinderValue = (keyValue) => {
    if (keyValue !== null) {
      return keyValue ? ["Filled", "text-green"] : ["Empty", "text-red-1"];
    }
    return ["-", ""];
  };

  return (
    <Modal closeDialog={closeDialog}>
      <div className="view-dialog-content absolute-center-align">
        <HeadingDialog heading="Visit Details" />
        <div className={`h-500 px-2 overflow-auto`}>
          <div className="view-dialog-content-wrap">
            <DetailRow
              title="Visit Description"
              value={viewDetails.description}
            />
            <div className={`${getVisitStatus(viewDetails.status).textColor}`}>
              <DetailRow
                title="Status"
                value={getVisitStatus(viewDetails.status).status}
              />
            </div>
            <DetailRow title="Site ID" value={viewDetails.station.id} />
            <DetailRow title="Site Name" value={viewDetails.station.name} />

            <DetailRow
              title="Complaint ID"
              value={checkValue(viewDetails.complaint?.id)}
            />
            <DetailRow
              title="Complaint Description"
              value={checkValue(viewDetails.complaint?.description)}
            />
            <DetailRow
              title="Visited At"
              value={formatDate(viewDetails.visitedAt)}
            />
            <DetailRow
              title="Created At"
              value={timeStampToDateAndTime(viewDetails.createdAt)}
            />
            <DetailRow
              title="Created By"
              value={viewDetails.createdBy.fullName}
            />
            <DetailRow
              title="Updated At"
              value={timeStampToDateAndTime(viewDetails.updatedAt)}
            />
            <DetailRow
              title="Updated By"
              value={viewDetails.updatedBy.fullName}
            />
            {viewDetails.checkedAt && (
              <DetailRow
                title="Checked At"
                value={timeStampToDateAndTime(viewDetails.checkedAt)}
              />
            )}
            {viewDetails.checkedBy && (
              <DetailRow
                title="Checked By"
                value={viewDetails.checkedBy.fullName}
              />
            )}
            <DetailRow
              title="Field Technician"
              value={viewDetails.fieldTechnician.fullName}
            />
            <DetailRow title="Category" value={viewDetails.category.name} />
            <DetailRow title="Work Done" value={viewDetails.workDone} />
            <DetailRow
              title="Supply Hours/Day"
              value={checkValue(viewDetails.supplyHours) / 100}
            />
            <DetailRow
              title="Working Cylinder ID"
              value={checkValue(viewDetails.workingCylinderID)}
            />
            <div
              className={`${
                checkCylinderValue(viewDetails.workingCylinderFilled)[1]
              }`}
            >
              <DetailRow
                title="Working Cylinder"
                value={checkCylinderValue(viewDetails.workingCylinderFilled)[0]}
              />
            </div>
            <DetailRow
              title="Standby Cylinder ID"
              value={checkValue(viewDetails.standbyCylinderID)}
            />
            <div
              className={`${
                checkCylinderValue(viewDetails.standbyCylinderFilled)[1]
              }`}
            >
              <DetailRow
                title="Standby Cylinder"
                value={checkCylinderValue(viewDetails.standbyCylinderFilled)[0]}
              />
            </div>
            <DetailRow
              title="Ejector vacuum working"
              value={
                <GetCheckCross checkVal={viewDetails.ejectorVacuumCheck} />
              }
            />
            <DetailRow
              title="Ejector back pressure check"
              value={
                <GetCheckCross
                  checkVal={viewDetails.ejectorBackPressureCheck}
                />
              }
            />
            <DetailRow
              title="Hooter working"
              value={<GetCheckCross checkVal={viewDetails.hooterCheck} />}
            />
            <DetailRow
              title="Leak detector"
              value={<GetCheckCross checkVal={viewDetails.leakDetectorCheck} />}
            />
            <DetailRow
              title="Sample valid"
              value={<GetCheckCross checkVal={viewDetails.sampleCheck} />}
            />
            <DetailRow
              title="Ammonia Leakage check"
              value={
                <GetCheckCross checkVal={viewDetails.ammoniaLeakageCheck} />
              }
            />
            <DetailRow
              title="Operator Phone No."
              value={checkValue(viewDetails.operatorPhoneNumber)}
            />
            <DetailRow
              title="Tube Size"
              value={`${checkValue(viewDetails.tubeSize)}mm`}
            />
            <DetailRow
              title="Dose set"
              value={checkValue(viewDetails.doseSet)}
            />
            <DetailRow
              title="Office Remark"
              value={checkValue(viewDetails.officeRemark)}
            />
            <DetailRow
              title="Check Remark"
              value={checkValue(viewDetails.checkRemark)}
            />
          </div>
          {viewDetails.pictureList && (
            <PictureList
              pictureList={viewDetails.pictureList}
              openImageSlider={openImageSlider}
              setImageSliderCurrentIndex={setImageSliderCurrentIndex}
            />
          )}
          <Remarks
            remarkList={viewDetails.remarks}
            dataId={viewDetails.id}
            apiUrl={VISIT_REMARK}
            updateStateOnAddRemark={updateStateOnAddRemark}
            setViewDetails={setVisitViewDetails}
          />
          <VisitSigns
            ftSign={viewDetails.ftSign}
            operatorSign={viewDetails.operatorSign}
          />
        </div>
        {accept && reject && (
          <div className="view-dialog-btn-wrap flex-center-align">
            <div className="px-2  2xs:max-sm:p-1">
              <GradientButton
                btnText="Approve"
                width="w-48 2xs:max-sm:w-full"
                rounded="rounded-lg"
                onClick={accept}
              />
            </div>
            <div className="px-2 2xs:max-sm:p-1">
              <GrayButton
                btnText="Reject"
                width="w-32 2xs:max-sm:w-20"
                onClick={reject}
              />
            </div>
          </div>
        )}
        <img
          src={closeIcon}
          alt="close"
          className="view-dialog-close"
          onClick={closeDialog}
        />
      </div>
    </Modal>
  );
}
