import React, { useState } from "react";
import { useForm } from "react-hook-form";

import CenteredHeading from "../../UI/headingsUI/CenteredHeading";
import PersonalInfo from "./PersonalInfo";
import BankInfo from "./BankInfo";
import SubmitButton from "../../UI/buttonUI/SubmitButton";
import GrayButton from "../../UI/buttonUI/GrayButton";
import successIcon from "../../../assets/icons/successIcon.svg";
import UserViewDetailsDialog from "../../UI/viewDialogs/user/UserViewDetailsDialog";
import { areObjectsEqual } from "../../../functions/areObjectsEqual";
import UseUserLocationState from "../../../CustomHooks/UseUserLocationState";

const UpdateUser = ({
  userSetup,
  sendData,
  isSucceed,
  setIsSucceed,
  loading,
  navigate,
  responseData,
}) => {
  const {
    userExistingData,
    existingDropdownData,
    emptyExistingData,
    replaceLocationState,
  } = UseUserLocationState();
  const [selectedOption, setSelectedOption] = useState(existingDropdownData);

  const [isFormClear, setIsFormClear] = useState(false);
  const [formError, setFormError] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: userExistingData });

  const dob = watch("dob");

  // send data into form data
  const updateStateOnResponse = () => {
    replaceLocationState({});
    setSelectedOption({});
    // reset form value
    reset(emptyExistingData);
  };

  const onSubmit = (data) => {
    if (areObjectsEqual(data, userExistingData)) {
      setFormError(true);
    } else {
      setFormError(false);
      sendData(data, updateStateOnResponse);
    }
  };

  // handle clear form
  const handleClearForm = () => {
    setSelectedOption({});
    setFormError(false);
    setIsFormClear(!isFormClear);
    // reset form value
    reset(emptyExistingData);
  };

  return (
    <div className="form-container">
      <CenteredHeading title="Update User" />
      <form onSubmit={!loading ? (e) => handleSubmit(onSubmit)(e) : undefined}>
        <div className="flex flex-wrap">
        <PersonalInfo
          userSetup={userSetup}
          register={register}
          errors={errors}
          setValue={setValue}
          dob={dob}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          isFormClear={isFormClear}
        />
        <BankInfo
          userSetup={userSetup}
          register={register}
          errors={errors}
          setValue={setValue}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        </div>
        {formError && (
          <p className="userFormErr">At least one field must be edited.</p>
        )}
        <div className="flex justify-center pt-8">
          <p className="addWrap">
            <SubmitButton
              btnText={!loading ? "Update User" : "Adding..."}
              loading={loading}
              height="h-12"
            />
          </p>
          <p className="grayWrap">
            <GrayButton
              btnText="Clear"
              width="w-40"
              onClick={handleClearForm}
            />
          </p>
        </div>
      </form>
      {isSucceed && (
        <UserViewDetailsDialog
          closeDialog={() => navigate("/user/all")}
          modalNotClose={() => setIsSucceed(true)}
          viewDetails={responseData}
          successView={true}
          message="User Successfully Created!"
          messageIcon={successIcon}
        />
      )}
    </div>
  );
};
export default UpdateUser;
