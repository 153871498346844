import React from "react";
import check from "../../../assets/icons/check.svg";
import cross from "../../../assets/icons/cross.svg";

function SingleExpenseCheck({ handleConfirmDialogBox, dataId }) {
  return (
    <>
      <button
        className="dataCheckedBtn"
        onClick={() => handleConfirmDialogBox(dataId, true)}
      >
        <img src={check} alt="check" className="dataCheckedIcon" />
      </button>
      <button
        className="dataCheckedBtn"
        onClick={() => handleConfirmDialogBox(dataId, false)}
      >
        <img src={cross} alt="cross" className="dataCheckedIcon" />
      </button>
    </>
  );
}

export default SingleExpenseCheck;
