import React from "react";
import ProfileDetails from "../../UI/UserUI/ProfileDetails";

const CompanyInfoTab = ({ userData }) => {
  return (
    <div className="flex">
      <div className="w-3/6">
        <ProfileDetails title="salary" value={`₹${userData.salary}`} />
        <ProfileDetails
          title="Total Balance"
          value={`₹${userData.totalBalance}`}
        />
        <ProfileDetails title="Cash Balance" value={`₹${userData.cashBalance}`} />
        <ProfileDetails
          title="Account Balance"
          value={`₹${userData.accountBalance}`}
        />
        <ProfileDetails title="Loan Balance" value={`₹${userData.loanBalance}`} />
      </div>
      <div className="w-3/6">
        <ProfileDetails
          title="Supervisor (JBCC)"
          value={userData.jbccSupervisor?.fullName ?? "N/A"}
        />
        <ProfileDetails
          title="Supervisor (JN)"
          value={userData.jnSupervisor?.fullName ?? "N/A"}
        />
        <ProfileDetails
          title="Admin (JBCC)"
          value={userData.jbccAdmin?.fullName ?? "N/A"}
        />
        <ProfileDetails
          title="Admin (JN)"
          value={userData.jnAdmin?.fullName ?? "N/A"}
        />
      </div>
    </div>
  );
};

export default CompanyInfoTab;
