import { BASE_IMAGE_URL } from "../api/allApi_List";

const useImageUrl = () => {
  const uuid = localStorage.getItem("uuid");

  const getImageUrl = (imagePath) => {
    return `${BASE_IMAGE_URL}${uuid}${imagePath}`;
  };

  return { getImageUrl };
};

export default useImageUrl;
