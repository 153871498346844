import { getDropdownOptionsData } from "./getDropdownOptionsData";
import { FilterVisitFlags } from "../../utils/FilterVisitFlags";

export function getDropdownsData(categories, originalData, location) {
  let arr = [];
  categories.forEach((cate) => {
    const statusName = cate.getStatusName ? cate.getStatusName : false;
    const item = {
      name: cate.name,
      optionsList: getDropdownOptionsData(originalData, {
        val: cate.value ? cate.value : cate.label,
        lab: cate.label,
      }),
      valueAddress: cate.value ? cate.value : cate.label,
    };
    if (statusName) {
      item.status = statusName;
    }
    arr.push(item);
  });
  // Add extra filter category for the visit page
  const currentUrl = location.pathname;
  if (
    currentUrl === "/complaint/visit-all" ||
    currentUrl === "/complaint/visit-pending"
  ) {
    arr.push(FilterVisitFlags);
  }
  return arr;
}
