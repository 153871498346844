import React from "react";
import SearchDropdown from "../../UI/PageInput/SearchDropdown";
import CustomInput from "../../UI/PageInput/CustomInput";

const BankInfo = ({
  userSetup,
  register,
  errors,
  setValue,
  selectedOption,
  setSelectedOption,
}) => {
  return (
    <div className="halfCol pl-1">
      <div className="userInfoWrap">
      <h4 className="userInfoHeading">Bank Info</h4>
      <div className="px-4 py-6">
        <SearchDropdown
          name="bank_name_id"
          label="Bank Name *"
          keyName="name"
          id="id"
          options={userSetup.banks}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          setValue={setValue}
          register={{
            ...register("bank_name_id", {
              required: "Please select your Bank name",
            }),
          }}
          error={errors.bank_name_id}
          search={true}
        />
        <CustomInput
          type="text"
          name="ifsc"
          labelText="IFSC Code *"
          placeholder="Enter"
          register={{
            ...register("ifsc", {
              required: "Please Enter your IFSC Code",
            }),
          }}
          error={errors.ifsc}
        />
        <CustomInput
          type="text"
          name="account_number"
          labelText="Bank Account No. *"
          placeholder="Enter"
          register={{
            ...register("account_number", {
              required: "Please Enter your Account number",
              pattern: {
                value: /^\d+$/,
                message: "Only numbers are allowed",
              },
            }),
          }}
          error={errors.account_number}
        />
        <CustomInput
          type="text"
          name="bank_branch"
          labelText="Bank Branch *"
          placeholder="Enter"
          register={{
            ...register("bank_branch", {
              required: "Please Enter your Branch Name ",
            }),
          }}
          error={errors.bank_branch}
        />
        {/* <div className="halfCol">
          <div className="px-1">
            <CustomInput
              type="text"
              name="account_number"
              labelText="Bank Account No. *"
              placeholder="Enter"
              register={{
                ...register("account_number", {
                  required: "Please Enter your Account number",
                  pattern: {
                    value: /^\d+$/,
                    message: "Only numbers are allowed",
                  },
                }),
              }}
              error={errors.account_number}
            />
            <CustomInput
              type="text"
              name="bank_branch"
              labelText="Bank Branch *"
              placeholder="Enter"
              register={{
                ...register("bank_branch", {
                  required: "Please Enter your Branch Name ",
                }),
              }}
              error={errors.bank_branch}
            />
          </div>
        </div> */}
      </div>
    </div>
    </div>
  );
};
export default BankInfo;
