import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import logo from "../../assets/images/logo.svg";
import emailIcon from "../../assets/icons/email.svg";
import showPass from "../../assets/icons/showpass.svg";
import hidePass from "../../assets/icons/hidepass.svg";
import errorIcon from "../../assets/icons/errorIcon.svg";
import CustomInput from "../UI/CustomInput";
import CustomCheckBoxInput from "../UI/CustomCheckBoxInput";
import useShowHidePassword from "../../CustomHooks/useShowHidePassword";
import DialogBox from "../UI/DialogBox";
import UseDialogBox from "../../CustomHooks/UseDialogBox";
import resetPass from "../../assets/icons/resetPass.svg";

const LoginForm = ({
  getLogin,
  isInvalid,
  loading,
  isRemember,
  setIsRemember,
  error,
}) => {
  const { showPassword, handleClickShowPassword } = useShowHidePassword();
  const { showDialogBox, setShowDialogBox } = UseDialogBox();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const password = watch("password");
  const email = watch("email");

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    const storedPassword = localStorage.getItem("password");
    if (storedEmail && storedPassword) {
      setValue("email", storedEmail.trim());
      setValue("password", storedPassword.trim());
      setIsRemember(true);
    }
  }, [setIsRemember, setValue]);

  const onSubmit = () => {
    getLogin(password, email);
  };
  return (
    <div className="login-main ">
      <div className="login-content">
        <div className="login-container">
          <img src={logo} alt="logo" className="login-logo" />
          <h3 className="login-heading">Welcome to JBCC</h3>
          {error ? (
            <p className="loginErr">Something went wrong. Please try again.</p>
          ) : (
            ""
          )}
          <form onSubmit={handleSubmit}>
            <CustomInput
              type="email"
              name="email"
              labelText="Email"
              placeholder="Enter your Email"
              register={{
                ...register("email", {
                  required: "Please Enter your email",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Please enter a valid email",
                  },
                }),
              }}
              inputIcon={emailIcon}
              imgAlt="email"
              error={errors.email}
            />
            <CustomInput
              type={showPassword.password ? "text" : "password"}
              name="password"
              labelText="Password"
              placeholder="Enter your Password"
              register={{
                ...register("password", {
                  required: "Please Enter your password",
                }),
              }}
              handleClick={() => handleClickShowPassword("password")}
              inputIcon={showPassword.password ? hidePass : showPass}
              imgAlt="pass"
              error={errors.password}
            />
            {isInvalid && (
              <p className="loginErr">
                <img src={errorIcon} alt="errorIcon" className="pr-2" />
                Invalid credentials, please check your credentials and try
                again.
              </p>
            )}
            <div className="rem-forgotPass--wrap">
              <CustomCheckBoxInput
                name="remember me"
                checked={isRemember}
                handleChange={() => setIsRemember((remember) => !remember)}
                labelText="Remember me"
              />
              <p
                className="forgotPassText"
                onClick={() => setShowDialogBox(true)}
              >
                Forgot password ?
              </p>
            </div>
            <button
              className={`loginBtn ${!loading ? "" : "disableBtn"}`}
              onClick={!loading ? handleSubmit(onSubmit) : undefined}
            >
              {!loading ? "Log in" : "Logging in..."}
            </button>
          </form>
        </div>
      </div>
      {showDialogBox && (
        <DialogBox
          message="For reset your password call this number"
          btnText="Close"
          phoneNo="+91 81463 98230"
          messageIcon={resetPass}
          closeAction={() => setShowDialogBox(false)}
        />
      )}
    </div>
  );
};
export default LoginForm;
