import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  uuidLoad: false,
  uuidError: false,
};

export const uuidSlice = createSlice({
  name: "uuid",
  initialState,
  reducers: {
    setLoad: (state) => {
      state.uuidLoad = true;
      state.uuidError = false;
    },
    setError: (state) => {
      state.data = {};
      state.uuidError = true;
    },
  },
});

export const { setLoad, setError } = uuidSlice.actions;

export const fetchUuid = (apiEndPoint, navigate) => async (dispatch) => {
  try {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: apiEndPoint,
    };

    const response = await axios.request(config);
    dispatch(setLoad());
    localStorage.setItem("uuid", response.data.uuid);
  } catch (error) {
    if (error.response && error.response.status === 401) {
      navigate("/login");
    } else {
      dispatch(setError());
    }
  }
};

export default uuidSlice.reducer;
