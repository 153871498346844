import React from "react";
import { Link } from "react-router-dom";

import menubar from "../../assets/icons/menu-bar.svg";
import mobileLogo from "../../assets/images/mobile-logo.svg";
import UserProfile from "./UserProfile";
import PageTitle from "./PageTitle";

const CustomHeader = ({
  setOpenSidebar,
  headerConfig,
  ExpenseListTabs,
  numberOfData,
}) => {
  return (
    <div className="header-row">
      <img
        src={menubar}
        alt="menubar"
        className="md:hidden w-5"
        onClick={() => setOpenSidebar(true)}
      />
      <Link to="/home" className="md:hidden flex">
        <img src={mobileLogo} alt="menuLogo" className="w-60" />
      </Link>
      {headerConfig && (
        <PageTitle pageTitle={headerConfig.title} numberOfData={numberOfData} />
      )}
      {ExpenseListTabs && ExpenseListTabs}
      <div className="flex items-center">
        <UserProfile />
      </div>
    </div>
  );
};
export default CustomHeader;