import React from "react";
import GrayButton from "../UI/buttonUI/GrayButton";

function SheetErrors({ errorList, closeAction }) {
  return (
    <div className="overlay z-9999">
      <div className="flex-center-align absolute-center-align sheet-container">
        <div className="w-full px-14 m-auto">
          <h1 className="sheetHeading">There are some errors in your sheet</h1>
          <div className="overflow-y-auto max-h-[300px]">
            {errorList && errorList.length > 0 && (
              <table className="sheet-table">
                <tr className="sheetTableHeadRow">
                  <th className="sheetTableTh">Row No.</th>
                  <th className="sheetTableTh">Error Description</th>
                </tr>
                {errorList.map((error, index) => {
                  return (
                    <tr
                      key={index}
                      className="text-red-1 even:bg-white odd:bg-lg6"
                    >
                      <td className="border border-lg6 text-center text-xs p-1.5">
                        {error.rowNumber}
                      </td>
                      <td className="border border-lg6 text-sm">
                        <span className="pl-1">{error.rowError}</span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            )}
          </div>
          <div className="mt-8 flex justify-center ">
            <GrayButton
              btnText="Close"
              width="w-40"
              rounded="rounded-full"
              onClick={closeAction}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default SheetErrors;
